import {
  addDays as daysAdd,
  addMinutes as minutesAdd,
  addMonths as monthsAdd,
  addYears as yearsAdd,
  differenceInMilliseconds as differenceMilliseconds,
  endOfMonth as monthEnd,
  format as forms,
  getMinutes as minutesGet,
  getTime as timeCheck,
  getYear as checkYear,
  isToday as dayCheck,
  isTomorrow as checkTomorrow,
  setMinutes as minutesSet,
  setYear as yearSet,
  startOfMonth as startMonth,
  startOfWeek as startWeek,
  startOfYear as startYear,
  subDays as daysSub,
  subMonths as monthsSub,
  subYears as yearsSub,
} from 'date-fns';
import de from 'date-fns/locale/de';

export const convertDateValue = (value: string | number | Date): Date => {
  if (typeof value === 'string') {
    const parts = value.split(' ')
    let dateString = ''
    let offset = Math.abs((new Date(`${parts[0]}T12:00:00`)).getTimezoneOffset() / 60)

    if (parts.length === 2) {
      dateString = `${parts.join('T')}+0${offset}:00`
    }

    if (parts.length === 1) {
      dateString = `${parts[0]}T00:00:00+0${offset}:00`
    }

    const date = new Date(dateString);

    value = date.getTime();
  }

  if (value instanceof Date) {
    return value;
  }

  return new Date(value);
};

export const format =  (value: Date | string | number, target: string = 'dd.MM.yyyy'): string => {
  if (!value) return '';

  if (typeof value === 'string') {
    value = new Date(value.split(' ').join('T'));
  }

  if (typeof value === 'number') {
    value = new Date(value);
  }

  try {
    return forms(value, target, { locale: de as Locale });
  } catch (e) {
    return '';
  }
};

export const getTime = (time: string | number | Date) => {
  return timeCheck(convertDateValue(time));
};

export const isToday = (date: string | number) => {
  return dayCheck(convertDateValue(date));
};

export const isTomorrow = (date: string | number) => {
  return checkTomorrow(convertDateValue(date));
};

export const differenceInMilliseconds = (left: string | number, right: string | number) => {
  return differenceMilliseconds(convertDateValue(left), convertDateValue(right));
};

export const getYear = (date: string | number) => {
  return checkYear(convertDateValue(date))
};

export const addDays = (date: string | number | Date, amount: number) => {
  return daysAdd(convertDateValue(date), amount);
};

export const subDays = (date: string | number | Date, amount: number) => {
  return daysSub(convertDateValue(date), amount);
};

export const subYears = (date: string | number | Date, amount: number) => {
  return yearsSub(convertDateValue(date), amount);
};

export const addYears = (date: string | number | Date, amount: number) => {
  return yearsAdd(convertDateValue(date), amount);
};

export const setYear = (date: string | number | Date, amount: number) => {
  return yearSet(convertDateValue(date), amount);
};

export const addMonths = (date: string | number | Date, amount: number) => {
  return monthsAdd(convertDateValue(date), amount);
};

export const subMonths = (date: string | number | Date, amount: number) => {
  return monthsSub(convertDateValue(date), amount);
};

export const endOfMonth = (date: string | number | Date) => {
  return monthEnd(convertDateValue(date));
};

export const startOfMonth = (date: string | number) => {
  return startMonth(new Date(date));
};

export const startOfYear = (date: string | number | Date) => {
  return startYear(convertDateValue(date));
};

export const startOfWeek = (date: string | number, options?: {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}) => startWeek(convertDateValue(date), options);

export const getMinutes = (date: string | number | Date) => {
  return minutesGet(convertDateValue(date));
};

export const setMinutes = (date: string | number | Date, amount: number) => {
  return minutesSet(convertDateValue(date), amount);
};

export const addMinutes = (date: string | number | Date, amount: number) => {
  return minutesAdd(convertDateValue(date), amount);
};

export default format;
