






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppTable from '@/components/AppTable.vue';
import { DOWNLOAD_COMPENSATIONS, FETCH_COMPENSATIONS } from "../store";
import { Compensation } from "../types";
import DatePicker from "@/components/form/DatePicker.vue";
import { startOfMonth, subMonths, endOfMonth, format } from "date-fns";

const AccountingStore = namespace('accounting');

const startOfLastMonth = () => startOfMonth(subMonths(new Date(), 1))
const endOfLastMonth = () => endOfMonth(subMonths(new Date(), 1))

@Component({
  components: {
    DatePicker,
    AppTable,
    ErrorAlert
  }
})
export default class AccountingPlugin extends Vue {
  @AccountingStore.Action(FETCH_COMPENSATIONS) fetch!: (params: { start?: string, end?: string }) => Promise<ApiResponse<Compensation[]>>;
  @AccountingStore.Action(DOWNLOAD_COMPENSATIONS) download!: (params: { start?: string, end?: string }) => Promise<ApiResponse<Blob>>;

  error: any = null;
  loading: boolean = false;
  downloading: boolean = false;
  list: Compensation[] = [];

  config: { start: string, end: string } = {
    start: format(startOfLastMonth(), 'yyyy-MM-dd'),
    end: format(endOfLastMonth(), 'yyyy-MM-dd')
  }

  pagination: Pagination = {
    sortBy: ['account'],
    itemsPerPage: -1
  };

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('label.invoiceDate'), value: 'end' },
      { align: 'left', text: this.$i18n.t('common.invoiceNumber'), value: 'invoiceNumber' },
      { align: 'left', text: this.$i18n.t('common.attorneyDocket'), value: 'attorneyDocket' },
      { align: 'left', text: this.$i18n.t('common.court'), value: 'court' },
      { align: 'left', text: this.$i18n.t('accounting.code'), value: 'account' },
      { align: 'left', text: this.$i18n.t('common.financialState'), value: 'financialState' },
      { align: 'left', text: this.$i18n.t('accounting.counterCode'), value: 'counterAccount' },
    ];
  }

  async downloadExport() {
    this.downloading = true;
    this.error = null;

    let start: string | undefined = this.config.start;
    let end: string | undefined = this.config.end;

    if (!start) {
      start = undefined;
    }
    if (!end) {
      end = undefined;
    }

    // @ts-ignore
    const { error, content } = await this.download({ start, end });
    this.downloading = false;

    if (error) {
      this.error = error;
      return;
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', 'BP_Rechnungsexport.zip');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  @Watch('config', { deep: true, immediate: true })
  async fetchCompensation(config: { start?: string, end?: string }) {
    this.loading = true;
    this.error = null;

    let start: string | undefined = config.start;
    let end: string | undefined = config.end;

    if (!start) {
      start = undefined;
    }
    if (!end) {
      end = undefined;
    }

    // @ts-ignore
    const { error, content } = await this.fetch({ start, end });

    this.loading = false;

    if (content) {
      this.list = content;
    }

    if (error) {
      this.error = error;
    }
  }
}
