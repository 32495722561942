import { RouterChild } from '@/router';

export default [
  {
    name: 'list-of-assets-list',
    path: 'list-of-assets/list',
    component: () => import('./views/List.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-transaction-import',
    path: 'list-of-assets/transaction-import',
    component: () => import('./views/TransactionImport.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-bank-account-import',
    path: 'list-of-assets/bank-account-import',
    component: () => import('./views/BankAccountImport.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-bank-account-balance-check',
    path: 'list-of-assets/bank-account-balance-check',
    component: () => import('./views/BankAccountBalanceCheck.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-annuity-computation',
    path: 'list-of-assets/annuity-computation',
    component: () => import('./views/AnnuityComputation.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-details',
    path: 'list-of-assets/:id/details',
    component: () => import('./views/ListOfAssetsDetails.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-bank-account-details',
    path: 'list-of-assets/bank-account/:id/details',
    component: () => import('./views/BankAccountDetails.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-credit-account-details',
    path: 'list-of-assets/credit-account/:id/details',
    component: () => import('./views/CreditAccountDetails.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-cash-account-details',
    path: 'list-of-assets/cash-account/:id/details',
    component: () => import('./views/CashAccountDetails.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-internal-account-details',
    path: 'list-of-assets/internal-account/:id/details',
    component: () => import('./views/InternalAccountDetails.vue')
  } as RouterChild,
  {
    name: 'list-of-assets-import-tan-modes',
    path: 'list-of-assets/import/tan-modes',
    component: () => import('./views/TanModeList.vue')
  } as RouterChild
];
