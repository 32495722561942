
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { VCardText, VFlex } from 'vuetify/lib';

@Component({
  inheritAttrs: false,
  components: { VCardText, VFlex }
})
export default class ErrorAlert extends Vue {
  @Prop() error: any;
  @Prop({ default: 'template' }) container!: string;

  get errorCode(): number {
    if (!this.error.response) {
      return 1;
    }

    if (this.error.response.data && this.error.response.data.code) {
      return this.error.response.data.code;
    }

    return this.error.response.status;
  }

  get errorMessage(): string {
    if (!this.error.response) {
      return '';
    }

    if (this.error.response.data && this.error.response.data.message) {
      return this.error.response.data.message;
    }

    return '';
  }

  get errorText(): string {
    if (!this.error.response) {
      return '';
    }

    if (this.error.response.data && this.error.response.data.error) {
      return this.error.response.data.error;
    }

    return '';
  }

  get statusCode(): number {
    if (!this.error.response) {
      return 1;
    }

    return this.error.response.status;
  }
}
