import Vue, { VueConstructor } from 'vue';
import { ActionTree } from 'vuex';
import {
  AddHealthInsuranceMembership,
  AddMedicalInformation,
  AddPhoneNumber,
  ChangeAddress,
  ChangeConcernedCourt,
  ChangeConservator,
  ChangeFinances,
  ChangeListenable,
  ChangeName,
  ChangePlacement,
  ChangeState,
  Conservatorship,
  ConservatorshipState,
  ConvertInAssigned,
  CorrectAssigned,
  CorrectBegin,
  CorrectBirthday,
  FetchDetails,
  RevertChange,
  WriteConservatorship,
  WriteNote
} from '@/modules/conservatorship/types';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { ApiResponse } from '@/components/types';

export const FETCH_LIST = 'FETCH_CONSERVATORSHIP_LIST';
export const FETCH_DETAILS = 'FETCH_CONSERVATORSHIP_DETAILS';
export const FETCH_HISTORY = 'FETCH_CONSERVATORSHIP_HISTORY';
export const FETCH_POSSIBLE_CHANGES = 'FETCH_CONSERVATORSHIP_POSSIBLE_CHANGES';
export const FETCH_DUE_REPORTS = 'FETCH_CONSERVATORSHIP_DUE_REPORTS';
export const FETCH_BIRTHDAY_LIST = 'FETCH_CONSERVATORSHIP_BIRTHDAY_LIST';
export const ADD_HEALTH_INSURANCE_MEMBERSHIP = 'ADD_HEALTH_INSURANCE_MEMBERSHIP';
export const ADD_MEDICAL_INFORMATION = 'ADD_MEDICAL_INFORMATION';
export const CHANGE_CONCERNED_COURT = 'CHANGE_CONSERVATORSHIP_CONCERNED_COURT';
export const CHANGE_STATE = 'CHANGE_CONSERVATORSHIP_STATE';
export const CHANGE_CONSERVATOR = 'CHANGE_CONSERVATORSHIP_CONSERVATOR';
export const CORRECT_BEGIN = 'CORRECT_CONSERVATORSHIP_BEGIN';
export const CORRECT_ASSIGNED = 'CORRECT_CONSERVATORSHIP_ASSIGNED';
export const CONVERT_IN_ASSIGNED = 'CONVERT_CONSERVATORSHIP_IN_ASSIGNED';
export const SAVE_CONSERVATORSHIP = 'SAVE_CONSERVATORSHIP';
export const REVERT_CHANGE = 'REVERT_CONSERVATORSHIP_CHANGES';
export const CHANGE_FINANCES = 'CHANGE_FINANCES';
export const CHANGE_PLACEMENT = 'CHANGE_PLACEMENT';
export const CHANGE_LISTENABLE = 'CHANGE_LISTENABLE';

export const ADD_CONSERVATEE_PRIVATE_NUMBER = 'ADD_CONSERVATEE_PRIVATE_NUMBER';
export const ADD_CONSERVATEE_PUBLIC_NUMBER = 'ADD_CONSERVATEE_PUBLIC_NUMBER';
export const ADD_CONSERVATEE_MOBILE_NUMBER = 'ADD_CONSERVATEE_MOBILE_NUMBER';
export const CORRECT_CONSERVATEE_BIRTHDAY = 'CORRECT_CONSERVATEE_BIRTHDAY';
export const CHANGE_CONSERVATEE_ADDRESS = 'CHANGE_CONSERVATEE_ADDRESS';
export const CHANGE_CONSERVATEE_NAME = 'CHANGE_CONSERVATEE_NAME';
export const WRITE_NOTE = 'WRITE_CONSERVATORSHIP_NOTE';

const actions = (Vue: Vue | VueConstructor): ActionTree<ConservatorshipState, RootState> => ({
  async [FETCH_LIST]({ commit }): Promise<ApiResponse<Conservatorship[]>> {
    try {
      const response = await Vue.axios.get('api/conservatorship/list', { responseType: 'json' });
      commit('SET_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_DETAILS]({ commit }, { id, redirect = false }: FetchDetails): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship/${id}/details`, { responseType: 'json' });

      commit('ADD_DETAILS', response.data);

      if (redirect) {
        // @ts-ignore
        Vue.router.push({ name: 'conservatorship-details', params: { id } }).catch(() => {});
      }

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_HISTORY]({ commit }, { id, redirect = false }: FetchDetails): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship/${id}/history`, { responseType: 'json' });

      commit('ADD_HISTORY', { history: response.data, id });

      if (redirect) {
        // @ts-ignore
        Vue.router.push({ name: 'conservatorship-details', params: { id } }).catch(() => {});
      }

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_POSSIBLE_CHANGES](): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship/possible-financial-change`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_DUE_REPORTS](): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/due-reports`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_BIRTHDAY_LIST](_, month: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship/birthday/list`, { params: { month }, responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [ADD_HEALTH_INSURANCE_MEMBERSHIP]({ commit, dispatch, rootGetters }, healthInsuranceMembership: AddHealthInsuranceMembership): Promise<ApiResponse> {
    try {
      await Vue.axios.post(
        'api/commands/conservatorship-management/conservatee/add-health-insurance-membership', healthInsuranceMembership, { responseType: 'json' }
      );

      dispatch(FETCH_DETAILS, { id: healthInsuranceMembership.conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_MEDICAL_INFORMATION]({ commit, dispatch }, medicalInformation: AddMedicalInformation): Promise<ApiResponse> {
    try {
      await Vue.axios.post(
        'api/commands/conservatorship-management/conservatee/add-medical-information', medicalInformation, { responseType: 'json' }
      );

      dispatch(FETCH_DETAILS, { id: medicalInformation.conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CONCERNED_COURT]({ commit, dispatch, rootGetters }, changeConcernedCourt: ChangeConcernedCourt) {
    try {
      await Vue.axios.post('api/commands/change-concerned-court', changeConcernedCourt, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: changeConcernedCourt.conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_STATE]({ commit, dispatch }, { conservatorship_id, changed, reassignment, state, note }: ChangeState) {
    try {
      switch (state) {
        case 'open':
          await Vue.axios.post('api/commands/open-conservatorship', {
            conservatorship_id,
            reopened: changed,
            reassignment,
            note
          }, { responseType: 'json' });

          break;
        case 'suspended':
          await Vue.axios.post('api/commands/suspend-conservatorship', {
            conservatorship_id,
            suspended: changed,
            note
          }, { responseType: 'json' });

          break;
        case 'closed':
          await Vue.axios.post('api/commands/close-conservatorship', {
            conservatorship_id,
            closed: changed,
            note
          }, { responseType: 'json' });

          break;
      }

      dispatch(FETCH_DETAILS, { id: conservatorship_id });
      dispatch(FETCH_HISTORY, { id: conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CONSERVATOR]({ commit, dispatch, rootGetters }, changeConservator: ChangeConservator) {
    try {
      await Vue.axios.post('api/commands/change-conservator', changeConservator, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: changeConservator.conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CORRECT_BEGIN]({ commit, dispatch }, correctBegin: CorrectBegin) {
    try {
      await Vue.axios.post('api/commands/correct-conservatorship-begin', correctBegin, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: correctBegin.conservatorship_id });
      dispatch(FETCH_HISTORY, { id: correctBegin.conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CORRECT_ASSIGNED]({ commit, dispatch }, correctAssigned: CorrectAssigned) {
    try {
      await Vue.axios.post('api/commands/correct-conservatorship-assigned', correctAssigned, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: correctAssigned.conservatorship_id });
      dispatch(FETCH_HISTORY, { id: correctAssigned.conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONVERT_IN_ASSIGNED]({ commit, dispatch }, convertInAssigned: ConvertInAssigned) {
    try {
      await Vue.axios.post('api/commands/convert-conservatorship-in-assigned', convertInAssigned, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: convertInAssigned.conservatorship_id });
      dispatch(FETCH_HISTORY, { id: convertInAssigned.conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [SAVE_CONSERVATORSHIP]({ commit, dispatch }, { type, ...conservatorship }: WriteConservatorship) {
    try {
      switch (type) {
        case 'assigned':
          await Vue.axios.post('api/commands/assume-conservatorship', conservatorship, { responseType: 'json' });
          break;
        case 'new':
          await Vue.axios.post('api/commands/new-conservatorship', conservatorship, { responseType: 'json' });
          break;
      }

      dispatch(FETCH_DETAILS, { id: conservatorship.conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REVERT_CHANGE]({ commit, dispatch }, { conservatorship_id, changed, value, type }: RevertChange) {
    try {
      let key: string = type;
      let endpoint: string = type;

      switch (type) {
        case 'conservator':
          key = 'conservator_id';

          break;
        case 'financial':
          key = 'financial_state';

          break;
        case 'state':
          endpoint = value;
      }

      await Vue.axios.post(`api/commands/conservatee/${endpoint}/revert`, {
        conservatorship_id,
        changed,
        [key]: value
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatorship_id });
      dispatch(FETCH_HISTORY, { id: conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: null };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [WRITE_NOTE]({ commit, dispatch }, writeNote: WriteNote) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/write-a-note', writeNote, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: writeNote.conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_FINANCES]({ commit, dispatch }, { conservatorship_id, changed, financial_state }: ChangeFinances) {
    try {
      await Vue.axios.post('api/commands/conservatee-financial-change', {
        conservatorship_id,
        changed,
        financial_state
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatorship_id });
      dispatch(FETCH_HISTORY, { id: conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_PLACEMENT]({ commit, dispatch }, { conservatorship_id, changed, placement }: ChangePlacement) {
    try {
      await Vue.axios.post('api/commands/conservatee-placement-change', {
        conservatorship_id,
        changed,
        placement
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatorship_id });
      dispatch(FETCH_HISTORY, { id: conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_LISTENABLE]({ commit, dispatch }, { conservatorship_id, listenable }: ChangeListenable) {
    try {
      await Vue.axios.post('api/commands/change-conservatee-listenable', {
        conservatorship_id,
        listenable
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatorship_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CONSERVATEE_ADDRESS]({ commit, dispatch }, changeAddress: ChangeAddress) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/change-address', changeAddress, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: changeAddress.conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CONSERVATEE_NAME]({ commit, dispatch }, changeName: ChangeName) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/change-name', changeName, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: changeName.conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CORRECT_CONSERVATEE_BIRTHDAY]({ commit, dispatch }, { conservatee_id, birthday }: CorrectBirthday) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/correct-birthday', {
        conservatee_id,
        birthday
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_CONSERVATEE_PUBLIC_NUMBER]({ commit, dispatch }, { conservatee_id, phone_number }: AddPhoneNumber) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/add-public-number', {
        conservatee_id,
        phone_number
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_CONSERVATEE_PRIVATE_NUMBER]({ commit, dispatch }, { conservatee_id, phone_number }: AddPhoneNumber) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/add-private-number', {
        conservatee_id,
        phone_number
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_CONSERVATEE_MOBILE_NUMBER]({ commit, dispatch }, { conservatee_id, phone_number }: AddPhoneNumber) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatee/add-mobile-number', {
        conservatee_id,
        phone_number
      }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, { id: conservatee_id });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_DETAILS({ commit }, { data }) {
    commit('ADD_DETAILS', data.details);
  },
  SET_LIST({ commit }, { data }) {
    commit('SET_LIST_ITEMS', data.conservatorships);
  },
  SET_HISTORY({ commit }, { data }) {
    commit('ADD_HISTORY', { id: data.conservatorship, history: data.history });
  }
});

export default actions;
