import Vue, { VueConstructor } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import registerValidation from './plugins/vee-validate';
import registerComponents from './plugins/components';
import registerHighCharts from './plugins/highcharts';
import '@/plugins/ckeditor';

import VueNativeSocket from 'vue-native-websocket';
// @ts-ignore
import VueAuth from '@websanova/vue-auth';
import VueClipboard from 'vue-clipboard2';
import Meta from 'vue-meta';
import moment from 'moment';
import authConfig from './plugins/auth';
import './plugins/axios';
import './plugins/ckeditor';
import { registerSW, VueSW } from './registerServiceWorker';
import dateFormat from '@/filter/dateFormat';
import money from '@/filter/money';
import absolute from '@/filter/absolute';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

// @ts-ignore
Sentry.init({
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
  release: '1.0.0',
  beforeSend(event: any, hint: any) {
    const error = hint.originalException;
    if (
      error &&
      error.message &&
      error.message.match(/Rendering cancelled/i)
    ) {
      return null
    }
    return event;
  },
});

// @ts-ignore
Vue.router = router;
Vue.config.productionTip = false;

Vue.use(Meta);
Vue.use(VueAuth, authConfig);
Vue.use(
  VueNativeSocket as any,
  process.env.VUE_APP_SOCKET || `wss://${document.location.host}/socket/`,
  {
    store,
    format: 'json',
    reconnection: true,
    connectManually: true
  }
);

moment.locale('de');

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard as any);

Vue.filter('date', dateFormat);
Vue.filter('money', money);
Vue.filter('absolute', absolute);

registerComponents(Vue as VueConstructor);
registerValidation(Vue as VueConstructor);
registerHighCharts(Vue as VueConstructor);

registerSW()
  .catch(async (error: any): Promise<null> => {
    return null;
  })
  .then(registration => {
    Vue.use(VueSW, { registration });

    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: h => h(App),
      created() {
        // @ts-ignore
        this.$connect();
      }
    }).$mount('#app');
  });
