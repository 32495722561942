import initialState from './store/state';
import getters from './store/getters';
import mutations from './store/mutations';
import actions from './store/actions';
import Vue, { VueConstructor } from 'vue';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { ConservatorshipState } from '@/modules/conservatorship/types';


export default (Vue: Vue | VueConstructor): Module<ConservatorshipState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
