import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Vue, { VueConstructor } from "vue";
import {
  AccountingState,
  Account,
  CounterAccount,
  CreateAccount,
  UpdateAccount,
  DeleteAccount,
  CreateCounterAccount,
  UpdateCounterAccount,
  DeleteCounterAccount
} from "@/modules/accounting/types";
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from "@/store/types";

export const FETCH_EXPORTS = 'FETCH_ACCOUNTING_EXPORTS';
export const FETCH_COMPENSATIONS = 'FETCH_ACCOUNTING_COMPENSATIONS';
export const DOWNLOAD_COMPENSATIONS = 'DOWNLOAD_ACCOUNTING_COMPENSATIONS';

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTING_ACCOUNT';
export const GET_ACCOUNTS = 'GET_ACCOUNTING_ACCOUNT';
export const SET_ACCOUNTS = 'SET_ACCOUNTING_ACCOUNT';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNTING_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNTING_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNTING_ACCOUNT';

export const FETCH_COUNTER_ACCOUNTS = 'FETCH_ACCOUNTING_COUNTER_ACCOUNT';
export const GET_COUNTER_ACCOUNTS = 'GET_ACCOUNTING_COUNTER_ACCOUNT';
export const SET_COUNTER_ACCOUNTS = 'SET_ACCOUNTING_COUNTER_ACCOUNT';

export const CREATE_COUNTER_ACCOUNT = 'CREATE_ACCOUNTING_COUNTER_ACCOUNT';
export const UPDATE_COUNTER_ACCOUNT = 'UPDATE_ACCOUNTING_COUNTER_ACCOUNT';
export const DELETE_COUNTER_ACCOUNT = 'DELETE_ACCOUNTING_COUNTER_ACCOUNT';

const initialState = (): AccountingState => ({
  accounts: [],
  counterAccounts: []
});

const getters: GetterTree<AccountingState, RootState> = {
  [GET_ACCOUNTS]: (state): Account[] => state.accounts,
  [GET_COUNTER_ACCOUNTS]: (state): CounterAccount[] => state.counterAccounts,
};

const mutations: MutationTree<AccountingState> = {
  [SET_COUNTER_ACCOUNTS](state: AccountingState, accounts: CounterAccount[]) {
    state.counterAccounts = [...accounts];
  },
  [SET_ACCOUNTS](state: AccountingState, accounts: Account[]) {
    state.accounts = [...accounts];
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
}

const actions = (Vue: Vue | VueConstructor): ActionTree<AccountingState, RootState> => ({
  async [FETCH_ACCOUNTS]({ commit }) {
    try {
      const response = await Vue.axios.get('api/accounting/account/list', { responseType: 'json' });
      commit(SET_ACCOUNTS, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_COUNTER_ACCOUNTS]({ commit }) {
    try {
      const response = await Vue.axios.get('api/accounting/counter-account/list', { responseType: 'json' });
      commit(SET_COUNTER_ACCOUNTS, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_COMPENSATIONS](_, { start, end }) {
    try {
      const response = await Vue.axios.get('api/accounting/compensations', { responseType: 'json', params: { start, end } });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_EXPORTS]() {
    try {
      const response = await Vue.axios.get('api/accounting/export/list', { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [DOWNLOAD_COMPENSATIONS](_, { start, end }) {
    try {
      const response = await Vue.axios.get('api/accounting/csv-export', { responseType: 'blob', params: { start, end } });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [CREATE_ACCOUNT]({ dispatch, commit }, command: CreateAccount) {
    try {
      await Vue.axios.post('api/commands/accounting/account/create', command, { responseType: 'json' });
      dispatch(FETCH_ACCOUNTS);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, true, { root: true });
      return { error };
    }
  },
  async [UPDATE_ACCOUNT]({ dispatch, commit }, command: UpdateAccount) {
    try {
      await Vue.axios.post('api/commands/accounting/account/update', command, { responseType: 'json' });
      dispatch(FETCH_ACCOUNTS);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, true, { root: true });
      return { error };
    }
  },
  async [DELETE_ACCOUNT]({ dispatch, commit }, command: DeleteAccount) {
    try {
      await Vue.axios.post('api/commands/accounting/account/delete', command, { responseType: 'json' });
      dispatch(FETCH_ACCOUNTS);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, true, { root: true });
      return { error };
    }
  },
  async [CREATE_COUNTER_ACCOUNT]({ dispatch, commit }, command: CreateCounterAccount) {
    try {
      await Vue.axios.post('api/commands/accounting/counter-account/create', command, { responseType: 'json' });
      dispatch(FETCH_COUNTER_ACCOUNTS);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, true, { root: true });
      return { error };
    }
  },
  async [UPDATE_COUNTER_ACCOUNT]({ dispatch, commit }, command: UpdateCounterAccount) {
    try {
      await Vue.axios.post('api/commands/accounting/counter-account/update', command, { responseType: 'json' });
      dispatch(FETCH_COUNTER_ACCOUNTS);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, true, { root: true });
      return { error };
    }
  },
  async [DELETE_COUNTER_ACCOUNT]({ dispatch, commit }, command: DeleteCounterAccount) {
    try {
      await Vue.axios.post('api/commands/accounting/counter-account/delete', command, { responseType: 'json' });
      dispatch(FETCH_COUNTER_ACCOUNTS);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, true, { root: true });
      return { error };
    }
  },
});

export default (instance: Vue | VueConstructor): Module<AccountingState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
