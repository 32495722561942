import {
  BankAccount,
  BankAccountDetails,
  BankAccountState,
  ChangeBankAccount,
  CheckBankAccountBalance,
  ConvertBankAccount,
  ListNewSEPAAccounts,
  OpenBankAccount
} from '@/modules/listOfAssets/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';

export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT_DETAILS';
export const GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNT_LIST';

export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';
export const FETCH_BANK_ACCOUNT = 'FETCH_BANK_ACCOUNT';

export const FETCH_NEW_BANK_ACCOUNTS = 'FETCH_NEW_FINANCE_ACCOUNT';
export const BANK_ACCOUNT_BALANCE_CHECK = 'BANK_ACCOUNT_BALANCE_CHECK';

export const OPEN_BANK_ACCOUNT = 'OPEN_FINANCE_BANK_ACCOUNT';
export const CHANGE_BANK_ACCOUNT = 'CHANGE_FINANCE_BANK_ACCOUNT';
export const CONVERT_BANK_ACCOUNT = 'CONVERT_FINANCE_BANK_ACCOUNT';

export const ADD_BANK_ACCOUNT_TO_DETAILS = 'ADD_BANK_ACCOUNT_TO_DETAILS';
export const ADD_BANK_ACCOUNT_TO_LIST = 'ADD_BANK_ACCOUNT_TO_LIST';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

const initialState = (): BankAccountState => ({
  list: [],
  details: []
});

const getters: GetterTree<BankAccountState, RootState> = {
  [GET_BANK_ACCOUNT]: (state) => (id: string): BankAccountDetails | undefined => {
    return state.details.find((bankAccount) => bankAccount.id === id);
  },
  [GET_BANK_ACCOUNTS]: (state) => (listOfAssetsId: string): BankAccount[] => {
    return state.list.filter((bankAccount) => bankAccount.listOfAssetsId === listOfAssetsId);
  }
};

const mutations: MutationTree<BankAccountState> = {
  [SET_BANK_ACCOUNTS](state, bankAccounts: BankAccount[]) {
    state.list = bankAccounts;
  },
  [ADD_BANK_ACCOUNT_TO_LIST](state, bankAccount: BankAccount) {
    const index = state.list.findIndex((entry) => entry.id === bankAccount.id);

    if (index >= 0) {
      state.list.splice(index, 1);
    }

    state.list = [
      ...state.list,
      bankAccount
    ];
  },
  [ADD_BANK_ACCOUNT_TO_DETAILS](state, bankAccount: BankAccountDetails) {
    const index = state.details.findIndex((entry) => entry.id === bankAccount.id);

    if (index >= 0) {
      state.details.splice(index, 1);
    }

    state.details = [
      ...state.details,
      bankAccount
    ];
  },
  [REMOVE_ACCOUNT](state, bankAccountId: string) {
    const listIndex = state.list.findIndex((entry) => entry.id === bankAccountId);

    if (listIndex !== -1) {
      state.list.splice(listIndex, 1);
      state.list = [...state.list];
    }

    const detailsIndex = state.details.findIndex((entry) => entry.id === bankAccountId);

    if (detailsIndex !== -1) {
      state.details.splice(detailsIndex, 1);
      state.details = [...state.details];
    }
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<BankAccountState, RootState> => ({
  async [FETCH_BANK_ACCOUNTS]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/bank-accounts`, { responseType: 'json' });

      commit(SET_BANK_ACCOUNTS, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_BANK_ACCOUNT]({ commit }, bankAccountId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/bank-account/${bankAccountId}`, {
        responseType: 'json'
      });

      commit(ADD_BANK_ACCOUNT_TO_DETAILS, response.data);
      commit(ADD_BANK_ACCOUNT_TO_LIST, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_NEW_BANK_ACCOUNTS]({ commit }, values: ListNewSEPAAccounts): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/fints3/sepa-accounts`, values, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [BANK_ACCOUNT_BALANCE_CHECK]({ commit }, values: CheckBankAccountBalance): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/fints3/sepa-accounts-balance`, values, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [OPEN_BANK_ACCOUNT]({ commit, dispatch }, bankAccount: OpenBankAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/bank-account/open-account`, bankAccount, { responseType: 'json' });

      dispatch(FETCH_BANK_ACCOUNT, bankAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_BANK_ACCOUNT]({ commit, dispatch }, bankAccount: ChangeBankAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/bank-account/change-account', bankAccount, { responseType: 'json' });

      dispatch(FETCH_BANK_ACCOUNT, bankAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONVERT_BANK_ACCOUNT]({ commit, dispatch }, bankAccount: ConvertBankAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/bank-account/convert-account', bankAccount, { responseType: 'json' });

      dispatch(FETCH_BANK_ACCOUNT, bankAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async ADD_BANK_ACCOUNT({ commit }, { data }) {
    commit(ADD_BANK_ACCOUNT_TO_DETAILS, data.details);
    commit(ADD_BANK_ACCOUNT_TO_LIST, data.details);
  }
});

export default (Vue: Vue | VueConstructor): Module<BankAccountState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
