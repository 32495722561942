




















import { Component, Prop } from 'vue-property-decorator';
import NavigationGroupItem from '@/views/application/components/NavigationGroupItem.vue';
import NavigationItem from '@/views/application/components/NavigationItem.vue';
import { namespace } from 'vuex-class';
import { InitializeMixin } from '@/views/mixin';
import { GET_LIST } from '@/modules/car/store';
import { GET_INBOX_COUNT } from '@/modules/fileManagement/store';

const CarStore = namespace('car');
const FileManagement = namespace('fileManagement');

@Component({
  components: {
    NavigationGroupItem,
    NavigationItem
  }
})
export default class Navigation extends InitializeMixin {
  @CarStore.Getter(GET_LIST) cars: any;
  @FileManagement.Getter(GET_INBOX_COUNT) inboxCount: any;

  @Prop({ type: Boolean, required: true }) value!: boolean;

  get drawer(): boolean {
    return this.value;
  }

  set drawer(drawer: boolean) {
    this.$emit('input', drawer);
  }

  get menu(): any {
    return [{
      label: 'Dashboard',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'ACCOUNTING', 'SUPPORTER'],
      to: '/',
      active: false,
      icon: 'dashboard'
    }, {
      label: 'Zeitkontingent',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      to: { name: 'time-allocation', ...(this.$route.name === 'time-allocation' ? this.$route : {}) },
      active: false,
      icon: 'access_time',
      visibility: this.$vuetify.breakpoint.mdAndUp
    }, {
      label: 'Betreuer',
      access: ['ADMINISTRATION'],
      active: false,
      icon: 'supervisor_account',
      visibility: this.$vuetify.breakpoint.mdAndUp,
      children: [
        { label: 'Rentabilität', icon: 'attach_money', to: '/conservator/profitabilities', access: ['ADMINISTRATION'] }
      ]
    }, {
      label: 'Betreuung',
      access: ['VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
      active: false,
      icon: 'assignment_ind',
      children: [
        {
          label: 'Übersicht',
          icon: 'list',
          to: { name: 'conservatorship-list', ...(this.$route.name === 'conservatorship-list' ? this.$route : {}) },
          access: ['VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER']
        },
        {
          label: 'Neue Betreuung',
          icon: 'create_new_folder',
          to: '/conservatorship/create',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        }
      ]
    }, {
      label: 'Schriftverkehr',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
      active: false,
      icon: 'insert_drive_file',
      badge: this.inboxCount,
      children: [
        {
          label: 'Übersicht',
          icon: 'list',
          to: { name: 'correspondence-letters', ...(this.$route.name === 'correspondence-letters' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
        },
        {
          label: 'Datei Suche',
          icon: 'search',
          to: { name: 'file-management-search', ...(this.$route.name === 'file-management-search' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER']
        },
        {
          label: 'Posteingang',
          icon: 'inbox',
          to: { name: 'file-management-inbox', ...(this.$route.name === 'file-management-inbox' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER']
        },
        {
          label: 'Postausgang',
          icon: 'inbox',
          to: { name: 'correspondence-outbox', ...(this.$route.name === 'correspondence-outbox' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        },
        {
          label: 'Briefvorlagen',
          icon: 'assignment',
          to: { name: 'correspondence-letter-templates', ...(this.$route.name === 'correspondence-letter-templates' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        },
        {
          label: 'E-Mail Vorlagen',
          icon: 'alternate_email',
          to: { name: 'correspondence-email-templates', ...(this.$route.name === 'correspondence-email-templates' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        }
      ]
    }, {
      label: 'Finanzverwaltung',
      access: ['VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
      active: false,
      icon: 'attach_money',
      children: [
        {
          label: 'Übersicht',
          icon: 'list',
          to: { name: 'list-of-assets-list', ...(this.$route.name === 'list-of-assets-list' ? this.$route : {}) },
          access: ['VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER']
        },
        {
          label: 'Finanz Import',
          icon: 'monetization_on',
          to: '/list-of-assets/transaction-import',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        },
        {
          label: 'Konten Import',
          icon: 'account_balance',
          to: '/list-of-assets/bank-account-import',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        },
        {
          label: 'Kontenabgleich',
          icon: 'sync',
          to: '/list-of-assets/bank-account-balance-check',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        },
        {
          label: 'Ratenrechner',
          icon: 'trending_up',
          to: '/list-of-assets/annuity-computation',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        },
        {
          label: 'Tan Verfahren',
          icon: 'settings',
          to: '/list-of-assets/import/tan-modes',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
          visibility: this.$vuetify.breakpoint.mdAndUp
        }
      ]
    }, {
      label: 'Vergütungsanträge',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR'],
      active: false,
      icon: 'assignment',
      visibility: this.$vuetify.breakpoint.mdAndUp,
      children: [
        {
          label: 'Übersicht',
          icon: 'list',
          to: { name: 'compensation-list', ...(this.$route.name === 'compensation-list' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        },
        {
          label: 'Fällige',
          icon: 'access_time',
          to: { name: 'compensation-payable', ...(this.$route.name === 'compensation-payable' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        },
        {
          label: 'Suche',
          icon: 'search',
          to: { name: 'compensation-search', ...(this.$route.name === 'compensation-search' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        }
      ]
    }, {
      label: 'Formblätter',
      access: ['CONSERVATOR'],
      visibility: this.$vuetify.breakpoint.mdAndUp && !this.$auth.check('INDEPENDENT_CONSERVATOR'),
      to: { name: 'compensation-form-sheets', ...(this.$route.name === 'compensation-form-sheets' ? this.$route : {}) },
      active: false,
      icon: 'assignment'
    }, {
      label: 'Termine',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      active: false,
      icon: 'event',
      children: [
        {
          label: 'Übersicht',
          icon: 'list',
          to: { name: 'appointment-list', ...(this.$route.name === 'appointment-list' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR']
        },
        {
          label: 'Kalender',
          icon: 'event_note',
          to: { name: 'appointment-calendar', ...(this.$route.name === 'appointment-calendar' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR']
        },
        {
          label: 'Suche',
          icon: 'search',
          to: { name: 'appointment-search', ...(this.$route.name === 'appointment-search' ? this.$route : {}) },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR']
        }
      ]
    }, {
      label: 'Fristen',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      to: { name: 'term-list', ...(this.$route.name === 'term-list' ? this.$route : {}) },
      active: false,
      icon: 'access_alarm'
    }, {
      label: 'Auto Kalender',
      visibility: this.cars.length > 0,
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      to: '/car/calendar',
      active: false,
      icon: 'directions_car'
    }, {
      label: 'Kontaktliste',
      access: ['VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
      to: { name: 'contact-address-list', ...(this.$route.name === 'contact-address-list' ? this.$route : {}) },
      active: false,
      icon: 'contact_phone'
    }, {
      label: 'Statistiken',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      active: false,
      icon: 'timeline',
      visibility: this.$vuetify.breakpoint.mdAndUp,
      children: [
        {
          label: 'Betreuungen',
          icon: 'assignment_ind',
          to: '/statistic/conservatorship',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        },
        {
          label: 'Betreuer Verteilung',
          icon: 'account_circle',
          to: '/statistic/conservator',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR']
        },
        {
          label: 'Betreuerstunden',
          icon: 'alarm',
          to: '/statistic/time-allocation',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR']
        },
        {
          label: 'Auslastung Fahrzeuge',
          icon: 'directions_car',
          to: '/statistic/car-workload',
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION'],
          visibility: this.cars.length > 0
        }
      ]
    }, {
      label: 'Nutzerverwaltung',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'INDEPENDENT_CONSERVATOR'],
      to: '/user/configuration',
      active: false,
      icon: 'account_box'
    }, {
      label: 'Steuerbüro',
      access: ['ADMINISTRATION', 'ACCOUNTING'],
      active: false,
      icon: 'attach_money',
      visibility: this.$vuetify.breakpoint.mdAndUp,
      children: [
        {
          label: 'Einstellungen',
          icon: 'settings',
          to: { name: 'accounting-overview' },
          access: ['ADMINISTRATION', 'ACCOUNTING']
        }
      ]
    }, {
      label: 'Administration',
      access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR'],
      active: false,
      icon: 'settings',
      visibility: this.$vuetify.breakpoint.mdAndUp,
      children: [
        {
          label: 'Organisation',
          icon: 'home',
          to: { name: 'admin-organization' },
          access: ['ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        },
        {
          label: 'Aufgabenkreise',
          icon: 'ballot',
          to: { name: 'admin-scope-of-functions' },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        },
        {
          label: 'Mitarbeiter',
          icon: 'supervisor_account',
          to: { name: 'admin-employees' },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        },
        {
          label: 'Fahrzeuge',
          icon: 'directions_car',
          to: { name: 'admin-cars' },
          access: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR']
        }
      ]
    }].filter(link => link.visibility !== undefined ? link.visibility : true);
  }

  beforeMount() {
    if (!this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = false;
    }
  }

  onClose() {
    if (this.$vuetify.breakpoint.mdAndUp) return;

    this.drawer = false;
  }
}
