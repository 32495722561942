


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CANCEL_APPOINTMENT, FETCH_DETAILS, GET_BY_ID } from '../store';
import { Appointment, WriteAppointment } from '../types';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '../model';

const Appointment = namespace('appointment');

@Component({})
export default class CancelAppointmentDialog extends Vue {
  @Prop({ type: String }) appointmentId?: string;

  @Appointment.Action(CANCEL_APPOINTMENT) cancelAppointment!: (appointment: WriteAppointment) => Promise<ApiResponse>;
  @Appointment.Action(FETCH_DETAILS) fetchAppointment!: (appointment: string) => Promise<ApiResponse<Appointment>>;
  @Appointment.Getter(GET_BY_ID) getById!: (id: string) => Appointment;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  appointment: WriteAppointment | null = null;
  value: Appointment | null = null;

  @Watch('appointmentId', { immediate: true })
  async onAppointmentId(appointmentId?: string) {
    if (!appointmentId) return;

    const appointment = this.getById(appointmentId);

    if (appointment) {
      this.value = appointment;
      return;
    }

    const { content, error } = await this.fetchAppointment(appointmentId);

    if (content && !error) {
      this.value = content;
    }
  }

  @Watch('value', { immediate: true })
  onAppointmentIdChange(appointment?: Appointment) {
    if (!appointment) return;

    this.appointment = MapViewToWrite(appointment);
    this.error = null;
    this.loading = false;
    this.dialog = true;
  }

  @Watch('dialog')
  onDialogClose(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;
      this.loading = false;
      this.value = null;
      this.appointment = null;
      this.$emit('update:appointmentId', '');
    }, 200);
  }

  async save() {
    if (!this.appointment) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.cancelAppointment(this.appointment);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.$emit('success', this.appointment);
    this.dialog = false;
  }
}
