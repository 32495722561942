/* eslint-disable camelcase */
import Vue, { VueConstructor } from 'vue';
import {
  Note,
  CreateNote,
  UpdateNote,
  DeleteNote,
  NoteState
} from '@/modules/notes/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';

export const FETCH_LIST = 'FETCH_NOTE_LIST';
export const FETCH_PDF = 'FETCH_NOTE_PDF';
export const GET_LIST = 'GET_NOTE_LIST';

export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

const baseURL = (): string => {
  if (process.env.VUE_APP_GATEWAY_API) {
    return process.env.VUE_APP_GATEWAY_API
  }

  return `https://prod.betreuer-plattform.de`
}

const initialState = (): NoteState => ({
  lists: {}
});

const getters: GetterTree<NoteState, RootState> = {
  [GET_LIST]: (state): { [id: string]: Note[] } => state.lists,
};

const mutations: MutationTree<NoteState> = {
  SET_NOTES(state: NoteState, { conservatorship, notes }: { conservatorship: string, notes: Note[] }) {
    state.lists = {
      ...state.lists,
      [conservatorship]: notes
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<NoteState, RootState> => ({
  async [FETCH_LIST]({ commit }, conservatorship: string) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/notes-service/conservatorship/${conservatorship}/notes`, { responseType: 'json' });
      commit('SET_NOTES', { conservatorship, notes: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_PDF]({ commit }, note: string) {
    try {
      const response = await Vue.axios.get(`api/note/${note}/pdf`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [CREATE_NOTE](_, payload: CreateNote) {
    try {
      await Vue.axios.post(`${baseURL()}/notes-service`, payload, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [UPDATE_NOTE](_, payload: UpdateNote) {
    try {
      await Vue.axios.patch(`${baseURL()}/notes-service`, payload, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [DELETE_NOTE](_, payload: DeleteNote) {
    try {
      await Vue.axios.delete(`${baseURL()}/notes-service`, { responseType: 'json', data: payload });

      return {};
    } catch (error) {
      return { error };
    }
  }
});

export default (instance: Vue | VueConstructor): Module<NoteState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
