import {
  CancelTransaction,
  Category,
  ChangeFinanceAccountOpened,
  ChangeStartDeposit,
  CloseFinanceAccount,
  CreateContraEntry, DeleteTanMode,
  FinanceAccountState,
  ImportItem,
  ImportTransactions, PersistTanMode,
  RemoveVoucher, TanModeRequest,
  Transaction,
  UpdateContraEntry,
  UpdateTransaction,
  WriteTransaction
} from '@/modules/listOfAssets/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';
import { fetchFinanceAccount, findFinanceAccount } from '@/modules/listOfAssets/store/helper';

export const FETCH_TRANSACTIONS = 'FETCH_FINANCE_ACCOUNT_TRANSACTIONS';
export const FETCH_VOUCHER = 'FETCH_FINANCE_ACCOUNT_VOUCHER';
export const CLOSE_ACCOUNT = 'CLOSE_FINANCE_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_FINANCE_ACCOUNT';
export const CHANGE_START_DEPOSIT = 'CHANGE_FINANCE_ACCOUNT_START_DEPOSIT';
export const CHANGE_OPENED = 'CHANGE_FINANCE_ACCOUNT_OPENED';
export const CREATE_CONTRA_ENTRY = 'CREATE_TRANSACTION_CONTRA_ENTRY';
export const ARE_TRANSACTIONS_LOADING = 'ARE_TRANSACTIONS_LOADING';
export const GET_TRANSACTIONS = 'GET_FINANCE_ACCOUNT_TRANSACTIONS';
export const SET_TRANSACTION_PAGE = 'SET_FINANCE_ACCOUNT_TRANSACTION_PAGE';
export const IMPORT_TRANSACTIONS = 'IMPORT_FINANCE_ACCOUNT_TRANSACTIONS';
export const IMPORT_SINGLE_ACCOUNT_TRANSACTIONS = 'IMPORT_SINGLE_FINANCE_ACCOUNT_TRANSACTIONS';
export const EXECUTE_TRANSACTION = 'EXECUTE_FINANCE_ACCOUNT_TRANSACTION';
export const CANCEL_TRANSACTION = 'CANCEL_FINANCE_ACCOUNT_TRANSACTION';
export const REMOVE_VOUCHER = 'REMOVE_FINANCE_ACCOUNT_TRANSACTION_VOUCHER';
export const REVERSE_TRANSACTION = 'REVERSE_FINANCE_ACCOUNT_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_FINANCE_ACCOUNT_TRANSACTION';
export const UPDATE_CONTRA_ENTRY = 'UPDATE_FINANCE_ACCOUNT_CONTRA_ENTRY';
export const FETCH_IMPORT = 'FETCH_IMPORT';
export const VERIFY_IMPORT = 'VERIFY_IMPORT';
export const SEND_TRANSACTION_TAN = 'SEND_TRANSACTION_TAN';
export const BANK_ACCOUNT_IMPORT = 'BANK_ACCOUNT_IMPORT';
export const GET_IMPORTS = 'GET_IMPORTS';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

export const REQUEST_TAN_MODES = 'IMPORT_REQUEST_TAN_MODES';
export const FETCH_TAN_MODE_LIST = 'IMPORT_FETCH_TAN_MODES';
export const PERSIST_TAN_MODE = 'IMPORT_PERSIST_TAN_MODE';
export const DELETE_TAN_MODE = 'IMPORT_DELETE_TAN_MODE';

const initialState = (): FinanceAccountState => ({
  transactions: {},
  transactionLoading: {},
  imports: []
});

const getters: GetterTree<FinanceAccountState, RootState> = {
  [GET_TRANSACTIONS]: (state) => (id: string) => {
    return state.transactions[id] || { page: 1, items: [] };
  },
  [ARE_TRANSACTIONS_LOADING]: (state) => (financeAccount: string): boolean => {
    return state.transactionLoading[financeAccount] || false;
  },
  [GET_IMPORTS]: (state): ImportItem[] => {
    return state.imports;
  }
};

const mutations: MutationTree<FinanceAccountState> = {
  [SET_TRANSACTIONS](state, { items, finance_account_id }: { finance_account_id: string, items: Transaction[] }) {
    state.transactions = {
      ...state.transactions,
      [finance_account_id]: {
        items,
        page: state.transactions[finance_account_id] ? state.transactions[finance_account_id].page : 1
      }
    };
  },
  LOAD_TRANSACTIONS_START(state, finance_account_id: string) {
    state.transactionLoading = {
      ...state.transactionLoading,
      [finance_account_id]: true
    };
  },
  LOAD_TRANSACTIONS_FINISHED(state, finance_account_id: string) {
    state.transactionLoading = {
      ...state.transactionLoading,
      [finance_account_id]: false
    };
  },
  [SET_TRANSACTION_PAGE](state, { finance_account_id, page }: { finance_account_id: string, page: number }) {
    state.transactions = {
      ...state.transactions,
      [finance_account_id]: {
        ...(state.transactions[finance_account_id] || []),
        page
      }
    };
  },
  SET_IMPORTS(state, items: ImportItem[]) {
    state.imports = items;
  },
  ADD_TRIGGERED(state, item: ImportItem) {
    state.imports = [
      ...state.imports,
      item
    ];
  },
  SET_IMPORT(state, item: ImportItem) {
    const index = state.imports.findIndex((importItem) => importItem.id === item.id);

    if (index !== -1) {
      state.imports.splice(index, 1);
    }

    state.imports = [
      ...state.imports,
      item
    ];
  },
  REMOVE_IMPORT(state, item: ImportItem) {
    item.removed = true;

    const index = state.imports.findIndex((importItem) => importItem.id === item.id);

    if (index !== -1) {
      state.imports.splice(index, 1);
    }

    state.imports = [
      item,
      ...state.imports
    ];
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<FinanceAccountState, RootState> => ({
  async [CLOSE_ACCOUNT]({ commit, dispatch }, financeAccount: CloseFinanceAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/finance-account/close-account`, financeAccount, { responseType: 'json' });

      fetchFinanceAccount(
        dispatch,
        financeAccount.finance_account_id,
        financeAccount.category
      );

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_ACCOUNT]({ commit }, financeAccount: { finance_account_id: string, category: Category }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/finance-account/remove-account`, financeAccount, { responseType: 'json' });

      switch (financeAccount.category) {
        case 'bank_account':
          commit('listOfAssets/bankAccount/REMOVE_ACCOUNT', financeAccount.finance_account_id, { root: true });
          break;
        case 'cash_account':
          commit('listOfAssets/cashAccount/REMOVE_ACCOUNT', financeAccount.finance_account_id, { root: true });
          break;
        case 'credit_account':
          commit('listOfAssets/creditAccount/REMOVE_ACCOUNT', financeAccount.finance_account_id, { root: true });
      }

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_START_DEPOSIT]({ commit, dispatch }, financeAccount: ChangeStartDeposit): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', financeAccount.finance_account_id);

      const response = await Vue.axios.post('api/commands/financial-administration/finance-account/record-start-deposit', financeAccount, { responseType: 'json' });

      fetchFinanceAccount(
        dispatch,
        financeAccount.finance_account_id,
        financeAccount.category
      );

      dispatch(FETCH_TRANSACTIONS, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', financeAccount.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_OPENED]({ commit, dispatch }, { finance_account_id, opened, list_of_assets_id, category }: ChangeFinanceAccountOpened): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/finance-account/change-opened', {
        finance_account_id,
        opened
      }, { responseType: 'json' });

      fetchFinanceAccount(
        dispatch,
        finance_account_id,
        category
      );

      dispatch(FETCH_TRANSACTIONS, finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [EXECUTE_TRANSACTION]({ commit, dispatch, rootGetters }, transaction: WriteTransaction): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', transaction.finance_account_id);

      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/execute-${transaction.type}`, transaction, { responseType: 'json' });
      dispatch(FETCH_TRANSACTIONS, transaction.finance_account_id);

      const financeAccount = findFinanceAccount(rootGetters, transaction.finance_account_id);

      if (financeAccount) {
        fetchFinanceAccount(
          dispatch,
          financeAccount.id,
          financeAccount.category
        );
      }

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', transaction.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [IMPORT_TRANSACTIONS]({ commit, dispatch }, request: ImportTransactions): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', request.finance_account_id);

      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/import`, request, { responseType: 'json' });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', request.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [IMPORT_SINGLE_ACCOUNT_TRANSACTIONS]({ commit, dispatch }, request: ImportTransactions): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', request.finance_account_id);

      const response = await Vue.axios.post(`api/commands/financial-administration/account-transaction/import`, request, { responseType: 'json' });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', request.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CANCEL_TRANSACTION]({ commit, dispatch, rootGetters }, transaction: CancelTransaction): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', transaction.finance_account_id);

      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/cancel-transaction`, transaction, { responseType: 'json' });
      dispatch(FETCH_TRANSACTIONS, transaction.finance_account_id);

      const financeAccount = findFinanceAccount(rootGetters, transaction.finance_account_id);

      if (financeAccount) {
        fetchFinanceAccount(
          dispatch,
          financeAccount.id,
          financeAccount.category
        );
      }

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', transaction.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REVERSE_TRANSACTION]({ commit, dispatch, rootGetters }, transaction: WriteTransaction): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', transaction.finance_account_id);

      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/reverse-transaction`, transaction, { responseType: 'json' });
      dispatch(FETCH_TRANSACTIONS, transaction.finance_account_id);

      const financeAccount = findFinanceAccount(rootGetters, transaction.finance_account_id);

      if (financeAccount) {
        fetchFinanceAccount(
          dispatch,
          financeAccount.id,
          financeAccount.category
        );
      }

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', transaction.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_TRANSACTION]({ commit, dispatch, rootGetters }, transaction: UpdateTransaction): Promise<ApiResponse> {
    try {
      commit('LOAD_TRANSACTIONS_START', transaction.finance_account_id);

      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/update-transaction`, transaction, { responseType: 'json' });
      dispatch(FETCH_TRANSACTIONS, transaction.finance_account_id);

      const financeAccount = findFinanceAccount(rootGetters, transaction.finance_account_id);

      if (financeAccount) {
        fetchFinanceAccount(
          dispatch,
          financeAccount.id,
          financeAccount.category
        );
      }

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit('LOAD_TRANSACTIONS_FINISHED', transaction.finance_account_id);
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CREATE_CONTRA_ENTRY]({ commit, dispatch }, contraEntry: CreateContraEntry): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/create-contra-entry`, contraEntry, { responseType: 'json' });

      dispatch(FETCH_TRANSACTIONS, contraEntry.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_CONTRA_ENTRY]({ commit, dispatch }, contraEntry: UpdateContraEntry): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/transaction/update-contra-entry`, contraEntry, { responseType: 'json' });

      dispatch(FETCH_TRANSACTIONS, contraEntry.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_TRANSACTIONS]({ commit }, finance_account_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/finance-account/${finance_account_id}/transactions`, { responseType: 'json' });

      commit('SET_TRANSACTIONS', { items: response.data, finance_account_id });
      commit('LOAD_TRANSACTIONS_FINISHED', finance_account_id);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_VOUCHER]({ commit }, transaction_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/${transaction_id}/voucher`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [REMOVE_VOUCHER]({ commit, dispatch }, transaction: RemoveVoucher): Promise<ApiResponse> {
    try {
       await Vue.axios.post(`api/commands/financial-administration/transaction/remove-voucher`, transaction, { responseType: 'json' });

      dispatch(FETCH_TRANSACTIONS, transaction.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {}
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_IMPORT]({ commit }, employeeId: string) {
    try {
      const response = await Vue.axios.get(`api/financial-administration/import/${employeeId}`, { responseType: 'json' });

      commit('SET_IMPORTS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [VERIFY_IMPORT]({ commit }, verifyImport: { import_id: string; tan: string }) {
    try {
      await Vue.axios.post(`api/commands/financial-administration/transaction/verify-import`, verifyImport, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [SEND_TRANSACTION_TAN]({ commit }, verifyImport: { username: string, pin: string, tan: string }) {
    try {
      await Vue.axios.post(`api/commands/financial-administration/account-transaction/verify-import`, verifyImport, { responseType: 'json' });

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [BANK_ACCOUNT_IMPORT]({ commit }, data) {
    try {
      await Vue.axios.post('api/commands/financial-administration/bank-account/import', data, { responseType: 'json' });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REQUEST_TAN_MODES](_, request: TanModeRequest) {
    try {
      const { data } = await Vue.axios.post('api/fints3/sepa-tan-modes', request, { responseType: 'json' });

      return { content: data };
    } catch (error) {

      return { error };
    }
  },
  async [PERSIST_TAN_MODE]({ commit }, request: PersistTanMode) {
    try {
      await Vue.axios.post('api/commands/financial-administration/import/persist-tan-mode', request, { responseType: 'json' });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [DELETE_TAN_MODE]({ commit }, request: DeleteTanMode) {
    try {
      await Vue.axios.post('api/commands/financial-administration/import/delete-tan-mode', request, { responseType: 'json' });
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_TAN_MODE_LIST]() {
    try {
      const response = await Vue.axios.get(`api/financial-administration/import/tan-mode/list`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async ADD_TRIGGERED({ commit }, { data }) {
    commit(`ADD_TRIGGERED`, data.import);
  },
  async SET_STARTED({ commit }, { data }) {
    commit(`SET_IMPORT`, data.import);
  },
  async SET_TAN_REQUEST({ commit }, { data }) {
    commit(`SET_IMPORT`, data.import);
  },
  async SET_ERRORED({ commit }, { data }) {
    commit(`SET_IMPORT`, data.import);

    setTimeout(() => {
      commit(`REMOVE_IMPORT`, data.import);
    }, 5000);
  },
  async SET_COMPLETED({ commit, dispatch }, { data }) {
    commit(`SET_IMPORT`, data.import);

    setTimeout(() => {
      commit(`REMOVE_IMPORT`, data.import);
    }, 5000);
  }
});

export default (Vue: Vue | VueConstructor): Module<FinanceAccountState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
