import { Court, CourtState, WriteCourt } from '@/modules/court/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';

export const FETCH_LIST = 'FETCH_COURT_LIST';
export const GET_LIST = 'GET_COURT_LIST';
export const GET_BY_ID = 'GET_COURT_BY_ID';
export const UPDATE = 'UPDATE_COURT';
export const CREATE = 'CREATE_COURT';

const initialState = (): CourtState => ({
  list: []
});

const getters: GetterTree<CourtState, RootState> = {
  [GET_LIST]: (state) => state.list,
  [GET_BY_ID]: (state) => (id: string) => state.list.find((court) => court.id === id)
};

const mutations: MutationTree<CourtState> = {
  SET_LIST_ITEMS: (state: CourtState, items: Court[]) => {
    state.list = items;
  },
  UPDATE_ITEM: (state, item) => {
    const index = state.list.findIndex((entry) => entry.id === item.id);

    state.list.splice(index, 1);

    state.list = [
      ...state.list,
      item
    ];
  },
  ADD_ITEM: (state, item) => {
    state.list = [
      ...state.list,
      item
    ];
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<CourtState, RootState> => ({
  [FETCH_LIST]: async ({ commit }) => {
    try {
      const response = await Vue.axios.get('api/court/list', { responseType: 'json' });

      commit('SET_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [UPDATE]: async ({ commit, dispatch }, updateCourt: WriteCourt) => {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/court/update', updateCourt, { responseType: 'json' });

      await dispatch(FETCH_LIST);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: null };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [CREATE]: async ({ commit, dispatch }, createCourt: WriteCourt) => {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/court/create', createCourt, { responseType: 'json' });

      await dispatch(FETCH_LIST);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_LIST: ({ commit }, { data }: { data: { courts: Court[] }})  => {
    commit('SET_LIST_ITEMS', data.courts);
  }
});

export default (instance: Vue | VueConstructor): Module<CourtState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
