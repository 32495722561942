







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class ResponseButton extends Vue {
  @Prop() label!: string;
  @Prop() icon!: string;
  @Prop() color?: string;
}
