/* eslint-disable camelcase */
import Vue, { VueConstructor } from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Data, RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { Term, TermData, TermsData, TermState, WriteTerm } from '@/modules/term/types';
import { ApiResponse } from '@/components/types';
import { create } from '@/modules/term/model';

export const FETCH_LIST = 'FETCH_TERM_LIST';
export const FETCH_DETAILS = 'FETCH_TERM_DETAILS';
export const ADD_TERM = 'ADD_TERM';
export const CHANGE_TERM = 'CHANGE_TERM';
export const FINISH_TERM = 'FINISH_TERM';
export const SET_IN_PROGRESS = 'SET_TERM_IN_PROGRESS';
export const RESET_PROGRESS = 'RESET_TERM';
export const REMOVE_TERM = 'REMOVE_TERM';

export const SET_LIST_PAGE = 'SET_TERM_LIST_PAGE';
export const GET_LIST = 'GET_TERM_LIST';
export const GET_PAGE = 'GET_TERM_LIST_PAGE';
export const GET_BY_ID = 'GET_TERM_BY_ID';

export const INIT_CREATE = 'INIT_TERM_CREATE';
export const GET_CREATE = 'GET_TERM_CREATE';
export const GET_UPDATE = 'GET_TERM_UPDATE';

export const SET_CREATE = 'SET_TERM_CREATED';
export const SET_UPDATE = 'SET_TERM_UPDATED';

const baseURL = (): string => {
  if (process.env.VUE_APP_GATEWAY_API) {
    return process.env.VUE_APP_GATEWAY_API
  }

  return `https://prod.betreuer-plattform.de`
}

const initialState = (): TermState => ({
  create: null,
  update: null,
  list: {
    items: [],
    page: 1
  }
});

const getters: GetterTree<TermState, RootState> = {
  [GET_LIST]: (state: TermState) => state.list.items,
  [GET_CREATE]: (state: TermState) => state.create,
  [GET_UPDATE]: (state: TermState) => state.update,
  [GET_PAGE]: (state: TermState) => state.list.page,
  [GET_BY_ID]: (state: TermState) => (id: string) => state.list.items.find((term) => term.id === id)
};

const mutations: MutationTree<TermState> = {
  [SET_LIST_PAGE]: (state, page: number) => {
    state.list.page = page;
  },
  [SET_CREATE]: (state, term: WriteTerm | null) => {
    state.create = term;
  },
  [INIT_CREATE]: (state, { employee }) => {
    state.create = create(employee);
  },
  [SET_UPDATE]: (state, term: WriteTerm | null) => {
    state.update = term;
  },
  SET_ITEMS: (state, items: Term[]) => {
    state.list.items = items;
  },
  SET_ITEM: (state: TermState, item: Term) => {
    const index = state.list.items.findIndex((term) => term.id === item.id);

    if (index !== -1) {
      state.list.items.splice(index, 1);
    }

    state.list.items = [
      ...state.list.items,
      item
    ];
  },
  REMOVE_ITEM: (state, termId: string) => {
    const index = state.list.items.findIndex((term) => term.id === termId);

    if (index !== -1) {
      state.list.items.splice(index, 1);
    }

    state.list.items = [...state.list.items];
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<TermState, RootState> => ({
  async [FETCH_LIST]({ commit }, employee?: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(baseURL() + '/terms-service/list', { responseType: 'json', params: { employee } });
      commit('SET_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_DETAILS]({ commit }, uuid): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`${baseURL()}/terms-service/details/${uuid}`, { responseType: 'json' });
      commit('SET_ITEM', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [CHANGE_TERM]({ commit, dispatch }, term: WriteTerm): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/terms-service/command/change', term, { responseType: 'json' });

      await dispatch(FETCH_DETAILS, term.term_id);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: undefined };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_TERM]({ commit, dispatch }, term): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/terms-service/command/add', term, { responseType: 'json' });

      await dispatch(FETCH_DETAILS, term.term_id);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: undefined };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [RESET_PROGRESS]({ commit, dispatch }, term: WriteTerm): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/terms-service/command/reset', { term_id: term.term_id }, { responseType: 'json' });

      await dispatch(FETCH_DETAILS, term.term_id);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: undefined };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FINISH_TERM]({ commit, dispatch }, term: WriteTerm): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/terms-service/command/finish', { term_id: term.term_id }, { responseType: 'json' });

      await dispatch(FETCH_DETAILS, term.term_id);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: undefined };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [SET_IN_PROGRESS]({ commit, dispatch }, term: WriteTerm): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/terms-service/command/in-progress', { term_id: term.term_id }, { responseType: 'json' });

      await dispatch(FETCH_DETAILS, term.term_id);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: undefined };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_TERM]({ commit, dispatch }, term: WriteTerm): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/terms-service/command/remove', { term_id: term.term_id }, { responseType: 'json' });

      commit('REMOVE_ITEM', term.term_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: undefined };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_LIST({ commit }, { data }: Data<TermsData>) {
    commit('SET_ITEMS', data.terms);
  },
  SET_DETAILS({ commit }, { data }: Data<TermData>) {
    commit('SET_ITEM', data.term);
  },
  REMOVE({ commit }, { data }: Data<TermData>) {
    commit('REMOVE_ITEM', data.term.id);
  }
});

export default (Vue: Vue | VueConstructor): Module<TermState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
