

































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_NEXT_COMPENSATION_COUNTER } from '../store';
import AppTable from '@/components/AppTable.vue';
import { NextCompensationCounter } from '../types';
import { ApiResponse } from '@/components/types';
import format from '@/filter/dateFormat';

const Compensation = namespace('compensation');

@Component({
  components: { AppTable }
})
export default class NextCompensationPlugin extends Vue {
  @Compensation.Action(FETCH_NEXT_COMPENSATION_COUNTER) fetchCounter!: () => Promise<ApiResponse<NextCompensationCounter>>;

  loading: boolean = false;

  data: NextCompensationCounter | null = null;

  get items() {
    if (!this.data) return [];

    return [
      { label: `Zum Stichtag ${format(this.data.date)}`, value: this.data.openUntilDate },
      { label: `Bis zum Monatsende ${format(this.data.date, 'MMMM')}`, value: this.data.overallOpen },
    ];
  }

  async created() {
    this.loading = true;

    const { content } = await this.fetchCounter();

    if (content) {
      this.data = content;
    }

    this.loading = false;
  }
}
