






import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_DARK_MODE } from '@/modules/employee/store';

const Employee = namespace('employee');

@Component
export default class App extends Vue {
  @Employee.Getter(GET_DARK_MODE) darkMode!: boolean;

  @Watch('darkMode', { immediate: true })
  onModeUpdate(mode: boolean) {
    this.$vuetify.theme.dark = mode;
  }
}
