
















































import { Component, Prop, Watch } from 'vue-property-decorator';
import DatePicker from '@/components/form/DatePicker.vue';
import TimeText from '@/components/form/TimeText.vue';
import CarAvailableSelect from '@/modules/car/components/CarAvailableSelect.vue';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import EmployeeSelect from '@/modules/employee/components/EmployeeSelect.vue';
import DateTime from './DateTime.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { WriteAppointment } from '@/modules/appointment/types';
import { AuthorizationMixin } from '@/views/mixin';

@Component({
  components: {
    ErrorAlert,
    DateTime,
    EmployeeSelect,
    DatePicker,
    TimeText,
    CarAvailableSelect,
    ConservatorshipSelect
  }
})
export default class AppointmentForm extends AuthorizationMixin {
  @Prop({ type: Object, required: true }) value!: WriteAppointment;
  @Prop({ type: [Object, Error] }) error: any;
  @Prop({ type: Boolean, default: false }) carRequired!: boolean;
  @Prop({ type: String }) ignoreReservation?: string;

  notAvailable: boolean = false;

  @Watch('value.begin', { deep: true })
  onBegin(begin: string) {
    if (this.value.car_id === null) {
      this.value.start = begin;
    }
  }

  @Watch('value', { deep: true })
  onAppointment(appointment: WriteAppointment | null) {
    this.$emit('input', appointment);
  }
}
