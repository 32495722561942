












































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AppTable from '@/components/AppTable.vue';
import { PossibleChange } from '../types';
import { ApiResponse } from '@/components/types';
import { FETCH_POSSIBLE_CHANGES } from '@/modules/conservatorship/store/actions';

const Conservatorship = namespace('conservatorship');

@Component({
  components: { AppTable }
})
export default class PossibleFinancialChangePlugin extends Vue {
  @Conservatorship.Action(FETCH_POSSIBLE_CHANGES) fetchPossibleChanges!: () => Promise<ApiResponse<PossibleChange[]>>;

  loading: boolean = false;

  items: PossibleChange[] = [];

  get headers() {
    return [
      { align: 'left', text: 'Betreuter', value: null, sortable: false },
      { align: 'left', text: 'Eingetragen als', value: null, sortable: false },
      { align: 'right', text: 'Aktuelles Vermögen', value: null, sortable: false },
      { align: 'right', text: '', value: null, sortable: false }
    ];
  }

  async created() {
    this.loading = true;

    const { content } = await this.fetchPossibleChanges();

    if (content) {
      this.items = content;
    }

    this.loading = false;
  }
}
