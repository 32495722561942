import { EmployeeState } from '@/modules/employee/types';
import { TermState } from '@/modules/term/types';
import { TimeAllocationState } from '@/modules/timeAllocation/types';
import { ScopeOfFunctionsState } from '@/modules/scopeOfFunctions/types';
import { CarState } from '@/modules/car/types';
import { AppointmentState } from '@/modules/appointment/types';
import { CompensationState } from '@/modules/compensation/types';
import { CourtState } from '@/modules/court/types';
import { CorrespondenceState } from '@/modules/correspondence/types';
import { ProfitabilityState } from '@/modules/profitabilities/types';
import { ConservatorshipState } from '@/modules/conservatorship/types';
import { ConservatorshipToContactState } from '@/modules/conservatorshipToContact/types';
import { ConservatorshipToScopeOfFunctionsState } from '@/modules/conservatorshipToScopeOfFunctions/types';
import {
  BankAccountState,
  CashAccountState,
  CreditAccountState,
  FinanceAccountState,
  ListOfAssetsState
} from '@/modules/listOfAssets/types';
import { ReportOfConservatorshipManagementState } from '@/modules/reportOfConservatorshipManagement/types';
import { OrganizationState } from '@/modules/organization/types';

export interface AppState {
  successAlert: boolean;
  errorAlert: boolean;
  socket: boolean;
}

export interface RootState extends AppState {
  employee?: EmployeeState;
  term?: TermState;
  timeAllocation?: TimeAllocationState;
  scopeOfFunctions?: ScopeOfFunctionsState;
  car?: CarState;
  appointment?: AppointmentState;
  compensation?: CompensationState;
  organization?: OrganizationState;
  court?: CourtState;
  correspondence?: CorrespondenceState;
  profitabilities?: ProfitabilityState;
  conservatorship?: ConservatorshipState;
  conservatorshipToContactState?: ConservatorshipToContactState;
  conservatorshipToScopeOfFunctionsState?: ConservatorshipToScopeOfFunctionsState;
  reportOfConservatorshipManagement?: ReportOfConservatorshipManagementState;
  listOfAssets?: ListOfAssetsState & {
    financeAccount: FinanceAccountState;
    cashAccount: CashAccountState;
    creditAccount: CreditAccountState;
    bankAccount: BankAccountState;
  };
}

export interface Data<T> {
  data: T;
}

/* eslint-disable camelcase */
export const SET_ALERT_SUCCESS = 'SET_ALERT_SUCCESS';
export const SET_ALERT_ERROR = 'SET_ALERT_ERROR';
export const GET_ALERT_SUCCESS = 'GET_ALERT_SUCCESS';
export const GET_ALERT_ERROR = 'GET_ALERT_ERROR';
