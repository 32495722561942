/* eslint-disable camelcase */
import Vue, { VueConstructor } from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { ApiResponse } from '@/components/types';
import { AxiosResponse } from 'axios';
import { Conservator, ConservatorState, UpdateHourlyRate } from '@/modules/conservator/types';

export const UPDATE_HOURLY_RATE = 'UPDATE_CONSERVATOR_HOURLY_RATE';

export const FETCH_CONSERVATOR_LIST = 'FETCH_CONSERVATOR_LIST';
export const GET_CONSERVATOR_LIST = 'GET_CONSERVATOR_LIST';
export const GET_CONSERVATOR_BY_ID = 'GET_CONSERVATOR_BY_ID';

const initialState = (): ConservatorState => ({
  conservators: []
});

const getters: GetterTree<ConservatorState, RootState> = {
  [GET_CONSERVATOR_BY_ID]: (state) => (conservatorId: string) => state.conservators.find((conservator) => {
    return conservator.id === conservatorId;
  }),
  [GET_CONSERVATOR_LIST]: (state) => state.conservators
};

const mutations: MutationTree<ConservatorState> = {
  SET_CONSERVATOR_ITEMS(state, items: Conservator[]) {
    state.conservators = items;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ConservatorState, RootState> => ({
  [FETCH_CONSERVATOR_LIST]: async ({ commit }): Promise<ApiResponse<Conservator[]>> => {
    try {
      const response = await Vue.axios.get<Conservator[], AxiosResponse<Conservator[]>>('api/commands/conservatorship-management/conservator/list', { responseType: 'json' });
      commit('SET_CONSERVATOR_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [UPDATE_HOURLY_RATE]: async ({ commit, dispatch }, command: UpdateHourlyRate): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/conservatorship-management/conservator/correct-hourly-rate', command, { responseType: 'json' });

      await dispatch(FETCH_CONSERVATOR_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  }
});

export default (instance: Vue | VueConstructor): Module<ConservatorState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
