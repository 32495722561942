
















































import { Component } from 'vue-property-decorator';
import AppointmentsPlugin from '@/modules/appointment/components/AppointmentsPlugin.vue';
import UnavailablePlugin from '@/modules/appointment/components/UnavailablePlugin.vue';
import NextCompensationPlugin from '@/modules/compensation/components/NextCompensationPlugin.vue';
import CompensationIncomePlugin from '@/modules/compensation/components/CompensationIncomePlugin.vue';
import FormSheetPlugin from '@/modules/compensation/components/FormSheetPlugin.vue';
import PossibleFinancialChangePlugin from '@/modules/conservatorship/components/PossibleFinancialChangePlugin.vue';
import DueReportPlugin from '@/modules/conservatorship/components/DueReportPlugin.vue';
import TermsPlugin from '@/modules/term/components/TermsPlugin.vue';
import { AuthorizationMixin } from '@/views/mixin';
import BirthdayPlugin from '@/modules/conservatorship/components/BirthdayPlugin.vue';
import AccountingPlugin from "@/modules/accounting/components/AccountingPlugin.vue";
import ExportPlugin from "@/modules/accounting/components/ExportPlugin.vue";

@Component({
  components: {
    ExportPlugin,
    AccountingPlugin,
    BirthdayPlugin,
    TermsPlugin,
    AppointmentsPlugin,
    UnavailablePlugin,
    NextCompensationPlugin,
    CompensationIncomePlugin,
    PossibleFinancialChangePlugin,
    DueReportPlugin,
    FormSheetPlugin
  }
})
export default class Dashboard extends AuthorizationMixin {
}
