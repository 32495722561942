import {
  Accounting,
  Asset,
  ChangeOpen,
  CloseListOfAssets,
  CorrectAnnualAccounting,
  CreateAnnualAccounting,
  CreateFinalInvoice,
  CreateStartAssetsRegister,
  ListOfAssets,
  ListOfAssetsState,
  RemoveAnnualAccounting,
  State,
  WriteAsset
} from '@/modules/listOfAssets/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';
import financeAccount from './store/financeAccount';
import bankAccount from './store/bankAccount';
import creditAccount from './store/creditAccount';
import cashAccount from './store/cashAccount';
import internalAccount from './store/internalAccount';

export const FETCH_LISTS_OF_ASSETS = 'FETCH_LIST_OF_ASSETS_LIST';
export const FETCH_LIST_OF_ASSETS = 'FETCH_LIST_OF_ASSETS_DETAILS';
export const FETCH_START_ASSETS_REGISTER = 'FETCH_LIST_OF_ASSETS_START_ASSETS_REGISTER';
export const FETCH_FINAL_INVOICE = 'FETCH_LIST_OF_ASSETS_FINAL_INVOICE';
export const FETCH_ACCOUNTING = 'FETCH_LIST_OF_ASSETS_ACCOUNTING';
export const FETCH_ACCOUNTING_LIST = 'FETCH_LIST_OF_ASSETS_ACCOUNTING_LIST';
export const FETCH_ASSETS = 'FETCH_LIST_OF_ASSETS_ASSETS';
export const SET_LIST_PAGE = 'SET_LIST_OF_ASSETS_LIST_PAGE';
export const SET_LIST_STATE = 'SET_LIST_OF_ASSETS_LIST_STATE';
export const LIST_OF_ASSETS_BY_CONSERVATORSHIP = 'GET_LIST_OF_ASSETS_ITEM_BY_CONSERVATORSHIP';
export const GET_ITEM = 'GET_LIST_OF_ASSETS_ITEM';
export const GET_LIST = 'GET_LIST_OF_ASSETS_LIST';
export const GET_ASSETS = 'GET_LIST_OF_ASSETS_ASSETS';
export const GET_ACCOUNTINGS = 'GET_LIST_OF_ASSETS_ACCOUNTINGS';
export const GET_PAGE = 'GET_LIST_OF_ASSETS_LIST_PAGE';

export const CREATE_START_ASSETS_REGISTER = 'CREATE_LIST_OF_ASSETS_START_ASSETS_REGISTER';
export const CREATE_FINAL_INVOICE = 'CREATE_LIST_OF_ASSETS_FINAL_INVOICE';
export const CREATE_ANNUAL_ACCOUNTING = 'CREATE_LIST_OF_ASSETS_ANNUAL_ACCOUNTING';
export const CORRECT_ANNUAL_ACCOUNTING = 'CORRECT_LIST_OF_ASSETS_ANNUAL_ACCOUNTING';
export const REMOVE_ANNUAL_ACCOUNTING = 'REMOVE_LIST_OF_ASSETS_ANNUAL_ACCOUNTING';
export const CREATE_ASSET = 'CREATE_LIST_OF_ASSETS_ASSET';
export const REMOVE_ASSET = 'REMOVE_LIST_OF_ASSETS_ASSET';
export const UPDATE_ASSET = 'UPDATE_LIST_OF_ASSETS_ASSET';
export const UPDATE_OPENED = 'UPDATE_LIST_OF_ASSETS_OPENED';
export const CLOSE = 'CLOSE_LIST_OF_ASSETS';

export const SET_GROUPS = 'SET_GROUPS';

const initialState = (): ListOfAssetsState => ({
  listsOfAssets: {
    items: [],
    page: 1,
    state: 'open'
  },
  assets: {},
  accountings: {}
});

const getters: GetterTree<ListOfAssetsState, RootState> = {
  [GET_LIST]: (state) => (key: number): ListOfAssets | undefined => {
    return state.listsOfAssets.items[key];
  },
  [GET_ITEM]: (state) => (listOfAssetsId: string): ListOfAssets | undefined => {
    return state.listsOfAssets.items.find((item) => item.id === listOfAssetsId);
  },
  [GET_ASSETS]: (state) => (listOfAssetsId: string): Asset[] => {
    return state.assets[listOfAssetsId] || [];
  },
  [GET_ACCOUNTINGS]: (state) => (listOfAssetsId: string): Accounting[] => {
    return state.accountings[listOfAssetsId] || [];
  },
  [GET_PAGE]: (state) => {
    return state.listsOfAssets.page;
  },
  [LIST_OF_ASSETS_BY_CONSERVATORSHIP]: (state) => (conservatorshipId: string): ListOfAssets | undefined => {
    return state.listsOfAssets.items.find((item) => item.conservatorshipId === conservatorshipId);
  }
};

const mutations: MutationTree<ListOfAssetsState> = {
  SET_LIST_OF_ASSETS(state, listOfAssets: ListOfAssets[]) {
    state.listsOfAssets.items = listOfAssets;
  },
  SET_ASSETS(state, { items, listOfAssets }: { items: Asset[], listOfAssets: string }) {
    state.assets = {
      ...state.assets,
      [listOfAssets]: items
    };
  },
  SET_ACCOUNTINGS(state, { items, listOfAssets }: { items: Accounting[], listOfAssets: string }) {
    state.accountings = {
      ...state.accountings,
      [listOfAssets]: items
    };
  },
  ADD_LIST_OF_ASSETS(state, item: ListOfAssets) {
    const index = state.listsOfAssets.items.findIndex((listOfAssets) => listOfAssets.id === item.id);

    if (index !== -1) {
      state.listsOfAssets.items.splice(index, 1);
    }

    state.listsOfAssets.items = [
      ...state.listsOfAssets.items,
      item
    ];
  },
  [SET_LIST_PAGE](state, page: number) {
    state.listsOfAssets.page = page;
  },
  [SET_LIST_STATE](state, status: State | null) {
    state.listsOfAssets.state = status;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ListOfAssetsState, RootState> => ({
  async [FETCH_LISTS_OF_ASSETS]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/financial-administration/list-of-assets/list', { responseType: 'json' });

      commit('SET_LIST_OF_ASSETS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_LIST_OF_ASSETS]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/details`, { responseType: 'json' });

      commit('ADD_LIST_OF_ASSETS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_START_ASSETS_REGISTER]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/start-assets`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FINAL_INVOICE]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/final-invoice`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_ACCOUNTING]({ commit }, { listOfAssetsId, id }: { listOfAssetsId: string, id: string }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssetsId}/accounting/${id}`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_ACCOUNTING_LIST]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/accountings`, { responseType: 'json' });

      commit('SET_ACCOUNTINGS', { items: response.data, listOfAssets });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_ASSETS]({ commit }, listOfAssets: string) {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/assets`, { responseType: 'json' });

      commit('SET_ASSETS', { items: response.data, listOfAssets });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [UPDATE_OPENED]({ commit, dispatch }, { list_of_assets_id, opened }: ChangeOpen) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/update-opened', {
        list_of_assets_id,
        opened
      }, { responseType: 'json' });

      dispatch(FETCH_LIST_OF_ASSETS, list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CLOSE]({ commit, dispatch }, { list_of_assets_id, closed }: CloseListOfAssets) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/close', {
        list_of_assets_id,
        closed
      }, { responseType: 'json' });

      dispatch(FETCH_LIST_OF_ASSETS, list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { error: null };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CREATE_ASSET]({ commit, dispatch }, asset: WriteAsset) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/deposit-asset', asset, { responseType: 'json' });

      dispatch(FETCH_ASSETS, asset.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_ASSET]({ commit, dispatch }, asset: WriteAsset) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/remove-asset', asset, { responseType: 'json' });

      dispatch(FETCH_ASSETS, asset.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_ASSET]({ commit, dispatch }, asset: WriteAsset) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/update-asset', asset, { responseType: 'json' });

      dispatch(FETCH_ASSETS, asset.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CREATE_START_ASSETS_REGISTER]({ commit, dispatch }, createStartAssetsRegister: CreateStartAssetsRegister) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/create-start-assets-register', createStartAssetsRegister, { responseType: 'json' });

      dispatch(FETCH_LIST_OF_ASSETS, createStartAssetsRegister.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CREATE_FINAL_INVOICE]({ commit, dispatch }, createFinalInvoice: CreateFinalInvoice) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/create-final-invoice', createFinalInvoice, { responseType: 'json' });

      dispatch(FETCH_LIST_OF_ASSETS, createFinalInvoice.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CREATE_ANNUAL_ACCOUNTING]({ commit, dispatch }, createAnnualAccounting: CreateAnnualAccounting) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/create-annual-accounting', createAnnualAccounting, { responseType: 'json' });

      dispatch(FETCH_ACCOUNTING_LIST, createAnnualAccounting.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CORRECT_ANNUAL_ACCOUNTING]({ commit, dispatch }, correctAnnualAccounting: CorrectAnnualAccounting) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/correct-annual-accounting', correctAnnualAccounting, { responseType: 'json' });

      dispatch(FETCH_ACCOUNTING_LIST, correctAnnualAccounting.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_ANNUAL_ACCOUNTING]({ commit, dispatch }, removeAnnualAccounting: RemoveAnnualAccounting) {
    try {
      await Vue.axios.post('api/commands/financial-administration/list-of-assets/remove-annual-accounting', removeAnnualAccounting, { responseType: 'json' });

      dispatch(FETCH_ACCOUNTING_LIST, removeAnnualAccounting.list_of_assets_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_DETAILS({ commit }, { data }) {
    commit('ADD_LIST_OF_ASSETS', data.listOfAssets);
  },
  SET_ASSETS({ commit }, { data }) {
    commit('SET_ASSETS', { items: data.assets, listOfAssets: data.listOfAssets });
  },
  SET_ACCOUNTINGS({ commit }, { data }) {
    commit('SET_ACCOUNTINGS', { items: data.accountings, listOfAssets: data.listOfAssets });
  },
  [SET_GROUPS]({ commit }, { data }) {
    commit(SET_GROUPS, { items: data.groups, listOfAssets: data.listOfAssets });
  }
});

export default (Vue: Vue | VueConstructor): Module<ListOfAssetsState, RootState> => ({
  namespaced: true,
  modules: {
    financeAccount: financeAccount(Vue),
    bankAccount: bankAccount(Vue),
    creditAccount: creditAccount(Vue),
    cashAccount: cashAccount(Vue),
    internalAccount: internalAccount(Vue),
  },
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
