import { RouterChild } from '@/router';

export default [
  {
    name: 'conservatorship-management-report-edit',
    path: 'conservatorship-management-report/:id/:reportId/edit',
    component: () => import('./views/ReportEditor.vue')
  } as RouterChild,
  {
    name: 'conservatorship-management-report',
    path: 'conservatorship-management-report/:id/create',
    component: () => import('./views/ReportBuilder.vue')
  } as RouterChild
];
