











import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApiResponse } from '../types';

@Component({})
export default class AsyncSaveButton extends Vue {
  @Prop() form!: any;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() saveFunction!: () => Promise<ApiResponse>;
  @Prop({ default: 'primary darken-1' }) color!: string;

  loading: boolean = false;

  btnColor: string = this.color;

  async save() {
    this.$emit('on-start');
    this.btnColor = this.color;

    if (this.form) {
      const result = await this.form.validate();

      if (!result) {
        this.$emit('validation-error');
        return;
      }
    }

    this.loading = true;
    const { error } = await this.saveFunction();
    this.loading = false;

    if (error) {
      this.$emit('on-error', error);
      this.btnColor = 'error';

      setTimeout(() => {
        this.btnColor = this.color
      }, 3000);

      return;
    }

    this.$emit('on-success');
  }
}
