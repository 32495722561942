






















































import { Component, Watch, Prop } from 'vue-property-decorator';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { AuthorizationMixin } from '@/views/mixin';
import DatePicker from '@/components/form/DatePicker.vue';

@Component({
  components: {
    DatePicker,
    ErrorAlert
  }
})
export default class ListExportDialog extends AuthorizationMixin {
  @Prop({ type: String }) conservatorship?: string;

  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  file: string | null = null;
  format: string | null = null;

  orderBy: string = 'deadline';
  begin: string | null = null;
  end: string | null = null;

  items: Array<{ text: string, value: string }> = [
    { text: 'Fristende', value: 'deadline' },
    { text: 'Fristbeginn', value: 'begin' },
  ];

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;
      this.file = null;
      this.loading = false;
    }, 200);
  }

  async save() {
    this.error = null;
    this.loading = true;

    try {
      const response = await this.$http.get(`api/term/pdf-list`, {
        responseType: 'blob',
        params: {
          order_by: this.orderBy,
          from: this.begin,
          to: this.end,
          conservatorship: this.conservatorship
        }
      });

      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
