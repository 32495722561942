import { mixins } from 'vue-class-component';
import Searchable from './Searchable.vue';
import Paginatable from './Paginatable.vue';
import Authorization from './Authorization.vue';
import Initialize from './Initialize.vue';
import { VueClass } from 'vue-class-component/lib/declarations';
import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    search?: string;
    page?: number;

    isAdmin(): boolean;

    isSupport(): boolean;

    isViewer(): boolean;

    isAccounting(): boolean;

    isSystemAdmin(): boolean;

    isSuperAdmin(): boolean;

    isConservator(): boolean;

    isIndependentConservator(): boolean;

    isAuthenticated(): boolean;

    isGranted(roles: string | string[]): boolean;
  }
}

export const SearchableMixin: VueClass<Vue> = mixins(Searchable, Authorization);
export const PaginatableMixin: VueClass<Vue> = mixins(Paginatable, Searchable, Authorization);
export const InitializeMixin: VueClass<Vue> = mixins(Initialize, Authorization);
export const AuthorizationMixin: VueClass<Vue> = mixins(Authorization);
