import Vue, { VueConstructor } from 'vue';
import { Contact, ContactPerson, ContactState, WriteContact, WriteContactPerson } from '@/modules/contact/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { ApiResponse } from '@/components/types';
import { contactValues, departmentValues } from '@/modules/contact/helper';

export const CREATE_CONTACT = 'CREATE_NEW_CONTACT';
export const CHANGE_CONTACT = 'CHANGE_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const FETCH_CONTACT_LIST = 'FETCH_CONTACT_LIST';
export const FETCH_CONTACT = 'FETCH_CONTACT';
export const FETCH_CSV = 'FETCH_CONTACT_CSV';

export const GET_HEALTH_INSURANCE_LIST = 'GET_CONTACT_HEALTH_INSURANCE_LIST';
export const GET_ADDRESS_LIST = 'GET_ADDRESS_LIST';
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_DEPARTMENT_LIST = 'GET_DEPARTMENT_LIST';
export const GET_DOCTOR_LIST = 'GET_DOCTOR_LIST';
export const GET_CONTACT_BY_ID = 'GET_CONTACT_BY_ID';
export const GET_CONTACT_PAGE = 'GET_ADDRESS_LIST_PAGE';
export const SET_CONTACT_PAGE = 'SET_CONTACT_PAGE';

export const REMOVE_CONTACT_PERSON = 'REMOVE_CONTACT_PERSON';
export const EDIT_CONTACT_PERSON = 'EDIT_CONTACT_PERSON';
export const REGISTER_CONTACT_PERSON = 'REGISTER_CONTACT_PERSON';

export const baseURL = (): string => {
  if (process.env.VUE_APP_GATEWAY_API) {
    return process.env.VUE_APP_GATEWAY_API
  }

  return `https://prod.betreuer-plattform.de`
}

const initialState = (): ContactState => ({
  contacts: [],
  contactPersons: {},
  addresses: {
    items: [],
    page: 1
  }
});

const getters: GetterTree<ContactState, RootState> = {
  [GET_CONTACT_LIST]: (state) => {
    const filterTypes = contactValues();

    return state.contacts.filter(contact => {
      for (const type of contact.types) {
        if (filterTypes.includes(type)) {
          return true
        }
      }

      return false;
    })
  },
  [GET_DEPARTMENT_LIST]: (state) => {
    const filterTypes = departmentValues();

    return state.contacts.filter(contact => {
      for (const type of contact.types) {
        if (filterTypes.includes(type)) {
          return true
        }
      }

      return false;
    })
  },
  [GET_DOCTOR_LIST]: (state) => {
    return state.contacts.filter(contact => contact.types.includes('doctor'))
  },
  [GET_ADDRESS_LIST]: (state) => state.addresses.items,
  [GET_CONTACT_BY_ID]: (state) => (id: string) => state.addresses.items.find((address) => address.id === id),
  [GET_HEALTH_INSURANCE_LIST]: (state: ContactState) => state.contacts.filter((contact) => contact.types.includes('health_insurance')),
  [GET_CONTACT_PAGE]: (state) => state.addresses.page
};

const mutations: MutationTree<ContactState> = {
  SET_CONTACT_ITEMS: (state: ContactState, list: Contact[]) => {
    state.contacts = list;
  },
  ADD_CONTACT_ITEM: (state: ContactState, contact: Contact) => {
    const index = state.contacts.findIndex((item) => item.id === contact.id);

    if (index !== -1) {
      state.contacts.splice(index, 1);
    }

    state.contacts = [
      ...state.contacts,
      contact
    ];
  },
  SET_CONTACT_PERSONS(state: ContactState, { items, address }: { items: ContactPerson[], address: string }) {
    state.contactPersons[address] = items;
  },
  SET_ADDRESS_ITEMS(state: ContactState, items: Contact[]) {
    state.addresses.items = items;
  },
  [SET_CONTACT_PAGE](state: ContactState, page: number) {
    state.addresses.page = page;
  },
  ADD_ADDRESS_ITEM(state: ContactState, address: Contact) {
    const index = state.addresses.items.findIndex((contact) => address.id === contact.id);

    if (index !== -1) {
      state.addresses.items.splice(index, 1);
    }

    state.addresses.items = [
      ...state.addresses.items,
      address
    ];
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ContactState, RootState> => ({
  [FETCH_CONTACT_LIST]: async ({ commit }): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.get(baseURL() + '/contact-service/contact', { responseType: 'json' });
      const contacts = response.data.map((contact: Contact) => ({
        ...contact,
        contactPersons: contact.contactPersons || []
      }))

      commit('SET_CONTACT_ITEMS', contacts);
      commit('SET_ADDRESS_ITEMS', contacts);

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_CONTACT]({ commit }, id: string) {
    try {
      const response = await Vue.axios.get(`${baseURL()}/contact-service/contact/${id}`, { responseType: 'json' });
      const contact: Contact = {
        ...response.data,
        contactPersons: response.data.contactPersons || []
      }
      commit('ADD_CONTACT_ITEM', contact);
      commit('ADD_ADDRESS_ITEM', contact);

      return { content: contact };
    } catch (error) {
      return { error };
    }
  },
  async [CREATE_CONTACT]({ commit, dispatch }, contact: WriteContact): Promise<ApiResponse> {
    try {
      await Vue.axios.post(baseURL() + '/contact-service/contact', contact, { responseType: 'json' });

      dispatch(FETCH_CONTACT, contact.contact_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CONTACT]({ commit, dispatch }, contact: WriteContact): Promise<ApiResponse> {
    try {
      await Vue.axios.patch(baseURL() + '/contact-service/contact', contact, { responseType: 'json' });

      dispatch(FETCH_CONTACT, contact.contact_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [DELETE_CONTACT]({ commit, dispatch }, contact: WriteContact): Promise<ApiResponse> {
    try {
      await Vue.axios.delete(baseURL() + '/contact-service/contact', { responseType: 'json', data: contact });

      dispatch(FETCH_CONTACT_LIST);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_CONTACT_PERSON]({ commit, dispatch }, contactPerson: WriteContactPerson) {
    try {
      await Vue.axios.delete(baseURL() + '/contact-service/contact-person', { responseType: 'json', data: contactPerson });
      dispatch(FETCH_CONTACT, contactPerson.contact_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [EDIT_CONTACT_PERSON]({ commit, dispatch }, contactPerson: WriteContactPerson) {
    try {
      await Vue.axios.patch(baseURL() + '/contact-service/contact-person', contactPerson, { responseType: 'json' });
      dispatch(FETCH_CONTACT, contactPerson.contact_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REGISTER_CONTACT_PERSON]({ commit, dispatch }, contactPerson: WriteContactPerson) {
    try {
      await Vue.axios.post(baseURL() + '/contact-service/contact-person', contactPerson, { responseType: 'json' });
      dispatch(FETCH_CONTACT, contactPerson.contact_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_CSV](): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`${baseURL()}/contact-service/contact/export/csv`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  SET_CONTACTS({ commit }, { data }) {
    commit('SET_CONTACT_ITEMS', data.contacts);
    commit('SET_ADDRESS_ITEMS', data.contacts);
  },
  ADD_CONTACT({ commit }, { data }) {
    commit('ADD_ADDRESS_ITEM', data.address);
  }
});

export default (instance: Vue | VueConstructor): Module<ContactState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
