/* eslint-disable camelcase */
import Vue, { VueConstructor } from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
  AddConfigurationParams,
  ChangePasswordParams, DismissConservator,
  Employee,
  EmployeeState,
  Position, RegisterAccounting,
  RegisterAdministrator,
  RegisterConservator,
  RegisterIndepenentConservator,
  RegisterJuniorAdministrator, RegisterSupporter,
  RegisterSystemAdministrator,
  TermOrdering,
  UpdateCoreData
} from '@/modules/employee/types';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { ApiResponse } from '@/components/types';
import { AxiosResponse } from 'axios';
import { FETCH_CONSERVATOR_LIST } from '@/modules/conservator/store';

export const CHANGE_PASSWORD = 'CHANGE_EMPLOYEE_PASSWORD';
export const UPDATE_CORE_DATA = 'UPDATE_EMPLOYEE_CORE_DATA';
export const DISMISS_CONSERVATOR = 'DISMISS_CONSERVATOR';
export const UPDATE_HOURLY_RATE = 'UPDATE_CONSERVATOR_HOURLY_RATE';
export const CHANGE_POSITION_TO_CONSERVATOR = 'CHANGE_POSITION_TO_CONSERVATOR';
export const FETCH_EMPLOYEE_LIST = 'FETCH_EMPLOYEE_LIST';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';

export const ADD_CUSTOM_CONFIGURATION = 'ADD_EMPLOYEE_CONFIGURATION';
export const SET_BACKGROUND_COLOR = 'SET_EMPLOYEE_BACKGROUND_COLOR';
export const GET_BACKGROUND_COLOR = 'GET_EMPLOYEE_BACKGROUND_COLOR';
export const SET_TERM_ORDERING = 'SET_EMPLOYEE_TERM_ORDERING';
export const GET_TERM_ORDERING = 'GET_EMPLOYEE_TERM_ORDERING';
export const GET_DARK_MODE = 'GET_EMPLOYEE_DARK_MODE';

export const REGISTER_SYSTEM_ADMINISTRATOR = 'REGISTER_SYSTEM_ADMINISTRATOR';
export const REGISTER_CONSERVATOR = 'REGISTER_CONSERVATOR_EMPLOYEE';
export const REGISTER_ADMINISTRATOR = 'REGISTER_ADMINISTRATOR_EMPLOYEE';
export const REGISTER_ACCOUNTING = 'REGISTER_ACCOUNTING_EMPLOYEE';
export const REGISTER_SUPPORTER = 'REGISTER_SUPPORTER_EMPLOYEE';

const initialState = (): EmployeeState => ({
  configurations: {
    backgroundColor: '',
    termOrdering: 'deadline'
  },
  employees: [],
  darkMode: window.localStorage.getItem('darkMode') === 'true'
});

const getters: GetterTree<EmployeeState, RootState> = {
  [GET_DARK_MODE]: (state) => state.darkMode,
  [GET_BACKGROUND_COLOR]: (state) => state.configurations.backgroundColor,
  [GET_TERM_ORDERING]: (state) => state.configurations.termOrdering,
  [GET_EMPLOYEE_LIST]: (state) => state.employees.filter((employee) => {
    return employee.position !== Position.SYSTEM_ADMINISTRATION;
  })
};

const mutations: MutationTree<EmployeeState> = {
  [SET_BACKGROUND_COLOR]: (state, backgroundColor: string) => {
    if (!backgroundColor) return;

    state.configurations = {
      ...state.configurations,
      backgroundColor
    };
  },
  [SET_TERM_ORDERING]: (state, termOrdering: TermOrdering) => {
    state.configurations = {
      ...state.configurations,
      termOrdering
    };
  },
  SET_LIST_ITEMS: (state, items: Employee[]) => {
    state.employees = items;
  },
  SET_DARK_MODE: (state, darkMode: boolean) => {
    window.localStorage.setItem('darkMode', darkMode.toString());
    state.darkMode = darkMode;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<EmployeeState, RootState> => ({
  [FETCH_EMPLOYEE_LIST]: async ({ commit }): Promise<ApiResponse<Employee[]>> => {
    try {
      const response = await Vue.axios.get<Employee[], AxiosResponse<Employee[]>>('api/administration/employee/list', { responseType: 'json' });
      commit('SET_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [CHANGE_PASSWORD]: async ({ commit }, { employee_id, new_password, old_password }: ChangePasswordParams): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post(
        '/api/commands/administration/employee/change-password',
        { employee_id, new_password, old_password },
        { responseType: 'json' }
      );

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [ADD_CUSTOM_CONFIGURATION]: async ({ commit }, { employee_id, configuration, value }: AddConfigurationParams): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('/api/commands/administration/employee/add-configuration',
        { employee_id, configuration, value },
        { responseType: 'json' }
      );

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [DISMISS_CONSERVATOR]: async ({ commit, dispatch }, command: DismissConservator): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/conservatorship-management/conservator/dismiss', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [REGISTER_ADMINISTRATOR]: async ({ commit, dispatch }, command: RegisterAdministrator | RegisterJuniorAdministrator): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/administration/employee/register', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [REGISTER_ACCOUNTING]: async ({ commit, dispatch }, command: RegisterAccounting): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/administration/employee/register', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [REGISTER_SUPPORTER]: async ({ commit, dispatch }, command: RegisterSupporter): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/administration/employee/register', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [REGISTER_SYSTEM_ADMINISTRATOR]: async ({ commit, dispatch }, command: RegisterSystemAdministrator): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/administration/employee/register-system-admin', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [UPDATE_CORE_DATA]: async ({ commit, dispatch }, command: UpdateCoreData): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/administration/employee/update-core-data', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [REGISTER_CONSERVATOR]: async ({ commit, dispatch }, command: RegisterConservator | RegisterIndepenentConservator): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/conservatorship-management/conservator/register', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [CHANGE_POSITION_TO_CONSERVATOR]: async ({ commit, dispatch }, command: UpdateCoreData): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.post('api/commands/conservatorship-management/conservator/change-to-conservator', command, { responseType: 'json' });

      dispatch(FETCH_EMPLOYEE_LIST);
      dispatch(`conservator/${FETCH_CONSERVATOR_LIST}`, {}, { root: true });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  }
});

export default (instance: Vue | VueConstructor): Module<EmployeeState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
