import { ValidationRule } from 'vee-validate/dist/types/types';

const isBIC: ValidationRule = {
  message: 'Der Wert ist keine valide BIC',
  validate: (value?: string): boolean => {
    return (
      !value ||
      /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
        value.toUpperCase()
      )
    );
  }
};


export default isBIC;
