






































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_UNAVAILABLE } from '../store';
import AppTable from '@/components/AppTable.vue';
import { UnavailableEntry } from '@/modules/appointment/types';
import format from '@/filter/dateFormat';

const Appointment = namespace('appointment');

@Component({
  components: { AppTable }
})
export default class UnavailablePlugin extends Vue {
  @Appointment.Action(FETCH_UNAVAILABLE) fetchUnavailable!: () => Promise<{ content: UnavailableEntry[] }>;

  loading: boolean = false;
  items: UnavailableEntry[] = [];

  async created() {
    this.loading = true;

    const { content } = await this.fetchUnavailable();

    this.items = content.map(({ start, end, ...item}) => ({
      ...item,
      start: format(start, 'HH:mm'),
      end: format(end, 'HH:mm')
    }));

    this.loading = false;
  }
}
