import {
  EmailTemplate,
  Letter,
  LetterTemplate,
  MailLetterParams,
  Outbox,
  WriteEmailTemplate,
  WriteLetter,
  WriteLetterTemplate,
  WriteOutbox
} from '@/modules/correspondence/types';
import uuid from 'uuid/v4';
import format from '@/filter/dateFormat';

export const createLetter = (employee_id: string, id?: string): WriteLetter => ({
  letter_id: id || uuid(),
  employee_id,
  conservatorship_id: null,
  headline: '',
  content: '',
  created: format(Date.now(), 'yyyy-MM-dd'),
  description: '',
  sender: {
    name: '',
    additional: '',
    street: '',
    city: '',
    zip_code: ''
  },
  receiver: {
    name: '',
    additional: '',
    street: '',
    city: '',
    zip_code: ''
  },
  receiver_type: 'all',
  faxable: false
});

export const createOutbox = (employee_id: string, id?: string): WriteOutbox => ({
  letter_id: id || uuid(),
  employee_id,
  conservatorship_id: null,
  description: '',
  receiver: {
    name: '',
    additional: '',
    street: '',
    city: '',
    zip_code: ''
  },
  receiver_type: 'all',
  mailed: format(Date.now(), 'yyyy-MM-dd')
});

export const createLetterTemplate = (employee_id: string, id?: string): WriteLetterTemplate => ({
  letter_template_id: id || uuid(),
  employee_id,
  contact_id: null,
  receiver_type: 'all',
  visibility: 'public',
  requirements: [],
  headline: '',
  content: '',
  name: ''
});

export const createEmailTemplate = (employee_id: string, id?: string): WriteEmailTemplate => ({
  email_template_id: id || uuid(),
  employee_id,
  contact_id: null,
  receiver_type: 'all',
  visibility: 'public',
  requirements: [],
  headline: '',
  content: '',
  name: ''
});

export const MapStateLetterTemplateToWriteLetterTemplate = ({ id, creator, receiverType, contactId, ...letterTemplate }: LetterTemplate): WriteLetterTemplate => ({
  letter_template_id: id,
  employee_id: creator.id,
  contact_id: contactId || null,
  receiver_type: receiverType,
  ...letterTemplate
});

export const MapStateEmailTemplateToWriteEmailTemplate = ({ id, creator, receiverType, ...emailTemplate }: EmailTemplate): WriteEmailTemplate => ({
  email_template_id: id,
  employee_id: creator.id,
  contact_id: null,
  receiver_type: receiverType,
  ...emailTemplate
});

export const MapStateOutboxToWriteOutbox = ({ description, receiver, receiverType, mailed, creator, id, conservatorship }: Outbox): WriteOutbox => ({
  letter_id: id,
  employee_id: creator.id,
  conservatorship_id: conservatorship ? conservatorship.id : null,
  description,
  receiver,
  receiver_type: receiverType,
  mailed
});

export const MapStateLetterToWriteLetter = ({ sender, content, headline, faxable, description, receiver, receiverType, mailed, creator, created, id, conservatorship }: Letter): WriteLetter => ({
  letter_id: id,
  employee_id: creator.id,
  conservatorship_id: conservatorship ? conservatorship.id : null,
  description,
  receiver,
  sender,
  content,
  created,
  headline,
  faxable,
  receiver_type: receiverType,
});

export const MapStateOutboxToMailParams = ({ id, mailed }: Outbox): MailLetterParams => ({
  letter_id: id,
  mailed: format(mailed, 'yyyy-MM-dd')
});

export const MapStateLetterToMailParams = ({ id, mailed }: Letter): MailLetterParams => ({
  letter_id: id,
  mailed: mailed ? format(mailed, 'yyyy-MM-dd') : format(Date.now(), 'yyyy-MM-dd')
});
