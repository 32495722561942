













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CompensationZipDownloadAction extends Vue {
  @Prop({ type: String, default: 'success' }) color!: string;
  @Prop({ type: Number, required: true }) year!: number;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: true }) icon!: boolean;
  @Prop({ type: String }) label?: string;
  @Prop({ type: String }) href?: string;

  error: any = null;
  loading: boolean = false;

  get filename(): string {
    return `Vergütungsanträge-${this.year}.zip`;
  }

  async download() {

    this.loading = true;

    try {
      const response = await this.$http.get(`api/compensation/${this.year}/zip`, { responseType: 'blob' });

      this.loading = false;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      link.setAttribute('download', this.filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.$emit('clicked', this.year);
    } catch (error) {
      this.error = error;
      this.loading = false;
      this.$emit('clicked', this.year);
    }
  }
}
