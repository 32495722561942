

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class TextArea extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String }) placeholder?: string;
  @Prop({ type: Object }) isEqual?: any;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) filled!: boolean;
  @Prop({ type: Boolean, default: true }) outlined!: boolean;

  get validate(): any {
    return {
      required: this.required,
      ...(this.isEqual ? { isEqual: this.isEqual } : {})
    };
  }
}
