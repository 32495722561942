import { ValidationRule } from 'vee-validate/dist/types/types';

const isEqual: ValidationRule = {
  message: `Der Wert stimmt nicht mit "{label}" überein.`,
  validate: (value: any, params: any): boolean => {
    return (!!value && params.input.length === 0) || value === params.input;
  },
  params: ['input', 'label']
};

export default isEqual;
