import {
  AddDepartment,
  AssignContactPerson,
  ConservatorshipToContactState,
  Contact,
  ContactPerson,
  Department,
  RemoveContact,
  RemoveContactPerson,
  RemoveDepartment,
  UpdateDepartment,
  WriteContact
} from '@/modules/conservatorshipToContact/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Vue, { VueConstructor } from 'vue';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { ApiResponse } from '@/components/types';
import { contactValues } from '@/modules/contact/helper';

export const FETCH_CONTACT_PERSONS = 'FETCH_CONSERVATORSHIP_CONTACT_PERSONS';
export const FETCH_DEPARTMENTS = 'FETCH_CONSERVATORSHIP_DEPARTMENTS';
export const FETCH_CONTACTS = 'FETCH_CONSERVATORSHIP_CONTACTS';

export const GET_DEPARTMENTS = 'GET_CONSERVATORSHIP_DEPARTMENTS';
export const GET_DOCTORS = 'GET_CONSERVATORSHIP_DOCTORS';
export const GET_CONTACTS = 'GET_CONSERVATORSHIP_CONTACTS';
export const GET_CONTACT_PERSONS = 'GET_CONSERVATORSHIP_CONTACT_PERSONS';
export const GET_CONTACT_PERSONS_BY_CONTACT = 'GET_CONSERVATORSHIP_CONTACT_PERSONS';

export const SET_DEPARTMENTS = 'SET_CONSERVATORSHIP_DEPARTMENTS';
export const SET_CONTACT_PERSONS = 'SET_CONSERVATORSHIP_CONTACT_PERSONS';
export const SET_CONTACTS = 'SET_CONSERVATORSHIP_CONTACTS';

export const ADD_DEPARTMENT = 'ADD_CONSERVATORSHIP_DEPARTMENT';
export const ADD_CONTACT = 'ADD_CONSERVATORSHIP_CONTACT';
export const ASSIGN_CONTACT_PERSON = 'ASSIGN_CONSERVATORSHIP_CONTACT_PERSON';
export const UPDATE_DEPARTMENT = 'UPDATE_CONSERVATORSHIP_DEPARTMENT';
export const REMOVE_CONTACT = 'REMOVE_CONSERVATORSHIP_CONTACT';
export const REMOVE_DEPARTMENT = 'REMOVE_CONSERVATORSHIP_DEPARTMENT';
export const REMOVE_CONTACT_PERSON = 'REMOVE_CONSERVATORSHIP_CONTACT_PERSON';

const baseURL = (): string => {
  if (process.env.VUE_APP_GATEWAY_API) {
    return process.env.VUE_APP_GATEWAY_API
  }

  return `https://prod.betreuer-plattform.de`
}

const initialState = (): ConservatorshipToContactState => ({
  contacts: {},
  departments: {},
  contactPersons: {},
});

const getters: GetterTree<ConservatorshipToContactState, RootState> = {
  [GET_DEPARTMENTS]: (state) => (id: string): Department[] => state.departments[id] || [],
  [GET_CONTACT_PERSONS]: (state) => (id: string): ContactPerson[] => state.contactPersons[id] || [],
  [GET_CONTACTS]: (state) => (id: string): Contact[] => {
    let filterTypes = contactValues();

    filterTypes = [...filterTypes, 'other'];

    return (state.contacts[id] || []).filter((contact) => {
      for (const type of contact.types) {
        if (filterTypes.includes(type)) {
          return true
        }
      }

      return false;
    })
  },
  [GET_DOCTORS]: (state) => (id: string): Contact[] => {
    return (state.contacts[id] || []).filter((contact) => contact.types.includes('doctor'))
  },
  [GET_CONTACT_PERSONS_BY_CONTACT]: (state) => (id: string, contact: string): ContactPerson[] => {
    return (state.contactPersons[id] || []).filter((contactPerson) => contactPerson.contactId === contact);
  }
};

const mutations: MutationTree<ConservatorshipToContactState> = {
  [SET_DEPARTMENTS](state, { id, departments }: { id: string, departments: Department[] }) {
    state.departments = {
      ...state.departments,
      [id]: departments.sort((first, second) => {
        return first.name.localeCompare(second.name);
      })
    };
  },
  [SET_CONTACT_PERSONS](state, { id, contactPersons }: { id: string, contactPersons: ContactPerson[] }) {
    state.contactPersons = {
      ...state.contactPersons,
      [id]: contactPersons
    };
  },
  [SET_CONTACTS](state, { id, contacts }: { id: string, contacts: Contact[] }) {
    state.contacts = {
      ...state.contacts,
      [id]: contacts.sort((first, second) => {
        return first.name.localeCompare(second.name);
      })
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ConservatorshipToContactState, RootState> => ({
  async [FETCH_CONTACT_PERSONS]({ commit }, conservatorship_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`${baseURL()}/contact-service/conservatorship/${conservatorship_id}/contact-persons`, { responseType: 'json' });

      commit(SET_CONTACT_PERSONS, { id: conservatorship_id, contactPersons: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_CONTACTS]({ commit }, conservatorship_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`${baseURL()}/contact-service/conservatorship/${conservatorship_id}/contacts`, { responseType: 'json' });

      commit(SET_CONTACTS, { id: conservatorship_id, contacts: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_DEPARTMENTS]({ commit }, conservatorship_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`${baseURL()}/contact-service/conservatorship/${conservatorship_id}/departments`, { responseType: 'json' });

      commit(SET_DEPARTMENTS, { id: conservatorship_id, departments: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [ADD_CONTACT]({ commit, dispatch }, contact: WriteContact): Promise<ApiResponse> {
    try {
      await Vue.axios.post(`${baseURL()}/contact-service/conservatorship/add-and-assign-contact`, contact, { responseType: 'json' });

      dispatch(FETCH_CONTACTS, contact.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_DEPARTMENT]({ commit, dispatch }, addDepartment: AddDepartment): Promise<ApiResponse> {
    try {
      await Vue.axios.post(`${baseURL()}/contact-service/department`, addDepartment, { responseType: 'json' });

      dispatch(FETCH_DEPARTMENTS, addDepartment.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_DEPARTMENT]({ commit, dispatch }, updateDepartment: UpdateDepartment): Promise<ApiResponse> {
    try {
      await Vue.axios.patch(`${baseURL()}/contact-service/department`, updateDepartment, { responseType: 'json' });

      dispatch(FETCH_DEPARTMENTS, updateDepartment.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_DEPARTMENT]({ commit, dispatch }, removeContact: RemoveDepartment): Promise<ApiResponse> {
    try {
      await Vue.axios.delete(`${baseURL()}/contact-service/department`, { responseType: 'json', data: removeContact });

      dispatch(FETCH_DEPARTMENTS, removeContact.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_CONTACT]({ commit, dispatch }, removeContact: RemoveContact): Promise<ApiResponse> {
    try {
      await Vue.axios.post(`${baseURL()}/contact-service/conservatorship/unassign-contact`, removeContact, { responseType: 'json' });

      dispatch(FETCH_CONTACTS, removeContact.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ASSIGN_CONTACT_PERSON]({ commit, dispatch }, assignContactPerson: AssignContactPerson): Promise<ApiResponse> {
    try {
      await Vue.axios.post(`${baseURL()}/contact-service/conservatorship/assign-contact-person`, assignContactPerson, { responseType: 'json' });

      dispatch(FETCH_CONTACT_PERSONS, assignContactPerson.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_CONTACT_PERSON]({ commit, dispatch }, removeContactPerson: RemoveContactPerson): Promise<ApiResponse> {
    try {
      await Vue.axios.post(`${baseURL()}/contact-service/conservatorship/unassign-contact-person`, removeContactPerson, { responseType: 'json' });

      dispatch(FETCH_CONTACT_PERSONS, removeContactPerson.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_DEPARTMENTS({ commit }, { data }) {
    commit(SET_DEPARTMENTS, { id: data.conservatorship, departments: data.departments });
  },
  SET_CONTACT_PERSONS({ commit }, { data }) {
    commit(SET_CONTACT_PERSONS, { id: data.conservatorship, contactPersons: data.list });
  },
  SET_CONTACTS({ commit }, { data }) {
    commit(SET_CONTACTS, { id: data.conservatorship, contacts: data.contacts });
  }
});

export default (Vue: Vue | VueConstructor): Module<ConservatorshipToContactState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
