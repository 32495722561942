











import { Component, Prop, Vue } from 'vue-property-decorator';
import { Repeatable } from '../types';

@Component
export default class RepeatableSelect extends Vue {
  @Prop({ default: 'none' }) value!: Repeatable;
  @Prop({ default: false }) required!: boolean;

  list = [
    { value: 'none', text: 'Keine Wiederholung' },
    { value: 'day', text: 'Tägliche Wiederholung' },
    { value: 'week', text: 'Wöchentliche Wiederholung' },
    { value: 'month', text: 'Monatliche Wiederholung' },
    { value: 'half_year', text: 'Halb Jährliche Wiederholung' },
    { value: 'year', text: 'Jährliche Wiederholung' }
  ];
}
