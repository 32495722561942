




























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import RepeatableSelect from './RepeatableSelect.vue';
import { CHANGE_TERM, GET_UPDATE, SET_UPDATE } from '@/modules/term/store';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import { TermView, WriteTerm } from '../types';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '../model';
import { AuthorizationMixin } from '@/views/mixin';

const Term = namespace('term');

@Component({
  components: {
    ConservatorSelect,
    DatePicker,
    RepeatableSelect
  }
})
export default class EditTermDialog extends AuthorizationMixin {
  @Prop() term?: TermView;

  @Term.Action(CHANGE_TERM) changeTerm!: (term: WriteTerm) => Promise<ApiResponse>;
  @Term.Getter(GET_UPDATE) getTerm!: WriteTerm | null;
  @Term.Mutation(SET_UPDATE) setTerm!: (term: WriteTerm | null) => void;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  title: string = '';

  get value(): WriteTerm | null {
    return this.getTerm;
  }

  set value(term: WriteTerm | null) {
    this.setTerm(term);
  }

  @Watch('value', { deep: true })
  onValueChange(value: WriteTerm) {
    this.setTerm(value);
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) {
      return;
    }

    setTimeout(() => {
      this.clear();
      this.$emit('update:term', null);
    }, 200);
  }

  @Watch('term', { immediate: true })
  watchTermSet(term: TermView) {
    if (!term) {
      return;
    }

    if (!this.value) {
      this.value = MapViewToWrite(term);
    }

    this.title = this.value.title;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  created() {
    if (!this.value) return;

    this.title = this.value.title;
    this.dialog = true;
  }

  clear() {
    this.value = null;
    this.error = null;

    // @ts-ignore
    this.$refs.form.reset();
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result || !this.value) return;

    this.loading = true;

    const { error } = await this.changeTerm(this.value);

    this.loading = false;

    if (error) {
      this.error = error;
      return
    }

    return this.dialog = false;
  }
}
