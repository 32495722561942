import { extend, localize, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, numeric, required } from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
import { VueConstructor } from 'vue';
import { isBIC, isEmpty, isEqual, isIBAN, isPhoneNumber } from '@/validation';

export default (Vue: VueConstructor) => {
  extend('isEmpty', isEmpty);
  extend('isEqual', isEqual);
  extend('isIBAN', isIBAN);
  extend('isBIC', isBIC);
  extend('isPhoneNumber', isPhoneNumber);


  extend('required', required);
  extend('numeric', numeric);
  extend('email', email);

// @ts-ignore
  localize('de', de);
  setInteractionMode('lazy');

  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);
}
