import { ChangeScopeOfFunctions, ConservatorshipToScopeOfFunctionsState, ScopeOfFunctions } from './types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';

export const FETCH_SCOPE_OF_FUNCTIONS = 'FETCH_CONSERVATORSHIP_SCOPE_OF_FUNCTIONS';
export const CHANGE_SCOPES_OF_FUNCTIONS = 'CHANGE_CONSERVATORSHIP_SCOPES_OF_FUNCTIONS';
export const GET_SCOPE_OF_FUNCTIONS = 'GET_CONSERVATORSHIP_SCOPE_OF_FUNCTIONS';
export const SET_SCOPE_OF_FUNCTIONS = 'SET_CONSERVATORSHIP_SCOPE_OF_FUNCTIONS';

const initialState = (): ConservatorshipToScopeOfFunctionsState => ({
  list: {}
});

const getters: GetterTree<ConservatorshipToScopeOfFunctionsState, RootState> = {
  [GET_SCOPE_OF_FUNCTIONS]: (state) => (id: string) => state.list[id] || [],
};

const mutations: MutationTree<ConservatorshipToScopeOfFunctionsState> = {
  [SET_SCOPE_OF_FUNCTIONS](state, { id, scopeOfFunctions }: { id: string, scopeOfFunctions: ScopeOfFunctions[] }) {
    state.list = {
      ...state.list,
      [id]: scopeOfFunctions.sort((first, second) => first.name.localeCompare(second.name))
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ConservatorshipToScopeOfFunctionsState, RootState> => ({
  async [FETCH_SCOPE_OF_FUNCTIONS]({ commit }, conservatorship_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship/${conservatorship_id}/scope-of-functions`, { responseType: 'json' });

      commit(SET_SCOPE_OF_FUNCTIONS, { id: conservatorship_id, scopeOfFunctions: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [CHANGE_SCOPES_OF_FUNCTIONS]({ commit, dispatch }, changeScopeOfFunctions: ChangeScopeOfFunctions): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/change-scopes-of-functions', changeScopeOfFunctions, { responseType: 'json' });

      dispatch(FETCH_SCOPE_OF_FUNCTIONS, changeScopeOfFunctions.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  }
});

export default (Vue: Vue | VueConstructor): Module<ConservatorshipToScopeOfFunctionsState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
