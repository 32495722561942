import { MutationTree } from 'vuex';
import {
  Conservatorship,
  ConservatorshipDetails,
  ConservatorshipState,
  State,
  WriteConservatorship
} from '@/modules/conservatorship/types';
import initialState from '@/modules/conservatorship/store/state';

export const SET_CREATED = 'SET_CONSERVATORSHIP_CREATED';
export const SET_LIST_PAGE = 'SET_CONSERVATORSHIP_LIST_PAGE';
export const SET_LIST_STATE = 'SET_CONSERVATORSHIP_LIST_STATE';
export const SET_LIST_SEARCH = 'SET_CONSERVATORSHIP_LIST_SEARCH';
export const SET_LIST_SCOPE_OF_FUCTIONS = 'SET_CONSERVATORSHIP_LIST_SCOPE_OF_FUCTIONS';


const mutations: MutationTree<ConservatorshipState> = {
  SET_LIST_ITEMS: (state, items: Conservatorship[]) => {
    state.list.items = items;
  },
  [SET_LIST_PAGE]: (state, page: number) => {
    state.list.page = page;
  },
  [SET_LIST_STATE]: (state, filter: State) => {
    state.list.state = filter;
  },
  [SET_LIST_SEARCH]: (state, filter: string) => {
    state.list.search = filter;
  },
  [SET_LIST_SCOPE_OF_FUCTIONS]: (state, filter: string) => {
    state.list.scopeOfFunctions = filter;
  },
  [SET_CREATED]: (state, created: WriteConservatorship | null) => {
    state.created = created;
  },
  ADD_DETAILS: (state, details: ConservatorshipDetails) => {
    state.details = {
      ...state.details,
      [details.id]: details
    };
  },
  ADD_HISTORY: (state, { history, id }) => {
    state.history = {
      ...state.history,
      [id]: history
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

export default mutations;
