/* eslint:disable */
import { emailPlaceholder, letterPlaceholder } from '@/statics/placeholders';

const autotag = (placeholders: any) => ({
  requires: 'autocomplete,textmatch',

  init: function(editor: any) {
    if (!editor) {
      return;
    }

    editor.on('instanceReady', function() {
      const list = placeholders.map((item: any) => ({
        id: item.placeholder,
        name: item.placeholder
      }));

      const config = {
        textTestCallback(range: any) {
          if (!range.collapsed) {
            return null;
          }

          // @ts-ignore
          return CKEDITOR.plugins.textMatch.match(range, (text, offset) => {
            const left = text.slice(0, offset);
            const match = left.match(/\*[a-zA-Z.]*$/);

            if (!match) {
              return null;
            }
            return { start: match.index, end: offset };
          });
        },
        dataCallback(matchInfo: any, callback: any) {
          const query = matchInfo.query;

          // Simple search.
          // Filter the entire items array so only the items that start
          // with the query remain.
          const suggestions = list.filter(
            (item: any) => item.name.toLowerCase().indexOf(query.toLowerCase()) === 0
          );

          // Note: The callback function can also be executed asynchronously
          // so dataCallback can do an XHR request or use any other asynchronous API.
          callback(suggestions);
        }
      };

      // @ts-ignore
      new CKEDITOR.plugins.autocomplete(editor, config);
    });
  }
});

// @ts-ignore
if ('CKEDITOR' in window) {
  // @ts-ignore
  CKEDITOR.plugins.add('letterAutotag', autotag(letterPlaceholder(true, true)));
  // @ts-ignore
  CKEDITOR.plugins.add('emailAutotag', autotag(emailPlaceholder(true, true)));
}
