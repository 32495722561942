












































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_COMPENSATION_INCOME } from '../store';
import AppTable from '@/components/AppTable.vue';
import { CompensationIncome } from '../types';
import { ApiResponse } from '@/components/types';
import format from '@/filter/dateFormat';

const Compensation = namespace('compensation');

@Component({
  components: { AppTable }
})
export default class CompensationIncomePlugin extends Vue {
  @Compensation.Action(FETCH_COMPENSATION_INCOME) fetchIncome!: (year: number) => Promise<ApiResponse<CompensationIncome[]>>;

  loading: boolean = false;

  items: CompensationIncome[] = [];

  year: number = (new Date()).getFullYear();
  currentYear: number = (new Date()).getFullYear();

  get headers() {
    return [
      { align: 'left', text: 'Monat', value: null, sortable: false },
      { align: 'right', text: 'Einnahmen insgesamt', value: null, sortable: false },
      { align: 'right', text: 'Einnahmen bezahlt', value: null, sortable: false },
      { align: 'right', text: 'Einnahmen offen', value: null, sortable: false }
    ];
  }

  @Watch('year', { immediate: true })
  async onYear(year: number) {
    this.loading = true;

    const { content } = await this.fetchIncome(year);

    if (content) {
      this.items = content.map(({ label, ...item }) => ({
        ...item,
        label: format(label, 'MMMM')
      }));
    }

    this.loading = false;
  }
}
