/* eslint-disable camelcase */
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { Appointment, AppointmentState, UnavailableEntry, WriteAppointment } from '@/modules/appointment/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';
import { createWriteAppointment } from '@/modules/appointment/model';
import { AxiosResponse } from 'axios';

export const FETCH_LIST = 'FETCH_APPOINTMENT_LIST';
export const FETCH_SEARCH = 'FETCH_APPOINTMENT_SEARCH';
export const FETCH_DETAILS = 'FETCH_APPOINTMENT_DETAILS';
export const FETCH_UNAVAILABLE = 'FETCH_APPOINTMENT_UNAVAILABLE';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const CHANGE_APPOINTMENT = 'CHANGE_APPOINTMENT';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const SET_LIST_PAGE = 'SET_APPOINTMENT_LIST_PAGE';
export const GET_BY_ID = 'GET_APPOINTMENT_BY_ID';
export const GET_PAGE = 'GET_APPOINTMENT_PAGE';

export const GET_CREATE = 'GET_APPOINTMENT_CREATED';
export const SET_CREATE = 'SET_APPOINTMENT_CREATED';
export const INIT_CREATE = 'INIT_APPOINTMENT_CREATED';

export const GET_UPDATE = 'GET_APPOINTMENT_UPDATE';
export const SET_UPDATE = 'SET_APPOINTMENT_UPDATE';

export const GET_CREATE_OPEN = 'GET_APPOINTMENT_CREATED_OPEN';
export const SET_CREATE_OPEN = 'SET_APPOINTMENT_CREATED_OPEN';

const initialState = (): AppointmentState => ({
  create: null,
  update: null,
  createDialog: false,
  list: {
    items: [],
    page: 1
  }
});

const getters: GetterTree<AppointmentState, RootState> = {
  [GET_BY_ID]: (state) => (id: string) => state.list.items.find((appointment) => appointment.id === id),
  [GET_UPDATE]: (state) => state.update,
  [GET_CREATE]: (state) => state.create,
  [GET_CREATE_OPEN]: (state) => state.createDialog,
  [GET_PAGE]: (state) => state.list.page
};

const mutations: MutationTree<AppointmentState> = {
  [SET_CREATE]: (state, appointment) => {
    state.create = appointment;
  },
  [SET_UPDATE]: (state, appointment) => {
    state.update = appointment;
  },
  [SET_CREATE_OPEN]: (state, open) => {
    state.createDialog = open;
  },
  [INIT_CREATE]: (state, { employee, start, begin, end }) => {
    state.create = createWriteAppointment(employee, start, begin, end);
  },
  SET_LIST_ITEMS: (state, { items }) => {
    state.list.items = items;
  },
  ADD_ITEM: (state, item) => {
    const index = state.list.items.findIndex((appointment) => item.id === appointment.id);

    if (index !== -1) {
      state.list.items.splice(index, 1);
    }

    state.list.items = [
      ...state.list.items,
      item
    ];
  },
  REMOVE_ITEM: (state, { id }) => {
    const index = state.list.items.findIndex((appointment) => id === appointment.id);

    if (index !== -1) {
      state.list.items.splice(index, 1);
    }

    state.list.items = [...state.list.items];
  },
  [SET_LIST_PAGE]: (state, page) => {
    state.list.page = page;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<AppointmentState, RootState> => ({
  [FETCH_LIST]: async ({ commit }, employee): Promise<ApiResponse<Appointment[]>> => {
    try {
      const response = await Vue.axios.get<any, AxiosResponse<Appointment[]>>('api/appointment/list', {
        responseType: 'json',
        params: { employee }
      });

      commit('SET_LIST_ITEMS', { items: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [FETCH_DETAILS]: async ({ commit }, appointment: string): Promise<ApiResponse<Appointment>> => {
    try {
      const response = await Vue.axios.get<any, AxiosResponse<Appointment>>(`api/appointment/${appointment}/details`, { responseType: 'json' });

      commit('ADD_ITEM', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [FETCH_SEARCH]: async ({ commit }, search): Promise<ApiResponse<Appointment[]>> => {
    try {
      const response = await Vue.axios.get<any, AxiosResponse<Appointment[]>>('api/appointment/search', {
        responseType: 'json',
        params: search
      });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [CHANGE_APPOINTMENT]: async ({ commit, dispatch, rootGetters }, appointment: WriteAppointment): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/appointment-management/appointment/change', appointment, { responseType: 'json' });

      dispatch(FETCH_DETAILS, appointment.appointment_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [ADD_APPOINTMENT]: async ({ commit, dispatch, rootGetters }, appointment: WriteAppointment): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/appointment-management/appointment/add', appointment, { responseType: 'json' });

      dispatch(FETCH_DETAILS, appointment.appointment_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [CANCEL_APPOINTMENT]: async ({ commit, dispatch }, { appointment_id }): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/appointment-management/appointment/cancel', { appointment_id }, { responseType: 'json' });

      commit('REMOVE_ITEM', { id: appointment_id});
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [FETCH_UNAVAILABLE]: async (): Promise<ApiResponse<UnavailableEntry[]>> => {
    try {
      const response = await Vue.axios.get<any, AxiosResponse<UnavailableEntry[]>>('api/appointment/unavailable', { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { content: [], error };
    }
  },
  SET_LIST: ({ commit }, { data }: { data: { appointments: Appointment[] } }): void => {
    commit('SET_LIST_ITEMS', { items: data.appointments });
  },
  SET_DETAILS: ({ commit }, { data }): void => {
    commit('ADD_ITEM', data.appointment);
  },
  REMOVE: ({ commit }, { data }): void => {
    commit('REMOVE_ITEM', data.appointment);
  }
});

export default (instance: Vue | VueConstructor): Module<AppointmentState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
