import { parsePhoneNumber } from 'libphonenumber-js';
import { ValidationRule } from 'vee-validate/dist/types/types';

const isPhoneNumber: ValidationRule = {
  message: 'Der Wert ist keine valide Telefonnummer',
  validate: (value: string): boolean => {
    if (!value) return true;

    try {
      return parsePhoneNumber(value, 'DE').isValid();
    } catch (e) {
      return false;
    }
  }
};

export default isPhoneNumber;
