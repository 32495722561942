import { SET_TRANSACTIONS } from '@/modules/listOfAssets/store/financeAccount';
import {
  ADD_CASH_ACCOUNT_TO_DETAILS,
  ADD_CASH_ACCOUNT_TO_LIST,
  SET_CASH_ACCOUNTS
} from '@/modules/listOfAssets/store/cashAccount';
import {
  ADD_BANK_ACCOUNT_TO_DETAILS,
  ADD_BANK_ACCOUNT_TO_LIST,
  SET_BANK_ACCOUNTS
} from '@/modules/listOfAssets/store/bankAccount';
import {
  ADD_CREDIT_ACCOUNT_TO_DETAILS,
  ADD_CREDIT_ACCOUNT_TO_LIST,
  SET_CREDIT_ACCOUNTS
} from '@/modules/listOfAssets/store/creditAccount';
import { SET_GROUPS } from '@/modules/listOfAssets/store';

export const sharedMutations = [
  `listOfAssets/financeAccount/${SET_TRANSACTIONS}`,
  `listOfAssets/cashAccount/${ADD_CASH_ACCOUNT_TO_DETAILS}`,
  `listOfAssets/bankAccount/${ADD_BANK_ACCOUNT_TO_DETAILS}`,
  `listOfAssets/creditAccount/${ADD_CREDIT_ACCOUNT_TO_DETAILS}`,
  `listOfAssets/cashAccount/${ADD_CASH_ACCOUNT_TO_LIST}`,
  `listOfAssets/bankAccount/${ADD_BANK_ACCOUNT_TO_LIST}`,
  `listOfAssets/creditAccount/${ADD_CREDIT_ACCOUNT_TO_LIST}`,
  `listOfAssets/bankAccount/${SET_BANK_ACCOUNTS}`,
  `listOfAssets/cashAccount/${SET_CASH_ACCOUNTS}`,
  `listOfAssets/creditAccount/${SET_CREDIT_ACCOUNTS}`,
  `listOfAssets/bankAccount/REMOVE_ACCOUNT`,
  `listOfAssets/cashAccount/REMOVE_ACCOUNT`,
  `listOfAssets/creditAccount/REMOVE_ACCOUNT`,
  `listOfAssets/${SET_GROUPS}`,
];
