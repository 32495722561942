
































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import RepeatableSelect from './RepeatableSelect.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import { ADD_TERM, GET_CREATE, SET_CREATE } from '@/modules/term/store';
import { ApiResponse } from '@/components/types';
import { AuthorizationMixin } from '@/views/mixin';
import { WriteTerm } from '@/modules/term/types';

const Term = namespace('term');

@Component({
  components: {
    ConservatorSelect,
    ConservatorshipSelect,
    DatePicker,
    RepeatableSelect
  }
})
export default class CreateTermDialog extends AuthorizationMixin {
  @Term.Action(ADD_TERM) addTerm!: (term: WriteTerm) => Promise<ApiResponse>;
  @Term.Getter(GET_CREATE) getCreate!: WriteTerm | null;
  @Term.Mutation(SET_CREATE) setCreate!: (term: WriteTerm | null) => void;

  @Prop({ type: String, required: false }) conservatorship?: string;

  error: any = null;
  loading: boolean = false;
  dialog = false;

  get term(): WriteTerm | null {
    return this.getCreate;
  }

  set term(term: WriteTerm | null) {
    this.setCreate(term);
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => this.clear(), 200);
  }

  @Watch('term', { immediate: true })
  watchTerm(term: WriteTerm | null) {
    if (term !== null && this.dialog === false) {
      this.dialog = true;
    }

    if (this.term && this.conservatorship) {
      this.term.conservatorship_id = this.conservatorship;
    }
  }

  clear() {
    this.error = null;
    this.term = null;
    this.loading = false;
  }

  destroyed() {
    this.clear();
  }

  async save() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result || !this.term) return;

    this.loading = true;

    const { error } = await this.addTerm(this.term);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.dialog = false;
  }
}
