
















































import { Component, Vue } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
import { SET_BACKGROUND_COLOR } from '@/modules/employee/store';

const Employee = namespace('employee');

@Component({})
export default class Login extends Vue {
  @Employee.Mutation(SET_BACKGROUND_COLOR) setBackgroundColor!: (color: string) => void;

  @Action('LOGOUT') reset!: () => void

  username: string = '';
  password: string = '';
  error: string | null = null;
  loading: boolean = false;
  showPassword: boolean = false;

  login() {
    return new Promise((resolve) => {
      this.$auth.login({
        data: { username: this.username.toLowerCase(), password: this.password },
        rememberMe: false,
        fetchUser: true,
        success: () => {
          resolve({});
        },
        error: () => {
          resolve({ error: `error.401` });
        }
      });
    });
  }

  onSuccess() {
    const user = this.$auth.user();

    if (user.configurations.background_color) {
      this.setBackgroundColor(user.configurations.background_color);
    }
  }

  created() {
    this.reset();
  }
}
