

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONSERVATOR_LIST, GET_CONSERVATOR_LIST } from '../store';
import { Conservator } from '../types';
import { ApiResponse } from '@/components/types';

const ConservatorStore = namespace('conservator');

@Component({
  inheritAttrs: false
})
export default class ConservatorSelect extends Vue {
  @Prop({ type: String }) value?: string | undefined;
  @Prop({ type: Boolean, default: false }) required!: boolean;

  @ConservatorStore.Getter(GET_CONSERVATOR_LIST) list!: Conservator[];
  @ConservatorStore.Action(FETCH_CONSERVATOR_LIST) fetch!: () => Promise<ApiResponse<Conservator[]>>;

  loading = false;

  async created() {
    if (this.list.length !== 0) return;

    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}
