

































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '../../mixin';

@Component({})
export default class NavigationGroupItem extends AuthorizationMixin {
  @Prop({ type: Object, required: true }) item!: any;

  get link(): any {
    return this.item;
  }

  set link(value: any) {
    this.$emit('input', value);
  }
}
