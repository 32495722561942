import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';
import {
  CashAccount,
  CashAccountDetails,
  CashAccountState,
  ChangeCashAccount,
  ConvertCashAccount,
  OpenCashAccount
} from '@/modules/listOfAssets/types';

export const GET_CASH_ACCOUNT = 'GET_CASH_ACCOUNT_DETAILS';
export const GET_CASH_ACCOUNTS = 'GET_CASH_ACCOUNT_LIST';

export const FETCH_CASH_ACCOUNTS = 'FETCH_CASH_ACCOUNTS';
export const FETCH_CASH_ACCOUNT = 'FETCH_CASH_ACCOUNT';

export const OPEN_CASH_ACCOUNT = 'OPEN_FINANCE_CASH_ACCOUNT';
export const CHANGE_CASH_ACCOUNT = 'CHANGE_FINANCE_CASH_ACCOUNT';
export const CONVERT_CASH_ACCOUNT = 'CONVERT_FINANCE_CASH_ACCOUNT';

export const ADD_CASH_ACCOUNT_TO_DETAILS = 'ADD_CASH_ACCOUNT_TO_DETAILS';
export const ADD_CASH_ACCOUNT_TO_LIST = 'ADD_CASH_ACCOUNT_TO_LIST';
export const SET_CASH_ACCOUNTS = 'SET_CASH_ACCOUNTS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

const initialState = (): CashAccountState => ({
  list: [],
  details: []
});

const getters: GetterTree<CashAccountState, RootState> = {
  [GET_CASH_ACCOUNT]: (state) => (id: string): CashAccountDetails | undefined => {
    return state.details.find((cashAccount) => cashAccount.id === id);
  },
  [GET_CASH_ACCOUNTS]: (state) => (listOfAssets: string): CashAccount[] => {
    return state.list.filter((cashAccount) => cashAccount.listOfAssetsId === listOfAssets);
  }
};

const mutations: MutationTree<CashAccountState> = {
  [SET_CASH_ACCOUNTS](state, cashAccounts: CashAccount[]) {
    state.list = cashAccounts;
  },
  [ADD_CASH_ACCOUNT_TO_LIST](state, cashAccount: CashAccount) {
    const index = state.list.findIndex((entry) => entry.id === cashAccount.id);

    if (index >= 0) {
      state.list.splice(index, 1);
    }

    state.list = [
      ...state.list,
      cashAccount
    ];
  },
  [ADD_CASH_ACCOUNT_TO_DETAILS](state, cashAccount: CashAccountDetails) {
    const index = state.details.findIndex((entry) => entry.id === cashAccount.id);

    if (index >= 0) {
      state.details.splice(index, 1);
    }

    state.details = [
      ...state.details,
      cashAccount
    ];
  },
  [REMOVE_ACCOUNT]: (state, cashAccount: string) => {
    const listIndex = state.list.findIndex((entry) => entry.id === cashAccount);

    if (listIndex !== -1) {
      state.list.splice(listIndex, 1);
      state.list = [...state.list];
    }

    const detailsIndex = state.details.findIndex((entry) => entry.id === cashAccount);

    if (detailsIndex !== -1) {
      state.details.splice(detailsIndex, 1);
      state.details = [...state.details];
    }
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<CashAccountState, RootState> => ({
  async [FETCH_CASH_ACCOUNTS]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/cash-accounts`, { responseType: 'json' });

      commit(SET_CASH_ACCOUNTS, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_CASH_ACCOUNT]({ commit }, cashAccountId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/cash-account/${cashAccountId}`, { responseType: 'json' });

      commit(ADD_CASH_ACCOUNT_TO_DETAILS, response.data);
      commit(ADD_CASH_ACCOUNT_TO_LIST, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [OPEN_CASH_ACCOUNT]({ commit, dispatch }, financeAccount: OpenCashAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/cash-account/open-account`, financeAccount, { responseType: 'json' });

      dispatch(FETCH_CASH_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CASH_ACCOUNT]({ commit, dispatch }, financeAccount: ChangeCashAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/cash-account/change-account', financeAccount, { responseType: 'json' });

      dispatch(FETCH_CASH_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONVERT_CASH_ACCOUNT]({ commit, dispatch }, financeAccount: ConvertCashAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/cash-account/convert-account', financeAccount, { responseType: 'json' });

      dispatch(FETCH_CASH_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async ADD_CASH_ACCOUNT({ commit }, { data }) {
    commit(ADD_CASH_ACCOUNT_TO_DETAILS, data.details);
    commit(ADD_CASH_ACCOUNT_TO_LIST, data.details);
  }
});

export default (Vue: Vue | VueConstructor): Module<CashAccountState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
