export enum Position {
  SUPPORTER = 'supporter',
  ACCOUNTING = 'accounting',
  CONSERVATOR = 'conservator',
  INDEPENDENT_CONSERVATOR = 'independent_conservator',
  ADMINISTRATION = 'administration',
  JUNIOR_ADMINISTRATION = 'junior_administration',
  SYSTEM_ADMINISTRATION = 'system_administration',
}

export type TermOrdering = 'deadline' | 'begin';

export interface Employee {
  id: string;
  name: string;
  email: string;
  salutation: string;
  position: Position;
  visible: boolean;
}

export interface EmployeeState {
  configurations: EmployeeConfiguration;
  employees: Employee[];
  darkMode: boolean;
}

interface EmployeeConfiguration {
  backgroundColor: string;
  termOrdering: TermOrdering;
}

export interface ChangePasswordParams {
  employee_id: string;
  new_password: string;
  old_password: string;
}

export interface AddConfigurationParams {
  employee_id: string;
  configuration: string;
  value: string;
}

export interface UserConfiguration {
  background_color: string;
  term_ordering: TermOrdering;
}

export interface CurrentUser {
  configurations: UserConfiguration;
}

export interface CurrentUserResponse {
  body: CurrentUser;
}

export interface UpdateCoreData {
  employee_id: string;
  salutation: string;
  full_name: string;
  email: string;
}

export interface UpdateHourlyRate {
  conservator_id: string;
  hourly_rate: number;
}

export interface ChangePositionToConservator {
  employee_id: string;
  hourly_rate: number;
}

export interface RegisterEmployee {
  employee_id: string;
  salutation: string;
  full_name: string;
  email: string;
  password: string;
  position: Position;
}

export interface RegisterConservator extends RegisterEmployee {
  hourly_rate: number;
  position: Position.CONSERVATOR;
}

export interface RegisterIndepenentConservator extends RegisterEmployee {
  hourly_rate: number;
  position: Position.INDEPENDENT_CONSERVATOR;
}

export interface RegisterJuniorAdministrator extends RegisterEmployee {
  position: Position.JUNIOR_ADMINISTRATION;
}

export interface RegisterSystemAdministrator {
  employee_id: string;
  salutation: string;
  full_name: string;
  email: string;
  password: string;
  position: Position.SYSTEM_ADMINISTRATION;
}

export interface DismissConservator {
  employee_id: string;
  date: string;
}

export interface RegisterAdministrator extends RegisterEmployee {
  position: Position.ADMINISTRATION;
}

export interface RegisterAccounting extends RegisterEmployee {
  position: Position.ACCOUNTING;
}

export interface RegisterSupporter extends RegisterEmployee {
  position: Position.SUPPORTER;
}
