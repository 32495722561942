
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
export default class Authorization extends Vue {
  public isSystemAdmin(): boolean {
    return this.$auth.check(['SYSTEM_ADMINISTRATION']);
  }

  public isAdmin(): boolean {
    return this.$auth.check(['ADMINISTRATION', 'JUNIOR_ADMINISTRATION']);
  }

  public isSupport(): boolean {
    return this.$auth.check(['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'SUPPORTER']);
  }

  public isViewer(): boolean {
    return this.$auth.check(['VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'SUPPORTER']);
  }

  public isAccounting(): boolean {
    return this.$auth.check(['ACCOUNTING']);
  }

  public isSuperAdmin(): boolean {
    return this.$auth.check(['ADMINISTRATION']);
  }

  public isConservator(): boolean {
    return this.$auth.check(['CONSERVATOR', 'INDEPENDENT_CONSERVATOR']);
  }

  public isIndependentConservator(): boolean {
    return this.$auth.check(['INDEPENDENT_CONSERVATOR']);
  }

  public isAuthenticated(): boolean {
    return this.$auth.check(['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR']);
  }

  public isGranted(roles: string | string[]): boolean {
    return this.$auth.check(roles);
  }
}
