






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REMOVE_TERM } from '../store';
import { TermView, WriteTerm } from '@/modules/term/types';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '@/modules/term/model';

const Term = namespace('term');

@Component({})
export default class RemoveTermDialog extends Vue {
  @Prop() term?: TermView;

  @Term.Action(REMOVE_TERM) removeTerm!: (term: WriteTerm) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: WriteTerm | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:term', null);
    }, 200);
  }

  @Watch('term')
  watchTermSet(term: TermView) {
    if (!term) return;

    this.value = MapViewToWrite(term);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (this.value === null) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.removeTerm(this.value);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.dialog = false;
  }
}
