import { RouterChild } from '@/router';

export default [
  {
    name: 'file-management-inbox',
    path: 'file-management/inbox',
    component: () => import('./views/Inbox.vue')
  } as RouterChild,
  {
    name: 'file-management-search',
    path: 'file-management/search',
    component: () => import('./views/Search.vue')
  } as RouterChild
];
