import { RouterChild } from '@/router';

export default [
  {
    name: 'correspondence-outbox',
    path: 'correspondence/outbox',
    component: () => import('./views/Outbox.vue')
  } as RouterChild,
  {
    name: 'correspondence-letter-templates',
    path: 'correspondence/letter-templates',
    component: () => import('./views/LetterTemplates.vue')
  } as RouterChild,
  {
    name: 'correspondence-letters',
    path: 'correspondence/letters',
    component: () => import('./views/Letters.vue')
  } as RouterChild,
  {
    name: 'correspondence-letter-create',
    path: 'correspondence/letter-create/:template/:receiverType',
    component: () => import('./views/LetterCreate.vue')
  } as RouterChild,
  {
    name: 'correspondence-letter-builder',
    path: 'correspondence/letter-builder',
    component: () => import('./views/LetterBuilder.vue')
  } as RouterChild,
  {
    name: 'correspondence-email-builder',
    path: 'correspondence/letter-builder',
    component: () => import('./views/EmailBuilder.vue')
  } as RouterChild,
  {
    name: 'correspondence-email-templates',
    path: 'correspondence/email-templates',
    component: () => import('./views/EmailTemplates.vue')
  } as RouterChild,
  {
    name: 'correspondence-email-create',
    path: 'correspondence/email-create/:template/:receiverType',
    component: () => import('./views/EmailCreate.vue')
  } as RouterChild
];
