import { RouterChild } from '@/router';

export default [
  {
    name: 'user-change-password',
    path: 'user/change-password',
    component: () => import('./views/ChangePassword.vue')
  } as RouterChild,
  {
    name: 'user-configuration',
    path: 'user/configuration',
    component: () => import('./views/Configuration.vue')
  } as RouterChild
];
