import { ConservatorshipState } from '@/modules/conservatorship/types';

const initialState = (): ConservatorshipState => ({
  list: {
    items: [],
    page: 1,
    state: 'open',
    scopeOfFunctions: '',
    search: ''
  },
  details: {},
  history: {},
  created: null
});

export default initialState;
