import { FETCH_BANK_ACCOUNT, GET_BANK_ACCOUNT } from '@/modules/listOfAssets/store/bankAccount';
import { FETCH_CASH_ACCOUNT, GET_CASH_ACCOUNT } from '@/modules/listOfAssets/store/cashAccount';
import { FETCH_CREDIT_ACCOUNT, GET_CREDIT_ACCOUNT } from '@/modules/listOfAssets/store/creditAccount';
import { FETCH_INTERNAL_ACCOUNT, GET_INTERNAL_ACCOUNT } from "@/modules/listOfAssets/store/internalAccount";
import { Category, FinanceAccount } from '@/modules/listOfAssets/types';

export const fetchFinanceAccount = (
  dispatch: (action: string, payload: any, option: { root: boolean }) => void,
  finance_account_id: string,
  category: Category
) => {
  switch (category) {
    case 'bank_account':
      dispatch(`listOfAssets/bankAccount/${FETCH_BANK_ACCOUNT}`, finance_account_id, { root: true });
      break;
    case 'cash_account':
      dispatch(`listOfAssets/cashAccount/${FETCH_CASH_ACCOUNT}`, finance_account_id, { root: true });
      break;
    case 'internal_account':
      dispatch(`listOfAssets/internalAccount/${FETCH_INTERNAL_ACCOUNT}`, finance_account_id, { root: true });
      break;
    case 'credit_account':
      dispatch(`listOfAssets/creditAccount/${FETCH_CREDIT_ACCOUNT}`, finance_account_id, { root: true });
  }
};

export const findFinanceAccount = (
  rootGetters: { [getter: string]: (finance_account_id: string) => FinanceAccount | undefined },
  finance_account_id: string
): FinanceAccount | undefined => {
  const bankAccountFinder = rootGetters[`listOfAssets/bankAccount/${GET_BANK_ACCOUNT}`];
  const cashAccountFinder = rootGetters[`listOfAssets/cashAccount/${GET_CASH_ACCOUNT}`];
  const internalAccountFinder = rootGetters[`listOfAssets/internalAccount/${GET_INTERNAL_ACCOUNT}`];
  const creditAccountFinder = rootGetters[`listOfAssets/creditAccount/${GET_CREDIT_ACCOUNT}`];

  let account = bankAccountFinder(finance_account_id);

  if (account) return account;

  account = cashAccountFinder(finance_account_id);

  if (account) return account;

  account = internalAccountFinder(finance_account_id);

  if (account) return account;

  return creditAccountFinder(finance_account_id);
};
