















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_APPOINTMENT, FETCH_DETAILS, GET_BY_ID, GET_UPDATE, SET_UPDATE } from '../store';
import AppointmentForm from './AppointmentForm.vue';
import { Appointment, WriteAppointment } from '../types';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '../model';

const Appointment = namespace('appointment');

@Component({
  components: {
    AppointmentForm
  }
})
export default class EditAppointmentDialog extends Vue {
  @Prop({ type: String }) appointmentId?: string;

  @Appointment.Action(CHANGE_APPOINTMENT) changeAppointment!: (appointment: WriteAppointment) => Promise<ApiResponse>;
  @Appointment.Action(FETCH_DETAILS) fetchAppointment!: (appointment: string) => Promise<ApiResponse<Appointment>>;
  @Appointment.Getter(GET_UPDATE) getUpdateAppointment!: WriteAppointment | null;
  @Appointment.Getter(GET_BY_ID) getById!: (id: string) => Appointment | undefined;
  @Appointment.Mutation(SET_UPDATE) setUpdateAppointment!: (appointment: WriteAppointment | null) => void;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: Appointment | null = null;

  get appointment(): WriteAppointment | null {
    return this.getUpdateAppointment;
  }

  set appointment(appointment: WriteAppointment | null) {
    this.setUpdateAppointment(appointment);
  }

  @Watch('appointmentId', { immediate: true })
  async onAppointmentId(appointmentId?: string) {
    if (!appointmentId) return;

    const appointment = this.getById(appointmentId);

    if (appointment) {
      this.value = appointment;
      return;
    }

    const { content, error } = await this.fetchAppointment(appointmentId);

    if (content && !error) {
      this.value = content;
    }
  }

  @Watch('value', { immediate: true })
  onAppointmentChange(appointment?: Appointment) {
    if (!appointment) return;

    if (!this.appointment) {
      this.appointment = MapViewToWrite(appointment);
    }

    this.error = null;
    this.loading = false;
    this.dialog = true;
  }

  close(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.$emit('update:appointmentId', '');
      this.error = null;
      this.loading = false;
      this.value = null;
      this.appointment = null;
      observer && observer.reset();
    }, 200);
  }

  onSuccess() {
    this.$emit('success', this.appointment);
    this.close()
  }

  save() {
    this.error = null;

    if (!this.appointment) return;

    return this.changeAppointment(this.appointment);
  }
}
