import { RouterChild } from '@/router';

export default [
  { name: 'appointment-list', path: 'appointments', component: () => import('./views/List.vue') } as RouterChild,
  {
    name: 'appointment-calendar',
    path: 'appointment/calendar',
    component: () => import('./views/Calendar.vue')
  } as RouterChild,
  {
    name: 'appointment-search',
    path: 'appointment/search',
    component: () => import('./views/Search.vue')
  } as RouterChild
];
