const WHITELIST = ['https://feiertage-api.de/api'];

const unauthCall = (url: string) => {
  for (const entry in WHITELIST) {
    if (url.includes(WHITELIST[entry])) {
      return true;
    }
  }

  return false;
};

const bearerAuth = {
  request(req: any, token: any) {
    if (unauthCall(req.url)) {
      return;
    }

    // @ts-ignore
    this.options.http._setHeaders.call(this, req, {
      Authorization: 'Bearer ' + token
    });
  },
  response(res: any): string {
    // @ts-ignore
    return this.options.http._httpData.call(this, res).token;
  }
};

export default {
  auth: bearerAuth,
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  rolesVar: 'position',
  loginData: {
    url: 'api/login',
    method: 'POST',
    redirect: '/',
    fetchUser: true
  },
  fetchData: {
    url: 'api/administration/employee/current',
    method: 'GET',
    enabled: true
  },
  refreshData: {
    url: 'token/refresh',
    method: 'GET',
    enabled: false,
    interval: 30
  },
  parseUserData: (data: any) => data
};
