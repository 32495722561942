/* eslint-disable camelcase */
import { VueConstructor } from 'vue';
import {
  AddBankAccount,
  AddEmail,
  AddFaxNumber,
  AddLetterhead,
  AddPhoneNumber,
  Organization,
  OrganizationState,
  RelocateOrganization
} from '@/modules/organization/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { ApiResponse } from '@/components/types';
import { AxiosResponse } from 'axios';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_LETTERHEAD = 'FETCH_ORGANIZATION_LETTERHEAD';

export const RELOCATE_ORGANIZATION = 'RELOCATE_ORGANIZATION';
export const ADD_PHONE_NUMBER = 'ADD_ORGANIZATION_PHONE_NUMBER';
export const ADD_FAX_NUMBER = 'ADD_ORGANIZATION_FAX_NUMBER';
export const ADD_EMAIL = 'ADD_ORGANIZATION_EMAIL';
export const ADD_BANK_ACCOUNT = 'ADD_ORGANIZATION_BANK_ACCOUNT';
export const ADD_LETTERHEAD = 'ADD_ORGANIZATION_LETTERHEAD';

const initialState = (): OrganizationState => ({
  organization: null
});

const getters: GetterTree<OrganizationState, RootState> = {
  [GET_ORGANIZATION]: (state) => state.organization
};

const mutations: MutationTree<OrganizationState> = {
  SET_ORGANIZATION: (state, organization: Organization) => {
    state.organization = organization;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: VueConstructor): ActionTree<OrganizationState, RootState> => ({
  [FETCH_ORGANIZATION]: async ({ commit}): Promise<ApiResponse<Organization>> => {
    try {
      const { data } = await Vue.axios.get<Organization, AxiosResponse<Organization>>('api/administration/organization');
      commit('SET_ORGANIZATION', data);

      return { content: data }
    } catch (error) {
      return { error };
    }
  },
  [FETCH_LETTERHEAD]: async ({ commit}): Promise<ApiResponse<string>> => {
    try {
      const { data } = await Vue.axios.get<{ letterhead: string; }, AxiosResponse<{ letterhead: string; }>>('api/administration/organization/letterhead');

      return { content: data.letterhead }
    } catch (error) {
      return { error };
    }
  },
  [RELOCATE_ORGANIZATION]: async ({ dispatch}, command: RelocateOrganization): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/administration/organization/relocate', command);
      await dispatch(FETCH_ORGANIZATION);

      return {}
    } catch (error) {
      return { error };
    }
  },
  [ADD_PHONE_NUMBER]: async ({ dispatch}, command: AddPhoneNumber): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/administration/organization/add-phone-number', command);
      await dispatch(FETCH_ORGANIZATION);

      return {}
    } catch (error) {
      return { error };
    }
  },
  [ADD_FAX_NUMBER]: async ({ dispatch}, command: AddFaxNumber): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/administration/organization/add-fax-number', command);
      await dispatch(FETCH_ORGANIZATION);

      return {}
    } catch (error) {
      return { error };
    }
  },
  [ADD_EMAIL]: async ({ dispatch}, command: AddEmail): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/administration/organization/add-email', command);
      await dispatch(FETCH_ORGANIZATION);

      return {}
    } catch (error) {
      return { error };
    }
  },
  [ADD_BANK_ACCOUNT]: async ({ dispatch}, command: AddBankAccount): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/administration/organization/add-bank-account', command);
      await dispatch(FETCH_ORGANIZATION);

      return {}
    } catch (error) {
      return { error };
    }
  },
  [ADD_LETTERHEAD]: async ({ dispatch}, command: AddLetterhead): Promise<ApiResponse<string>> => {
    try {
      await Vue.axios.post('api/commands/administration/organization/add-letterhead', command);

      return await dispatch(FETCH_LETTERHEAD);
    } catch (error) {
      return { error };
    }
  }
});

export default (Vue: VueConstructor): Module<OrganizationState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
