
























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_DARK_MODE } from '@/modules/employee/store';

const Employee = namespace('employee');

@Component
export default class AppUserMenu extends Vue {
  menu: boolean = false;

  @Employee.Getter(GET_DARK_MODE) darkMode!: boolean;
  @Employee.Mutation('SET_DARK_MODE') setDarkMode!: (enabled: boolean) => void;

  get mode() {
    return this.darkMode;
  }

  set mode(enabled: boolean) {
    this.setDarkMode(enabled);
  }

  options: any = [
    { icon: 'settings', title: 'Einstellungen', to: { name: 'user-change-password' } },
    { icon: 'power', title: 'Logout', click: this.logout }
  ];

  logout(): void {
    this.$auth.logout({
      redirect: '/login'
    });
  }

  stop(event: MouseEvent) {
    event.stopPropagation();
    this.mode = !this.mode;
  }
}
