
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST as FETCH_SCOPE_OF_FUNCTIONS } from '@/modules/scopeOfFunctions/store';
import { FETCH_CONTACT_LIST } from '@/modules/contact/store';
import { FETCH_LIST as FETCH_COURTS } from '@/modules/court/store';
import { FETCH_LIST as FETCH_BANKS } from '@/modules/bank/store';
import { FETCH_LIST as FETCH_CARS } from '@/modules/car/store';
import { FETCH_EMPLOYEE_LIST } from '@/modules/employee/store';
import { FETCH_CONSERVATOR_LIST } from '@/modules/conservator/store';
import { ApiResponse } from '@/components/types';
import { FETCH_INBOX_COUNT } from '@/modules/fileManagement/store';

const FileManagement = namespace('fileManagement');
const ScopesOfFunctions = namespace('scopeOfFunctions');
const Contact = namespace('contact');
const Car = namespace('car');
const Court = namespace('court');
const Bank = namespace('bank');
const Employee = namespace('employee');
const Conservator = namespace('conservator');

@Component
export default class Initialize extends Vue {
  @FileManagement.Action(FETCH_INBOX_COUNT) fetchInboxCount: any;
  @ScopesOfFunctions.Action(FETCH_SCOPE_OF_FUNCTIONS) fetchScopeOfFunctions!: () => Promise<ApiResponse>;
  @Contact.Action(FETCH_CONTACT_LIST) fetchHealthInsurances!: () => Promise<ApiResponse>;
  @Court.Action(FETCH_COURTS) fetchCourts!: () => Promise<ApiResponse>;
  @Car.Action(FETCH_CARS) fetchCars!: () => Promise<ApiResponse>;
  @Bank.Action(FETCH_BANKS) fetchBanks!: () => Promise<ApiResponse>;
  @Employee.Action(FETCH_EMPLOYEE_LIST) fetchEmployees!: () => Promise<ApiResponse>;
  @Conservator.Action(FETCH_CONSERVATOR_LIST) fetchConservators!: () => Promise<ApiResponse>;

  created() {
    if (this.isAccounting()) {
      this.fetchCourts();
      return;
    }

    this.fetchHealthInsurances();
    this.fetchScopeOfFunctions();
    this.fetchBanks();
    this.fetchCourts();
    this.fetchCars();

    if (this.isSupport()) {
      this.fetchEmployees();
      this.fetchConservators();
    }

    this.fetchInboxCount(this.$auth.user().id);

    if (this.isConservator()) {
      setInterval(() => this.fetchInboxCount(this.$auth.user().id), 30000)
    }
  }
}
