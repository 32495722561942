export default [
  {
    name: 'statistic-conservatorship',
    path: 'statistic/conservatorship',
    component: () => import('./views/Conservatorship.vue'),
    meta: {
      auth: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR'],
      redirect: {
        name: 'app'
      }
    }
  },
  {
    name: 'statistic-car-workload',
    path: 'statistic/car-workload',
    component: () => import('./views/CarWorkload.vue'),
    meta: {
      auth: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION'],
      redirect: {
        name: 'app'
      }
    }
  },
  {
    name: 'statistic-conservator',
    path: 'statistic/conservator',
    component: () => import('./views/Conservator.vue'),
    meta: {
      auth: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      redirect: {
        name: 'app'
      }
    }
  },
  {
    name: 'statistic-time-allocation',
    path: 'statistic/time-allocation',
    component: () => import('./views/TimeAllocation.vue'),
    meta: {
      auth: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR'],
      redirect: {
        name: 'app'
      }
    }
  }
];
