import {
  ChangeCreditAccount,
  ConvertCreditAccount,
  CreditAccount,
  CreditAccountDetails,
  CreditAccountState,
  OpenCreditAccount
} from '@/modules/listOfAssets/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';

export const GET_CREDIT_ACCOUNT = 'GET_CREDIT_ACCOUNT_DETAILS';
export const GET_CREDIT_ACCOUNTS = 'GET_CREDIT_ACCOUNT_LIST';

export const FETCH_CREDIT_ACCOUNTS = 'FETCH_CREDIT_ACCOUNTS';
export const FETCH_CREDIT_ACCOUNT = 'FETCH_CREDIT_ACCOUNT';

export const OPEN_CREDIT_ACCOUNT = 'OPEN_FINANCE_CREDIT_ACCOUNT';
export const CHANGE_CREDIT_ACCOUNT = 'CHANGE_FINANCE_CREDIT_ACCOUNT';
export const CONVERT_CREDIT_ACCOUNT = 'CONVERT_FINANCE_CREDIT_ACCOUNT';

export const ADD_CREDIT_ACCOUNT_TO_DETAILS = 'ADD_CREDIT_ACCOUNT_TO_DETAILS';
export const ADD_CREDIT_ACCOUNT_TO_LIST = 'ADD_CREDIT_ACCOUNT_TO_LIST';
export const SET_CREDIT_ACCOUNTS = 'SET_CREDIT_ACCOUNTS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

const initialState = (): CreditAccountState => ({
  list: [],
  details: []
});

const getters: GetterTree<CreditAccountState, RootState> = {
  [GET_CREDIT_ACCOUNT]: (state) => (id: string): CreditAccountDetails | undefined => {
    return state.details.find((bankAccount) => bankAccount.id === id);
  },
  [GET_CREDIT_ACCOUNTS]: (state) => (listOfAssetsId: string): CreditAccount[] => {
    return state.list.filter((bankAccount) => bankAccount.listOfAssetsId === listOfAssetsId);
  }
};

const mutations: MutationTree<CreditAccountState> = {
  [SET_CREDIT_ACCOUNTS](state, creditAccounts: CreditAccount[]) {
    state.list = creditAccounts;
  },
  [ADD_CREDIT_ACCOUNT_TO_LIST](state, creditAccount: CreditAccount) {
    const index = state.list.findIndex((entry) => entry.id === creditAccount.id);

    if (index >= 0) {
      state.list.splice(index, 1);
    }

    state.list = [
      ...state.list,
      creditAccount
    ];
  },
  [ADD_CREDIT_ACCOUNT_TO_DETAILS](state, creditAccount: CreditAccountDetails) {
    const index = state.details.findIndex((entry) => entry.id === creditAccount.id);

    if (index >= 0) {
      state.details.splice(index, 1);
    }

    state.details = [
      ...state.details,
      creditAccount
    ];
  },
  [REMOVE_ACCOUNT]: (state, creditAccount: string) => {
    const listIndex = state.list.findIndex((entry) => entry.id === creditAccount);

    if (listIndex !== -1) {
      state.list.splice(listIndex, 1);
      state.list = [...state.list];
    }

    const detailsIndex = state.details.findIndex((entry) => entry.id === creditAccount);

    if (detailsIndex !== -1) {
      state.details.splice(detailsIndex, 1);
      state.details = [...state.details];
    }
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<CreditAccountState, RootState> => ({
  async [FETCH_CREDIT_ACCOUNTS]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/credit-accounts`, {
        responseType: 'json'
      });

      commit(SET_CREDIT_ACCOUNTS, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_CREDIT_ACCOUNT]({ commit }, creditAccountId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/credit-account/${creditAccountId}`, {
        responseType: 'json'
      });

      commit(ADD_CREDIT_ACCOUNT_TO_DETAILS, response.data);
      commit(ADD_CREDIT_ACCOUNT_TO_LIST, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [OPEN_CREDIT_ACCOUNT]({ commit, dispatch }, financeAccount: OpenCreditAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/credit-account/open-account`, financeAccount, { responseType: 'json' });

      dispatch(FETCH_CREDIT_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_CREDIT_ACCOUNT]({ commit, dispatch }, financeAccount: ChangeCreditAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/credit-account/change-account', financeAccount, { responseType: 'json' });

      dispatch(FETCH_CREDIT_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONVERT_CREDIT_ACCOUNT]({ commit, dispatch }, financeAccount: ConvertCreditAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/credit-account/convert-account', financeAccount, { responseType: 'json' });

      dispatch(FETCH_CREDIT_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async ADD_CREDIT_ACCOUNT({ commit }, { data }) {
    commit(ADD_CREDIT_ACCOUNT_TO_DETAILS, data.details);
    commit(ADD_CREDIT_ACCOUNT_TO_LIST, data.details);
  }
});

export default (Vue: Vue | VueConstructor): Module<CreditAccountState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
