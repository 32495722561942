import Vue, { VueConstructor } from 'vue';
import Router, { Location, Route } from 'vue-router';
import qs from 'qs';
import Application from './views/application/Application.vue';
import Login from './views/login/Login.vue';
import NotFound from './views/errorPages/404.vue';
import NotAllowed from './views/errorPages/403.vue';
import Dashboard from './modules/dashboard/views/Dashboard.vue';

import employee from './modules/employee/routes';
import terms from './modules/term/routes';
import timeAllocation from './modules/timeAllocation/routes';
import appointment from './modules/appointment/routes';
import car from './modules/car/routes';
import compensation from './modules/compensation/routes';
import profitabilities from './modules/profitabilities/routes';
import correspondence from './modules/correspondence/routes';
import contact from './modules/contact/routes';
import conservatorship from './modules/conservatorship/routes';
import conservatorshipToContact from './modules/conservatorshipToContact/routes';
import statistic from './modules/statistic/routes';
import listOfAssets from './modules/listOfAssets/routes';
import reportOfConservatorshipManagement from './modules/reportOfConservatorshipManagement/routes';
import fileManagement from './modules/fileManagement/routes';
import accounting from './modules/accounting/routes';

import scopeOfFunctionsAdmin from './modules/scopeOfFunctions/admin/routes';
import employeeAdmin from './modules/employee/admin/routes';
import carAdmin from './modules/car/admin/routes';
import organizationAdmin from './modules/organization/admin/routes';
import { AsyncComponentPromise } from 'vue/types/options';

Vue.use(Router);

export interface RouterChild {
  name: string;
  path: string;
  component?: VueConstructor | AsyncComponentPromise;
  children?: RouterChild[];
}

export const routeToLocation = ({ path, params, query, name, hash}: Route): Location => ({
  path,
  params,
  query,
  name: name || undefined,
  hash
});

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        auth: false,
        redirect: {
          name: 'app'
        }
      }
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound
    },
    {
      path: '/403',
      name: 'not-allowed',
      component: NotAllowed
    },
    {
      path: '/',
      component: Application,
      children: [
        { name: 'dashboard', path: '', component: Dashboard } as RouterChild,
        ...employee,
        ...terms,
        ...timeAllocation,
        ...appointment,
        ...car,
        ...compensation,
        ...profitabilities,
        ...correspondence,
        ...conservatorship,
        ...conservatorshipToContact,
        ...contact,
        ...statistic,
        ...listOfAssets,
        ...reportOfConservatorshipManagement,
        ...fileManagement,
        ...accounting
      ],
      meta: {
        auth: ['ACCOUNTING', 'VIEWER', 'ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'CONSERVATOR', 'SUPPORTER'],
        redirect: {
          name: 'login'
        }
      }
    },
    {
      path: '/administration',
      name: 'administration',
      component: Application,
      children: [
        ...scopeOfFunctionsAdmin,
        ...employeeAdmin,
        ...carAdmin,
        ...organizationAdmin
      ],
      meta: {
        auth: ['ADMINISTRATION', 'JUNIOR_ADMINISTRATION', 'INDEPENDENT_CONSERVATOR'],
        redirect: {
          name: 'login'
        }
      }
    },
    {
      path: '*',
      name: 'not-found-redirect',
      component: Application,
      redirect: '/404'
    }
  ],
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result ? ('?' + result) : '';
  }
});
