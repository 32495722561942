import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';
import {
  InternalAccount,
  InternalAccountDetails,
  InternalAccountState,
  ChangeInternalAccount,
  ConvertInternalAccount,
  OpenInternalAccount
} from '@/modules/listOfAssets/types';

export const GET_INTERNAL_ACCOUNT = 'GET_INTERNAL_ACCOUNT_DETAILS';
export const GET_INTERNAL_ACCOUNTS = 'GET_INTERNAL_ACCOUNT_LIST';

export const FETCH_INTERNAL_ACCOUNTS = 'FETCH_INTERNAL_ACCOUNTS';
export const FETCH_INTERNAL_ACCOUNT = 'FETCH_INTERNAL_ACCOUNT';

export const OPEN_INTERNAL_ACCOUNT = 'OPEN_FINANCE_INTERNAL_ACCOUNT';
export const CHANGE_INTERNAL_ACCOUNT = 'CHANGE_FINANCE_INTERNAL_ACCOUNT';
export const CONVERT_INTERNAL_ACCOUNT = 'CONVERT_FINANCE_INTERNAL_ACCOUNT';

export const ADD_INTERNAL_ACCOUNT_TO_DETAILS = 'ADD_INTERNAL_ACCOUNT_TO_DETAILS';
export const ADD_INTERNAL_ACCOUNT_TO_LIST = 'ADD_INTERNAL_ACCOUNT_TO_LIST';
export const SET_INTERNAL_ACCOUNTS = 'SET_INTERNAL_ACCOUNTS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

const initialState = (): InternalAccountState => ({
  list: [],
  details: []
});

const getters: GetterTree<InternalAccountState, RootState> = {
  [GET_INTERNAL_ACCOUNT]: (state) => (id: string): InternalAccountDetails | undefined => {
    return state.details.find((internalAccount) => internalAccount.id === id);
  },
  [GET_INTERNAL_ACCOUNTS]: (state) => (listOfAssets: string): InternalAccount[] => {
    return state.list.filter((internalAccount) => internalAccount.listOfAssetsId === listOfAssets);
  }
};

const mutations: MutationTree<InternalAccountState> = {
  [SET_INTERNAL_ACCOUNTS](state, internalAccounts: InternalAccount[]) {
    state.list = internalAccounts;
  },
  [ADD_INTERNAL_ACCOUNT_TO_LIST](state, internalAccount: InternalAccount) {
    const index = state.list.findIndex((entry) => entry.id === internalAccount.id);

    if (index >= 0) {
      state.list.splice(index, 1);
    }

    state.list = [
      ...state.list,
      internalAccount
    ];
  },
  [ADD_INTERNAL_ACCOUNT_TO_DETAILS](state, internalAccount: InternalAccountDetails) {
    const index = state.details.findIndex((entry) => entry.id === internalAccount.id);

    if (index >= 0) {
      state.details.splice(index, 1);
    }

    state.details = [
      ...state.details,
      internalAccount
    ];
  },
  [REMOVE_ACCOUNT]: (state, internalAccount: string) => {
    const listIndex = state.list.findIndex((entry) => entry.id === internalAccount);

    if (listIndex !== -1) {
      state.list.splice(listIndex, 1);
      state.list = [...state.list];
    }

    const detailsIndex = state.details.findIndex((entry) => entry.id === internalAccount);

    if (detailsIndex !== -1) {
      state.details.splice(detailsIndex, 1);
      state.details = [...state.details];
    }
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<InternalAccountState, RootState> => ({
  async [FETCH_INTERNAL_ACCOUNTS]({ commit }, listOfAssets: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/list-of-assets/${listOfAssets}/internal-accounts`, { responseType: 'json' });

      commit(SET_INTERNAL_ACCOUNTS, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_INTERNAL_ACCOUNT]({ commit }, internalAccountId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/financial-administration/internal-account/${internalAccountId}`, { responseType: 'json' });

      commit(ADD_INTERNAL_ACCOUNT_TO_DETAILS, response.data);
      commit(ADD_INTERNAL_ACCOUNT_TO_LIST, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [OPEN_INTERNAL_ACCOUNT]({ commit, dispatch }, financeAccount: OpenInternalAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/commands/financial-administration/internal-account/open-account`, financeAccount, { responseType: 'json' });

      dispatch(FETCH_INTERNAL_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_INTERNAL_ACCOUNT]({ commit, dispatch }, financeAccount: ChangeInternalAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/internal-account/change-account', financeAccount, { responseType: 'json' });

      dispatch(FETCH_INTERNAL_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONVERT_INTERNAL_ACCOUNT]({ commit, dispatch }, financeAccount: ConvertInternalAccount): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post('api/commands/financial-administration/internal-account/convert-account', financeAccount, { responseType: 'json' });

      dispatch(FETCH_INTERNAL_ACCOUNT, financeAccount.finance_account_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async ADD_INTERNAL_ACCOUNT({ commit }, { data }) {
    commit(ADD_INTERNAL_ACCOUNT_TO_DETAILS, data.details);
    commit(ADD_INTERNAL_ACCOUNT_TO_LIST, data.details);
  }
});

export default (Vue: Vue | VueConstructor): Module<InternalAccountState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
