import { GetterTree } from 'vuex';
import {
  ConservatorshipDetails,
  ConservatorshipState,
  HistoryEntry,
  WriteConservatorship
} from '@/modules/conservatorship/types';
import { RootState } from '@/store/types';

export const GET_PAGE = 'GET_CONSERVATORSHIP_LIST_PAGE';
export const GET_CREATED = 'GET_CONSERVATORSHIP_CREATED';
export const GET_HISTORY = 'GET_CONSERVATORSHIP_HISTORY';
export const GET_DETAILS = 'GET_CONSERVATORSHIP_DETAILS';

const getters: GetterTree<ConservatorshipState, RootState> = {
  [GET_PAGE]: (state: ConservatorshipState): number => state.list.page,
  [GET_CREATED]: (state: ConservatorshipState): WriteConservatorship | null => state.created,
  [GET_HISTORY]: (state: ConservatorshipState) => (id: string): HistoryEntry[] => state.history[id] || [],
  [GET_DETAILS]: (state: ConservatorshipState) => (id: string): ConservatorshipDetails | undefined => state.details[id],
};

export default getters;
