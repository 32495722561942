import { Appointment, AppointmentView, Icon, WriteAppointment } from '@/modules/appointment/types';
import { addMinutes, format, getMinutes, isToday, isTomorrow, setMinutes } from '@/filter/dateFormat';
import uuid from 'uuid/v4';

export const MapViewToWrite = ({ id, employee, car, conservatorships, ...appointment }: Appointment): WriteAppointment => ({
  appointment_id: id,
  employee_id: employee.id,
  car_id: car ? car.id : null,
  conservatorships: conservatorships.map((conservatorship) => conservatorship.conservatorship_id),
  ...appointment
});

export const icon = (appointment: Appointment): Icon => {
  if (isToday(appointment.start)) {
    return { name: 'error', color: 'error' };
  }

  if (isTomorrow(appointment.start)) {
    return { name: 'warning', color: 'warning' };
  }

  return { name: 'info', color: 'info' };
};

export const MapStateToView = (appointment: Appointment): AppointmentView => ({
  ...appointment,
  conservatees: appointment.conservatorships.map((conservatorship) => {
    return `${conservatorship.surname}, ${conservatorship.forename}`;
  }),
  startDate: format(appointment.start, 'dd.MM.yyyy'),
  beginDate: format(appointment.begin, 'dd.MM.yyyy'),
  endDate: format(appointment.end, 'dd.MM.yyyy'),
  startTime: format(appointment.start, 'HH:mm'),
  beginTime: format(appointment.begin, 'HH:mm'),
  endTime: format(appointment.end, 'HH:mm'),
  icon: icon(appointment)
});

export const createWriteAppointment = (employee_id: string = '', start?: string, begin?: string, end?: string): WriteAppointment => {
  const from = format(setMinutes(Date.now(), (Math.floor(getMinutes(Date.now()) / 15) + 1) * 15), 'yyyy-MM-dd HH:mm:ss');
  const to = format(addMinutes(setMinutes(Date.now(), (Math.floor(getMinutes(Date.now()) / 15) + 1) * 15), 30), 'yyyy-MM-dd HH:mm:ss');

  return {
    appointment_id: uuid(),
    conservatorships: [],
    employee_id,
    start: start || from,
    begin: begin || from,
    end: end || to,
    title: '',
    note: '',
    car_id: null
  };
};
