export interface Placeholder {
  placeholder: string;
  description: string;
}

export const getPlaceholders = (list: Placeholder[], conservatorRequired: boolean, bankAccountRequired: boolean): Placeholder[] => {
  if (conservatorRequired) {
    list = [
      ...list,
      { placeholder: '*Betreuter.Anrede', description: 'Anrede des Betreuten' },
      { placeholder: '*Betreuter.Vorname', description: 'Vorname des Betreuten' },
      { placeholder: '*Betreuter.Nachname', description: 'Nachname des Betreuten' },
      { placeholder: '*Betreuter.Name', description: 'Nachname, Vorname des Betreuten' },
      { placeholder: '*Betreuter.Unterbringung', description: 'Unterbringung des Betreuten' },
      { placeholder: '*Betreuter.Vermögen', description: 'Vermögensstatus des Betreuten (vermögend / mittellos)' },
      { placeholder: '*Betreuter.AdressName', description: 'Angegebener Name der Adresse des Betreuten' },
      { placeholder: '*Betreuter.Strasse', description: 'Angegebene Straße der Adresse des Betreuten' },
      { placeholder: '*Betreuter.PLZ', description: 'Angegebene PLZ der Adresse des Betreuten' },
      { placeholder: '*Betreuter.Stadt', description: 'Angegebene Stadt der Adresse des Betreuten' },
      { placeholder: '*Betreuter.Krankenkasse', description: 'Name der Krankekasse des Betreuten' },
      { placeholder: '*Betreuter.Pflegegrad', description: 'Pflegegrad des Betreuten' },
      { placeholder: '*Betreuter.Behinderungsgrad', description: 'Behinderungsgrad des Betreuten' },
      { placeholder: '*Betreuung.Aufgabenkreise', description: 'Aufgabenkreise des Betreuten' },
      { placeholder: '*Betreuung.Geschaeftsnummer', description: 'Geschäftsnummer des Betreuten' },
      { placeholder: '*Betreuung.Gericht', description: 'Gericht des Betreuten' },
      { placeholder: '*Betreuung.Beginn', description: 'Begin der Betreuung' },
      { placeholder: '*Betreuung.Uebernahme', description: 'Übernahme der Betreuung' },
      { placeholder: '*Betreuung.Beendet', description: 'Beendigung der Betreuung' },
      {
        placeholder: '*Krankenkasse.Versicherungsnummer',
        description: 'Versicherungsnummer der Krankenkasse des Betreuten'
      },
      { placeholder: '*Betreuer', description: 'Name des Betreuers des Betreuten' },
      { placeholder: '*Betreuer.Email', description: 'Email des Betreuers des Betreuten' },
      { placeholder: '*Betreuer.Anrede', description: 'Anrede des Betreuers des Betreuten' },
      { placeholder: '*Arbeitsamt', description: 'Name des, für Betreuten, zuständigen Arbeitsamtes' },
      {
        placeholder: '*Arbeitsamt.Kundennummer',
        description: 'Kundennummer des, für Betreuten, zuständigen Arbeitsamtes'
      },
      { placeholder: '*Rentenversicherung', description: 'Name der, für Betreuten, zuständigen Rentenversicherung' },
      {
        placeholder: '*Rentenversicherungsnummer',
        description: 'Rentenversicherungsnummer der, für Betreuten, zuständigen Rentenversicherung'
      },
      { placeholder: '*Landratsamt', description: 'Name des, für Betreuten, zuständigen Landratsamt' },
      {
        placeholder: '*Landratsamt.Aktenzeichen',
        description: 'Aktenzeichen des, für Betreuten, zuständigen Landratsamt'
      },
      { placeholder: '*Jobcenter', description: 'Name des, für Betreuten, zuständigen Jobcenters' },
      {
        placeholder: '*Jobcenter.Kundennummer',
        description: 'Kundennummer des, für Betreuten, zuständigen Jobcenters'
      },
      { placeholder: '*Stadtverwaltung', description: 'Name der, für Betreuten, zuständigen Stadtverwaltung' },
      {
        placeholder: '*Stadtverwaltung.Wohngeldnummer',
        description: 'Wohngeldnummer der, für Betreuten, zuständigen Stadtverwaltung'
      },
      { placeholder: '*Sozialamt', description: 'Name des, für Betreuten, zuständigen Sozialamtes' },
      {
        placeholder: '*Sozialamt.Aktenzeichen',
        description: 'Aktenzeichen der, für Betreuten, zuständigen Stadtverwaltung'
      },
      { placeholder: '*Finanzamt', description: 'Name des, für Betreuten, zuständigen Finanzamtes' },
      {
        placeholder: '*Finanzamt.SteuerID',
        description: 'SteuerID Nummer des, für Betreuten, zuständigen Finanzamtes'
      },
      { placeholder: '*Behindertenrecht', description: 'Name des, für Betreuten, zuständigen Behindertenrechtes' },
      {
        placeholder: '*Behindertenrecht.Aktenzeichen',
        description: 'Aktenzeichen des, für Betreuten, zuständigen Behindertenrechtes'
      },
      { placeholder: '*Wohngeldstelle', description: 'Name der, für Betreuten, zuständigen Wohngeldstelle' },
      {
        placeholder: '*Wohngeldstelle.Wohngeldnummer',
        description: 'Wohngeldnummer der, für Betreuten, zuständigen Wohngeldstelle'
      }
    ];
  }

  if (bankAccountRequired) {
    list = [
      ...list,
      { placeholder: '*IBAN', description: 'IBAN des Ausgewählten Bankkontos' },
      { placeholder: '*BIC', description: 'BIC des Ausgewählten Bankkontos' }
    ];
  }

  return list;
};

export const emailPlaceholder = (requireConservatorship: boolean, requireBank: boolean) => {
  const list = [
    { placeholder: '*Empfaenger', description: 'Anschrift des Empfängers. Z.B.: Amtsgericht Pirna' },
    {
      placeholder: '*Sender',
      description: 'Im Kontext einer Betreuung ist der Sender dessen Betreuer. Andernfalls der angemeldete Nutzer'
    },
    { placeholder: '*Ersteller', description: 'Name des angemeldeten Nutzers' },
    { placeholder: '*Ansprechpartner', description: 'Name des ausgewählten Ansprechpartners (Wenn vorhanden)' }
  ];

  return getPlaceholders(list, requireConservatorship, requireBank);
};

export const letterPlaceholder = (requireConservatorship: boolean, requireBank: boolean) => {
  const list = [
    { placeholder: '*Empfaenger', description: 'Anschrift des Empfängers. Z.B.: Amtsgericht Pirna' },
    ...(requireConservatorship ? [{ placeholder: '*Empfaenger.Kundennummer', description: 'Kundennummer des Betreuten beim gewählten Empfänger' }] : []),
    {
      placeholder: '*Sender',
      description: 'Im Kontext einer Betreuung ist der Sender dessen Betreuer. Andernfalls der angemeldete Nutzer'
    },
    { placeholder: '*Sender.AdressName', description: 'Adresse des Senders' },
    { placeholder: '*Sender.Straße', description: 'Straße des Senders' },
    { placeholder: '*Sender.PLZ', description: 'PLZ des Senders' },
    { placeholder: '*Sender.Stadt', description: 'Stadt des Senders' },
    { placeholder: '*Sender.Telefon', description: 'Telefon des Senders' },
    { placeholder: '*Sender.Fax', description: 'Fax des Senders' },
    { placeholder: '*Ersteller', description: 'Name des angemeldeten Nutzers' },
    { placeholder: '*Ansprechpartner', description: 'Name des ausgewählten Ansprechpartners (Wenn vorhanden)' }
  ];

  return getPlaceholders(list, requireConservatorship, requireBank);
};
