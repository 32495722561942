











import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';

@Component({})
export default class NavigationItem extends AuthorizationMixin {
  @Prop({ type: Object, required: true }) link!: any;
}
