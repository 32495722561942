
















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_IMPORTS } from '../store/financeAccount';
import { ImportItem } from '../types';

const FinanceAccount = namespace('listOfAssets/financeAccount');

@Component
export default class ImportSuccessDialog extends Vue {
  @FinanceAccount.Getter(GET_IMPORTS) list!: ImportItem[];

  get completed() {
    return this.list
      .filter((item) => ['completed', 'canceled'].includes(item.state))
      .map((item) => ({
        ...item,
        type: item.state === 'completed' ? 'success' : 'error'
      }));
  }
}
