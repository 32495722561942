
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Pagination } from '@/components/types';

@Component
export default class Paginatable extends Vue {
  pagination: Pagination = {};

  get totalVisible() {
    return this.$vuetify.breakpoint.mdAndUp ? 10 : 3;
  }

  get pages() {
    return this.pagination.pageCount;
  }

  get page() {
    return parseInt(this.$route.query.page as string || '1', 10);
  }

  set page(page) {
    if (!page) {
      page = 1;
    }

    // @ts-ignore
    this.setPage(parseInt(page as string, 10));
    this.$router.push({ ...(this.$route as any), query: { ...this.$route.query, page } }).catch(() => {});
  }

  paginationChange(event: Pagination) {
    this.pagination.sortBy = event.sortBy || [];
    this.pagination.descending = event.descending || false;
  }

  @Watch('search')
  onSearching(search: string, old: string) {
    if (!search && !old) return;

    this.page = 1;
  }

  @Watch('page')
  onPageChange(value: number) {
    this.pagination.page = value;
  }

  created() {
    const page = this.$route.query.page;

    if (!page) {
      // @ts-ignore
      this.page = this.list.page;
    }

    this.pagination.page = this.page;
  }
}
