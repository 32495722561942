































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_EMPLOYEE_LIST, GET_EMPLOYEE_LIST } from '../store';
import { ApiResponse } from '@/components/types';
import { Employee } from '@/modules/employee/types';

const Employee = namespace('employee');

@Component({
  inheritAttrs: false
})
export default class EmployeeSelect extends Vue {
  @Prop() value?: string | undefined;
  @Prop({ default: 'select.employee' }) fieldName!: string;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: false }) visible!: boolean;

  @Employee.Getter(GET_EMPLOYEE_LIST) list!: Employee[];
  @Employee.Action(FETCH_EMPLOYEE_LIST) fetch!: () => Promise<ApiResponse<Employee[]>>;

  loading: boolean = false;

  get items () {
    return this.list.filter((employee) => employee.visible)
  }

  async created() {
    this.loading = true;
    await this.fetch();
    this.loading = false;
  }
}
