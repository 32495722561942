











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DatePicker from '@/components/form/DatePicker.vue';
import TimeText from '@/components/form/TimeText.vue';

@Component({
  components: {
    DatePicker,
    TimeText
  }
})
export default class DateTime extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;

  date: string = '';
  time: string = '';

  @Watch('date')
  onDateChange() {
    this.$emit('input', `${this.date} ${this.time}:00`);
  }

  @Watch('time')
  onTimeChange() {
    this.$emit('input', `${this.date} ${this.time}:00`);
  }

  @Watch('value', { immediate: true })
  watchValue(value: string) {
    if (!value) return;

    try {
      const [date, time] = value.split(' ');

      if (!date || !time) return;

      this.date = date.trim();
      this.time = time.trim().slice(0, 5);
    } catch (e) {
    }
  }
}
