


































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_ZIP_ERROR_LIST, GET_ZIP_LIST } from '../store';
import CompensationZipDownloadAction from '@/modules/compensation/components/CompensationZipDownloadAction.vue';

const Compensation = namespace('compensation');
@Component({
  components: { CompensationZipDownloadAction }
})
export default class CompensationZipSuccessAlerts extends Vue {
  @Compensation.Getter(GET_ZIP_LIST) completed!: number[];
  @Compensation.Getter(GET_ZIP_ERROR_LIST) errored!: number[];
  @Compensation.Mutation('REMOVE_ZIP_YEAR') removeYear!: (year: number) => void;
  @Compensation.Mutation('REMOVE_ZIP_ERROR_YEAR') dismissYear!: (year: number) => void;

  downloaded: number[] = [];
  dismissed: number[] = [];

  onClicked(year: number) {
    setTimeout(() => {
      this.downloaded.push(year);
    }, 1000);

    setTimeout(() => {
      this.removeYear(year);
    }, 1500);
  }

  onClose(year: number) {
    this.downloaded.push(year);

    setTimeout(() => {
      this.removeYear(year);
    }, 1000);
  }

  onDismissed(year: number) {
    this.dismissed.push(year);

    setTimeout(() => {
      this.dismissYear(year);
    }, 1000);
  }
}
