import Vue, { VueConstructor } from 'vue';
import Vuex, { GetterTree, ModuleTree, MutationTree } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';
import { sharedMutations } from '@/store/config';

import employee from '@/modules/employee/store';
import term from '@/modules/term/store';
import court from '@/modules/court/store';
import bank from '@/modules/bank/store';
import conservatorship from '@/modules/conservatorship/store';
import timeAllocation from '@/modules/timeAllocation/store';
import scopeOfFunctions from '@/modules/scopeOfFunctions/store';
import appointment from '@/modules/appointment/store';
import car from '@/modules/car/store';
import compensation from '@/modules/compensation/store';
import profitabilities from '@/modules/profitabilities/store';
import correspondence from '@/modules/correspondence/store';
import contact from '@/modules/contact/store';
import conservatorshipToContact from '@/modules/conservatorshipToContact/store';
import conservatorshipToScopeOfFunctions from '@/modules/conservatorshipToScopeOfFunctions/store';
import reportOfConservatorshipManagement from '@/modules/reportOfConservatorshipManagement/store';
import listOfAssets from '@/modules/listOfAssets/store';
import fileManagement from '@/modules/fileManagement/store';
import organization from '@/modules/organization/store';
import conservator from '@/modules/conservator/store';
import accounting from '@/modules/accounting/store';
import notes from '@/modules/notes/store';

import {
  AppState,
  GET_ALERT_ERROR,
  GET_ALERT_SUCCESS,
  RootState,
  SET_ALERT_ERROR,
  SET_ALERT_SUCCESS
} from '@/store/types';

Vue.use(Vuex);

const initialState = (): AppState => ({
  successAlert: false,
  errorAlert: false,
  socket: false
});

const modules: ModuleTree<AppState> = {
  conservatorship: conservatorship(Vue),
  employee: employee(Vue),
  term: term(Vue),
  court: court(Vue),
  bank: bank(Vue),
  timeAllocation: timeAllocation(Vue),
  scopeOfFunctions: scopeOfFunctions(Vue),
  appointment: appointment(Vue),
  compensation: compensation(Vue),
  profitability: profitabilities(Vue),
  correspondence: correspondence(Vue),
  contact: contact(Vue),
  car: car(Vue),
  conservatorshipToContact: conservatorshipToContact(Vue),
  conservatorshipToScopeOfFunctions: conservatorshipToScopeOfFunctions(Vue),
  reportOfConservatorshipManagement: reportOfConservatorshipManagement(Vue),
  listOfAssets: listOfAssets(Vue),
  fileManagement: fileManagement(Vue as VueConstructor),
  organization: organization(Vue as VueConstructor),
  conservator: conservator(Vue as VueConstructor),
  accounting: accounting(Vue as VueConstructor),
  notes: notes(Vue as VueConstructor)
};

const getters: GetterTree<AppState, RootState> = {
  [GET_ALERT_SUCCESS]: (state) => state.successAlert,
  [GET_ALERT_ERROR]: (state) => state.errorAlert
};

const mutations: MutationTree<AppState> = {
  [SET_ALERT_SUCCESS](state, open) {
    state.successAlert = open;
  },
  [SET_ALERT_ERROR](state, open) {
    state.errorAlert = open;
  },
  SOCKET_ONOPEN(state, event) {
    state.socket = event.returnValue || event.type === 'open';
  },
  SOCKET_RECONNECT(state) {
    state.socket = false;
  },
  SOCKET_ONERROR(state) {
    state.socket = false;
  },
  SOCKET_ONMESSAGE(state, payload) {},
  SOCKET_ONCLOSE(state) {
    state.socket = false;
  },
  RESET(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

export default new Vuex.Store({
  modules,
  state: initialState(),
  getters,
  mutations,
  actions: {
    LOGOUT: ({ commit }) => {
      commit('RESET');

      for (const module in modules) {
        commit(`${module}/RESET`);

        if (modules[module].modules) {
          for (const childModule in modules[module].modules) {
            commit(`${module}/${childModule}/RESET`);
          }
        }
      }
    }
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
        'bank',
        'financeAccount',
        'car',
        'conservator',
        'contact',
        'court',
        'employee',
        'listOfAssets',
        'imported',
        'scopeOfFunctions',
        'timeAllocation',
        'healthInsurance',
        'app',
        'conservatorshipToContact',
        'conservatorshipToScopeOfFunctions',
        'reportOfConservatorshipManagement'
      ]
    }),
    createMutationsSharer({
      predicate: (mutation: { type: string }) => {
        return sharedMutations.includes(mutation.type);
      }
    })
  ]
});
