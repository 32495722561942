/* eslint-disable camelcase */
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import {
  AvailableDates,
  ChangePayableCompensation,
  Compensation,
  CompensationState,
  FormSheet,
  MailCompensation,
  PayableCompensation,
  PayCompensation,
  PossibleFixedRatesParams,
  PutNewCompensation,
  RecalculateCompensation,
  RemovePayableCompensation,
  RevertCompensation,
  SearchParams,
  UpdateCompensation
} from '@/modules/compensation/types';
import Vue, { VueConstructor } from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApiResponse } from '@/components/types';

export const FETCH_FORM_SHEET_FINANCES = 'FETCH_FORM_SHEET_FINANCES';
export const FETCH_FORM_SHEET_CONSERVATEE = 'FETCH_FORM_SHEET_CONSERVATEE';
export const FETCH_FORM_SHEET_LAST = 'FETCH_FORM_SHEET_LAST';
export const FETCH_FORM_SHEET_PDF = 'FETCH_COMPENSATION_FORM_SHEET_PDF';
export const FETCH_FORM_SHEET = 'FETCH_COMPENSATION_FORM_SHEET';
export const FETCH_PAYABLE_LIST = 'FETCH_PAYABLE_COMPENSATION_LIST';
export const FETCH_REQUIRE_FORM_SHEET = 'FETCH_REQUIRE_FORM_SHEET';
export const FETCH_LIST = 'FETCH_COMPENSATION_LIST';
export const FETCH_SEARCH = 'FETCH_COMPENSATION_SEARCH';
export const FETCH_DETAILS = 'FETCH_COMPENSATION_DETAILS';
export const FETCH_PAYABLE_DETAILS = 'FETCH_PAYABLE_COMPENSATION_DETAILS';
export const FETCH_PDF = 'FETCH_COMPENSATION_PDF';
export const FETCH_NEXT_COMPENSATION_COUNTER = 'FETCH_COMPENSATION_NEXT_COMPENSATION_COUNTER';
export const FETCH_COMPENSATION_INCOME = 'FETCH_COMPENSATION_INCOME';
export const PAY = 'PAY_COMPENSATION';
export const MAIL = 'MAIL_COMPENSATION';
export const PUT_NEW = 'PUT_NEW_COMPENSATION';
export const POSSIBLE_FIXED_RATES = 'POSSIBLE_COMPENSATION_FIXED_RATES';
export const RECALCULATE = 'RECALCULATE_COMPENSATION';
export const INFLATION = 'INFLATION_COMPENSATION';
export const ADD_FORM_SHEET = 'ADD_FORM_SHEET_FOR_COMPENSATION';
export const UPDATE_FORM_SHEET = 'UPDATE_FORM_SHEET_FOR_COMPENSATION';
export const CONSERVATOR_ADD_FORM_SHEET = 'CONSERVATOR_ADD_FORM_SHEET_FOR_COMPENSATION';
export const CONSERVATOR_UPDATE_FORM_SHEET = 'CONSERVATOR_UPDATE_FORM_SHEET_FOR_COMPENSATION';
export const CHANGE_PAYABLE_COMPENSATION = 'CHANGE_PAYABLE_COMPENSATION';
export const REMOVE_PAYABLE_COMPENSATION = 'REMOVE_PAYABLE_COMPENSATION';
export const UPDATE_COMPENSATION = 'UPDATE_COMPENSATION';
export const REVERT_PAYMENT = 'REVERT_COMPENSATION_PAYMENT';
export const REVERT_MAILING = 'REVERT_COMPENSATION_MAILING';
export const GET_PAGE = 'GET_COMPENSATION_LIST_PAGE';

export const SET_LIST_PAGE = 'SET_COMPENSATION_LIST_PAGE';
export const FETCH_AVAILABLE = 'FETCH_COMPENSATION_AVAILABLE_LIST';
export const GET_AVAILABLE = 'GET_COMPENSATION_AVAILABLE_LIST';
export const GET_BY_ID = 'GET_COMPENSATION_BY_ID';
export const GET_ZIP_LIST = 'GET_ZIP_LIST';
export const GET_ZIP_ERROR_LIST = 'GET_ZIP_ERROR_LIST';

const initialState = (): CompensationState => ({
  list: {
    items: [],
    page: 1
  },
  payable: [],
  available: {},
  zip: {
    success: [],
    errors: []
  }
});

const getters: GetterTree<CompensationState, RootState> = {
  [GET_ZIP_LIST]: (state: CompensationState) => state.zip.success,
  [GET_ZIP_ERROR_LIST]: (state: CompensationState) => state.zip.errors,
  [GET_PAGE]: (state: CompensationState) => state.list.page,
  [GET_BY_ID]: (state: CompensationState) => (id: string): Compensation | undefined => state.list.items.find((compensation) => compensation.id === id),
  [GET_AVAILABLE]: (state: CompensationState) => (conservatorship: string): AvailableDates => state.available[conservatorship] || {
    start: [],
    end: []
  }
};

const mutations: MutationTree<CompensationState> = {
  SET_LIST_ITEMS(state: CompensationState, items: Compensation[]) {
    state.list.items = items;
  },
  SET_PAYABLE_LIST_ITEMS(state: CompensationState, items: PayableCompensation[]) {
    state.payable = items;
  },
  SET_ITEM(state: CompensationState, item: Compensation) {
    const index = state.list.items.findIndex((compensation: Compensation) => compensation.id === item.id);

    if (index !== -1) {
      state.list.items.splice(index, 1);
    }

    state.list.items = [
      ...state.list.items,
      item
    ];
  },
  REMOVE(state: CompensationState, compensationId: string) {
    state.list.items = state.list.items.filter((compensation) => compensation.id !== compensationId);
  },
  REMOVE_PAYABLE(state: CompensationState, conservatorshipId: string) {
    state.payable = state.payable.filter((payableCompensation) => payableCompensation.conservatorshipId !== conservatorshipId);
  },
  SET_PAYABLE_ITEM(state: CompensationState, item: PayableCompensation) {
    const index = state.payable.findIndex((compensation) => compensation.id === item.id);

    if (index !== -1) {
      state.payable.splice(index, 1);
    }
    state.payable = [
      ...state.payable,
      item
    ];
  },
  [SET_LIST_PAGE](state: CompensationState, page: number) {
    state.list.page = page;
  },
  SET_AVAILABLE_LIST(state: CompensationState, { conservatorship, items }) {
    state.available = {
      ...state.available,
      [conservatorship]: items
    };
  },
  ADD_ZIP_YEAR(state: CompensationState, year: number) {
    state.zip.success = [...(new Set([...(state.zip.success || []), year]))];
  },
  REMOVE_ZIP_YEAR(state: CompensationState, year: number) {
    state.zip.success = state.zip.success.filter((item) => item !== year);
  },
  ADD_ZIP_ERROR_YEAR(state: CompensationState, year: number) {
    state.zip.errors = [...new Set([...(state.zip.errors || []), year])];
  },
  REMOVE_ZIP_ERROR_YEAR(state: CompensationState, year: number) {
    state.zip.errors = state.zip.errors.filter((item) => item !== year);
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<CompensationState, RootState> => ({
  async [FETCH_LIST]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/compensation/list', { responseType: 'json' });
      commit('SET_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_REQUIRE_FORM_SHEET]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/compensation/form-sheets', { responseType: 'json' });
      commit('SET_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_SEARCH]({ commit }, search: SearchParams) {
    try {
      const response = await Vue.axios.get('api/compensation/search', { params: search, responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_PAYABLE_LIST]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/compensation/next-list', { responseType: 'json' });
      commit('SET_PAYABLE_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [POSSIBLE_FIXED_RATES]({ commit }, { conservatorshipId, begin, end }: PossibleFixedRatesParams): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/compensation/${conservatorshipId}/possible-fixed-rates`, {
        responseType: 'json',
        params: { begin, end }
      });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_PDF]({ commit }, id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/compensation/${id}/pdf`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_DETAILS]({ commit }, id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/compensation/${id}/details`, { responseType: 'json' });
      commit('SET_ITEM', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_PAYABLE_DETAILS]({ commit }, conservatorship: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/compensation/${conservatorship}/next-details`, { responseType: 'json' });
      commit('SET_PAYABLE_ITEM', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FORM_SHEET_CONSERVATEE]({ commit }, { conservatorshipId, compensationId }: { conservatorshipId: string, compensationId: string }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${conservatorshipId}/${compensationId}/form-sheet/conservatee`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FORM_SHEET_FINANCES]({ commit }, { conservatorshipId, compensationId, date }: { conservatorshipId: string, compensationId: string, date: string }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${conservatorshipId}/${compensationId}/form-sheet/finance-account-balances`, {
        responseType: 'json',
        params: { date }
      });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FORM_SHEET_LAST]({ commit }, { conservatorshipId }: { conservatorshipId: string, compensationId: string, date: string }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${conservatorshipId}/form-sheet/last`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FORM_SHEET]({ commit }, compensation: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${compensation}/form-sheet`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_FORM_SHEET_PDF]({ commit }, compensation: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${compensation}/form-sheet-pdf`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [PUT_NEW]({ commit, dispatch }, putNewCompensation: PutNewCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/put-new`, putNewCompensation, { responseType: 'json' });

      dispatch(FETCH_DETAILS, putNewCompensation.compensation_id);
      dispatch(FETCH_PAYABLE_LIST);

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_FORM_SHEET]({ commit, dispatch }, formSheet: FormSheet) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/add-form-sheet`, formSheet, { responseType: 'json' });

      dispatch(FETCH_DETAILS, formSheet.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_FORM_SHEET]({ commit, dispatch }, formSheet: FormSheet) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/update-form-sheet`, formSheet, { responseType: 'json' });

      dispatch(FETCH_DETAILS, formSheet.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONSERVATOR_ADD_FORM_SHEET]({ commit, dispatch }, formSheet: FormSheet) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/add-form-sheet`, formSheet, { responseType: 'json' });

      dispatch(FETCH_REQUIRE_FORM_SHEET);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CONSERVATOR_UPDATE_FORM_SHEET]({ commit, dispatch }, formSheet: FormSheet) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/update-form-sheet`, formSheet, { responseType: 'json' });

      dispatch(FETCH_REQUIRE_FORM_SHEET);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [PAY]({ commit }, payCompensation: PayCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/pay`, payCompensation, { responseType: 'json' });

      commit('REMOVE', payCompensation.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [MAIL]({ commit, dispatch }, mailCompensation: MailCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/mail`, mailCompensation, { responseType: 'json' });

      dispatch(FETCH_DETAILS, mailCompensation.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REVERT_PAYMENT]({ commit, dispatch }, { compensation_id }: RevertCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/revert-payment`, { compensation_id }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REVERT_MAILING]({ commit, dispatch }, { compensation_id }: RevertCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/revert-mailing`, { compensation_id }, { responseType: 'json' });

      dispatch(FETCH_DETAILS, compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [RECALCULATE]({ commit, dispatch }, recalculateCompensation: RecalculateCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/recalculate`, recalculateCompensation, { responseType: 'json' });

      dispatch(FETCH_DETAILS, recalculateCompensation.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [INFLATION]({ commit, dispatch }, compensation: RecalculateCompensation) {
    try {
      await Vue.axios.post(`api/commands/conservatorship-management/compensation/adjust-inflation`, compensation, { responseType: 'json' });

      dispatch(FETCH_DETAILS, compensation.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_COMPENSATION]({ commit, dispatch }, updateCompensation: UpdateCompensation) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/compensation/update', updateCompensation, { responseType: 'json' });

      dispatch(FETCH_DETAILS, updateCompensation.compensation_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [CHANGE_PAYABLE_COMPENSATION]({ commit, dispatch }, changePayableCompensation: ChangePayableCompensation) {
    try {
      await Vue.axios.post('api/commands/change-next-compensation', changePayableCompensation, { responseType: 'json' });

      dispatch(FETCH_PAYABLE_DETAILS, changePayableCompensation.conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [REMOVE_PAYABLE_COMPENSATION]({ commit, dispatch }, { conservatorship_id }: RemovePayableCompensation) {
    try {
      await Vue.axios.post('api/commands/remove-next-compensation', { conservatorship_id }, { responseType: 'json' });

      commit('REMOVE_PAYABLE', conservatorship_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_AVAILABLE]({ commit }, { conservatorship }) {
    try {
      const response = await Vue.axios.get(`api/time-allocation/${conservatorship}/available-for-conservatorship`, {
        responseType: 'json'
      });

      commit('SET_AVAILABLE_LIST', { conservatorship, items: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_NEXT_COMPENSATION_COUNTER]() {
    try {
      const response = await Vue.axios.get('api/next-compensation/open-counter', {
        responseType: 'json'
      });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_COMPENSATION_INCOME](_ , year: number) {
    try {
      const response = await Vue.axios.get('api/compensation/income', {
        params: { year },
        responseType: 'json'
      });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  SET_DETAILS({ commit }, { data }) {
    commit('SET_ITEM', data.compensation);
  },
  REMOVE({ commit }, { data }) {
    commit('REMOVE', data.compensation);
  },
  SET_PAYABLE_DETAILS({ commit }, { data }) {
    commit('SET_PAYABLE_ITEM', data.compensation);
  },
  SET_PAYABLE_LIST({ commit }, { data }) {
    commit('SET_PAYABLE_LIST_ITEMS', data.compensations);
  },
  SET_COMPENSATION_ZIP_SUCCESS({ commit }, { data }) {
    commit('ADD_ZIP_YEAR', data.year);
  },
  SET_COMPENSATION_ZIP_ERROR({ commit }, { data }) {
    commit('ADD_ZIP_ERROR_YEAR', data.year);
  }
});

export default (instance: Vue | VueConstructor): Module<CompensationState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
