






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import { FINISH_TERM } from '../store';
import { TermView, WriteTerm } from '../types';
import { ApiResponse } from '@/components/types';
import { MapViewToWrite } from '@/modules/term/model';

const Term = namespace('term');

@Component({
  components: {
    ConservatorSelect,
    DatePicker
  }
})
export default class FinishTermDialog extends Vue {
  @Prop() term?: TermView;

  @Term.Action(FINISH_TERM) finishTerm!: (term: WriteTerm) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  value: WriteTerm | null = null;
  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) {
      return;
    }

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:term', null);
    }, 200);
  }

  @Watch('term')
  watchTermSet(term: TermView) {
    if (!term) return;

    this.value = MapViewToWrite(term);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (this.value === null) return;
    this.error = null;
    this.loading = true;

    const { error } = await this.finishTerm(this.value);

    this.loading = false;

    if (error) {
      this.error = error;
    }

    this.dialog = false;
  }
}
