import Vue from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';
import { Framework } from 'vuetify';

Vue.use(Vuetify);
Vue.mixin({
  computed: {
    darken() {
      return (this.$vuetify as Framework).theme.dark ? 'darken-4' : '';
    },
    lighten() {
      return (this.$vuetify as Framework).theme.dark ? '' : 'lighten-3';
    },
    lightenText() {
      return (this.$vuetify as Framework).theme.dark ? 'text--lighten-3' : '';
    }
  }
});

export default new Vuetify({
  theme: {
    dark: window.localStorage.getItem('darkMode') === 'true',
    themes: {
      dark: {
        info: '#BBDEFB',
        primary: '#BBDEFB'
      }
    }
  },
  icons: {
    iconfont: 'md', // default - only for display purposes
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
