

import { Component, Vue } from 'vue-property-decorator';
import debounce from 'lodash.debounce';
import { routeToLocation } from '@/router';

@Component
export default class Searchable extends Vue {
  private debounceRouterPush = debounce(this.routerPush, 400);

  get search(): string {
    return this.$route.query['search'] as string;
  }

  set search(search: string) {
    if (!search) {
      this.debounceRouterPush({ search: undefined });
      return;
    }

    this.debounceRouterPush({ search });
  }

  routerPush(query: any) {
    try {
      this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, ...query } })).catch(() => {});
    } catch {}
  }
}
