import { ValidationRule } from 'vee-validate/dist/types/types';

const isEmpty: ValidationRule = {
  message: 'Es muss mindestens ein Wert ausgewählt werden',
  computesRequired: true,
  validate: (value?: string | string[]): boolean => {
    return !value || (Array.isArray(value) && value.length > 0)
  }
};


export default isEmpty;
