import { RouterChild } from '@/router';

export default [
  {
    name: 'compensation-search',
    path: 'compensations/search',
    component: () => import('./views/Search.vue')
  } as RouterChild,
  {
    name: 'compensation-payable',
    path: 'compensations/payable',
    component: () => import('./views/PayableCompensations.vue')
  } as RouterChild,
  {
    name: 'compensation-list',
    path: 'compensations/list',
    component: () => import('./views/Compensations.vue')
  } as RouterChild,
  {
    name: 'compensation-form-sheets',
    path: 'compensations/form-sheets',
    component: () => import('./views/FormSheets.vue')
  } as RouterChild
];
