import {
  CorrespondenceState,
  EmailTemplate,
  Letter,
  LetterTemplate,
  MailLetterParams,
  Outbox,
  RenderConservatorshipLetterParams,
  RenderLetterParams,
  WriteEmailTemplate,
  WriteLetter,
  WriteLetterTemplate,
  WriteOutbox
} from '@/modules/correspondence/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';
import { createLetter } from '@/modules/correspondence/model';
import { AxiosResponse } from 'axios';

export const FETCH_LETTER_TEMPLATE_LIST = 'FETCH_LETTER_TEMPLATE_LIST';
export const FETCH_LETTER_TEMPLATE_DETAILS = 'FETCH_LETTER_TEMPLATE_DETAILS';
export const ADD_LETTER_TEMPLATE = 'ADD_LETTER_TEMPLATE';
export const UPDATE_LETTER_TEMPLATE = 'UPDATE_LETTER_TEMPLATE';
export const DELETE_LETTER_TEMPLATE = 'DELETE_LETTER_TEMPLATE';
export const SET_LETTER_TEMPLATE_LIST_PAGE = 'SET_LETTER_TEMPLATE_LIST_PAGE';
export const GET_LETTER_TEMPLATE_LIST = 'GET_LETTER_TEMPLATE_LIST';
export const GET_LETTER_TEMPLATE_PAGE = 'GET_LETTER_TEMPLATE_LIST_PAGE';

export const GET_LETTER_ACTIVE = 'GET_ACTIVE_LETTER';
export const SET_LETTER_ACTIVE = 'SET_ACTIVE_LETTER';
export const UPDATE_LETTER_ACTIVE = 'UPDATE_LETTER_ACTIVE';
export const INIT_LETTER_ACTIVE = 'INIT_ACTIVE_LETTER';
export const FETCH_RENDERED_LETTER = 'FETCH_RENDERED_LETTER_TEMPLATE';
export const FETCH_CONSERVATORSHIP_RENDERED_LETTER = 'FETCH_CONSERVATORSHIP_RENDERED_LETTER_TEMPLATE';
export const FETCH_LETTER_LIST = 'FETCH_LETTER_LIST';
export const FETCH_LETTER_DETAILS = 'FETCH_LETTER_DETAILS';
export const FETCH_OUTBOX = 'FETCH_OUTBOX_LIST';
export const FETCH_OUTBOX_DETAILS = 'FETCH_OUTBOX_DETAILS';
export const FETCH_LETTER_PREVIEW = 'FETCH_LETTER_PREVIEW';
export const FETCH_LETTER_WORD = 'FETCH_LETTER_WORD';
export const FETCH_LETTER_PDF = 'FETCH_LETTER_PDF';
export const ADD_OUTBOX = 'ADD_OUTBOX';
export const ADD_LETTER = 'ADD_LETTER';
export const UPDATE_LETTER = 'UPDATE_LETTER';
export const MAIL_LETTER = 'MAIL_LETTER';
export const DELETE_LETTER = 'DELETE_LETTER';
export const DELETE_OUTBOX = 'DELETE_OUTBOX';
export const SET_LETTER_PAGE = 'SET_LETTER_LIST_PAGE';
export const GET_LETTER_PAGE = 'GET_LETTER_LIST_PAGE';
export const SET_OUTBOX_PAGE = 'SET_LETTER_OUTBOX_PAGE';
export const GET_OUTBOX_PAGE = 'GET_LETTER_OUTBOX_PAGE';
export const GET_EMAIL_TEMPLATE_LIST = 'GET_EMAIL_TEMPLATE_LIST';
export const GET_EMAIL_TEMPLATE_PAGE = 'GET_EMAIL_TEMPLATE_PAGE';
export const SET_EMAIL_TEMPLATE_PAGE = 'SET_EMAIL_TEMPLATE_PAGE';

export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATE_LIST';
export const FETCH_RENDERED_EMAIL_TEMPLATE = 'FETCH_EMAIL_TEMPLATE_RENDERED';
export const FETCH_EMAIL_TEMPLATE_DETAILS = 'FETCH_EMAIL_TEMPLATE_DETAILS';
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';

const initialState = (): CorrespondenceState => ({
  active: null,
  letterTemplates: {
    items: [],
    page: 1
  },
  letters: {
    items: [],
    page: 1
  },
  outbox: {
    items: [],
    page: 1
  },
  emailTemplates: {
    items: [],
    page: 1
  }
});

const getters: GetterTree<CorrespondenceState, RootState> = {
  [GET_LETTER_TEMPLATE_LIST]: (state: CorrespondenceState): LetterTemplate[] => state.letterTemplates.items,
  [GET_LETTER_TEMPLATE_PAGE]: (state: CorrespondenceState): number => state.letterTemplates.page,
  [GET_LETTER_ACTIVE]: (state: CorrespondenceState) => state.active,
  [GET_LETTER_PAGE]: (state: CorrespondenceState) => state.letters.page,
  [GET_OUTBOX_PAGE]: (state: CorrespondenceState) => state.outbox.page,
  [GET_EMAIL_TEMPLATE_LIST]: (state) => state.emailTemplates.items,
  [GET_EMAIL_TEMPLATE_PAGE]: (state) => state.emailTemplates.page
};

const mutations: MutationTree<CorrespondenceState> = {
  SET_LETTER_TEMPLATE_ITEMS(state: CorrespondenceState, items: LetterTemplate[]) {
    state.letterTemplates.items = items;
  },
  SET_LETTER_TEMPLATE(state: CorrespondenceState, letterTemplate: LetterTemplate) {
    const index = state.letterTemplates.items.findIndex((item) => letterTemplate.id === item.id);

    if (index !== -1) {
      state.letterTemplates.items.splice(index, 1);
    }

    state.letterTemplates.items = [
      ...state.letterTemplates.items,
      letterTemplate
    ];
  },
  REMOVE_LETTER_TEMPLATE(state: CorrespondenceState, letterTemplateId: string) {
    const index = state.letterTemplates.items.findIndex((letter) => letter.id === letterTemplateId);

    if (index !== -1) {
      state.letterTemplates.items.splice(index, 1);
    }

    state.letterTemplates.items = [...state.letterTemplates.items];
  },
  [SET_LETTER_TEMPLATE_LIST_PAGE](state: CorrespondenceState, page: number) {
    state.letterTemplates.page = page;
  },
  [SET_LETTER_ACTIVE](state: CorrespondenceState, letter: WriteLetter) {
    state.active = letter;
  },
  [UPDATE_LETTER_ACTIVE](state: CorrespondenceState, letter: WriteLetter) {
    state.active = {
      ...state.active,
      ...letter
    };
  },
  [INIT_LETTER_ACTIVE](state: CorrespondenceState, employee: string) {
    state.active = createLetter(employee);
  },
  SET_OUTBOX_ITEMS(state, items: Outbox[]) {
    state.outbox.items = items;
  },
  SET_LETTER_ITEMS(state: CorrespondenceState, items: Letter[]) {
    state.letters.items = items;
  },
  SET_LETTER(state: CorrespondenceState, letter: Letter) {
    const index = state.letters.items.findIndex((item) => letter.id === item.id);

    if (index !== -1) {
      state.letters.items.splice(index, 1);
    }

    state.letters.items = [
      ...state.letters.items,
      letter
    ];
  },
  REMOVE_LETTER(state: CorrespondenceState, letterId: string) {
    const index = state.letters.items.findIndex((letter) => letter.id === letterId);

    if (index !== -1) {
      state.letters.items.splice(index, 1);
    }

    state.letters.items = [...state.letters.items];
  },
  REMOVE_OUTBOX(state: CorrespondenceState, letterId: string) {
    const index = state.outbox.items.findIndex((letter) => letter.id === letterId);

    if (index !== -1) {
      state.outbox.items.splice(index, 1);
    }

    state.outbox.items = [...state.outbox.items];
  },
  SET_OUTBOX(state: CorrespondenceState, outbox: Outbox) {
    const index = state.outbox.items.findIndex((item) => outbox.id === item.id);

    if (index !== -1) {
      state.outbox.items.splice(index, 1);
    }

    state.outbox.items = [
      ...state.outbox.items,
      outbox
    ];
  },
  SET_EMAIL_TEMPLATE_ITEMS(state: CorrespondenceState, items: EmailTemplate[]) {
    state.emailTemplates.items = items;
  },
  [SET_LETTER_PAGE](state: CorrespondenceState, page: number) {
    state.letters.page = page;
  },
  [SET_OUTBOX_PAGE](state: CorrespondenceState, page: number) {
    state.outbox.page = page;
  },
  [SET_EMAIL_TEMPLATE_PAGE](state: CorrespondenceState, page: number) {
    state.emailTemplates.page = page;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<CorrespondenceState, RootState> => ({
  async [FETCH_LETTER_TEMPLATE_LIST]({ commit }, employee: string): Promise<ApiResponse<LetterTemplate[]>> {
    try {
      const response = await Vue.axios.get<any, AxiosResponse<LetterTemplate[]>>('api/letter-template/list', {
        responseType: 'json',
        params: { employee }
      });

      commit('SET_LETTER_TEMPLATE_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_LETTER_TEMPLATE_DETAILS]({ commit }, letterTemplateId: string): Promise<ApiResponse<LetterTemplate>> {
    try {
      const response = await Vue.axios.get<any, AxiosResponse<LetterTemplate>>(
        `api/letter-template/${letterTemplateId}/details`,
        { responseType: 'json' }
        );
      commit('SET_LETTER_TEMPLATE', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [ADD_LETTER_TEMPLATE]({ commit, dispatch }, letterTemplate: WriteLetterTemplate): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter-template/create', letterTemplate, { responseType: 'json' });

      dispatch(FETCH_LETTER_TEMPLATE_DETAILS, letterTemplate.letter_template_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [DELETE_LETTER_TEMPLATE]({ commit }, { letter_template_id }: WriteLetterTemplate): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter-template/delete', { letter_template_id }, { responseType: 'json' });

      commit('REMOVE_LETTER_TEMPLATE', letter_template_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_LETTER_TEMPLATE]({ commit, dispatch }, letterTemplate: WriteLetterTemplate): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter-template/update', letterTemplate, { responseType: 'json' });

      dispatch(FETCH_LETTER_TEMPLATE_DETAILS, letterTemplate.letter_template_id);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_RENDERED_LETTER]({ commit }, params: RenderLetterParams): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/letter-template/rendered`, { responseType: 'json', params });

      commit(UPDATE_LETTER_ACTIVE, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_CONSERVATORSHIP_RENDERED_LETTER]({ commit }, params: RenderConservatorshipLetterParams): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/letter-template/conservatorship-rendered`, {
        responseType: 'json',
        params
      });

      commit(UPDATE_LETTER_ACTIVE, response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_LETTER_LIST]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/letter/list', { responseType: 'json' });

      commit('SET_LETTER_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_OUTBOX]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/outbox/list', { responseType: 'json' });

      commit('SET_OUTBOX_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_LETTER_DETAILS]({ commit }, letterId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/letter/${letterId}/details`, { responseType: 'json' });
      commit('SET_LETTER', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_OUTBOX_DETAILS]({ commit }, letterId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/outbox/${letterId}/details`, { responseType: 'json' });
      commit('SET_OUTBOX', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_LETTER_PDF]({ commit }, letterId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/letter/${letterId}/pdf`, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_LETTER_PREVIEW]({ commit }, letter: object): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/letter/preview`, letter, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_LETTER_WORD]({ commit }, letter: object): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.post(`api/letter/word`, letter, { responseType: 'blob' });

      return { content: response.data };
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_LETTER]({ commit, dispatch, rootGetters }, letter: WriteLetter): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter/create', letter, { responseType: 'json' });
      dispatch(FETCH_LETTER_DETAILS, letter.letter_id);

      return {};
    } catch (error) {

      return dispatch(UPDATE_LETTER, letter);
    }
  },
  async [UPDATE_LETTER]({ commit, dispatch }, letter: WriteLetter): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter/update', letter, { responseType: 'json' });
      dispatch(FETCH_LETTER_DETAILS, letter.letter_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [ADD_OUTBOX]({ commit, dispatch, rootGetters }, outbox: WriteOutbox): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/outbox/create', outbox, { responseType: 'json' });
      dispatch(FETCH_OUTBOX_DETAILS, outbox.letter_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [DELETE_OUTBOX]({ commit, dispatch }, { letter_id }: WriteOutbox): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/outbox/delete', { letter_id }, { responseType: 'json' });
      commit('REMOVE_OUTBOX', letter_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [DELETE_LETTER]({ commit, dispatch }, { letter_id }: WriteLetter): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter/delete', { letter_id }, { responseType: 'json' });
      commit('REMOVE_LETTER', letter_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [MAIL_LETTER]({ commit, dispatch }, letter: MailLetterParams): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/letter/mail', letter, { responseType: 'json' });
      dispatch(FETCH_LETTER_DETAILS, letter.letter_id);

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [FETCH_RENDERED_EMAIL_TEMPLATE]({ commit }, params): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/email-template/rendered`, { responseType: 'json', params });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_EMAIL_TEMPLATES]({ commit }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get('api/email-template/list', { responseType: 'json' });

      commit('SET_EMAIL_TEMPLATE_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_EMAIL_TEMPLATE_DETAILS]({ commit }, emailTemplateId: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/email-template/${emailTemplateId}/details`, { responseType: 'json' });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [ADD_EMAIL_TEMPLATE]({ commit, dispatch }, emailTemplate: WriteEmailTemplate): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/email-template/create', emailTemplate, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [DELETE_EMAIL_TEMPLATE]({ commit }, { email_template_id }: WriteEmailTemplate): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/email-template/delete', { email_template_id }, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_EMAIL_TEMPLATE]({ commit }, emailTemplate: WriteEmailTemplate): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/email-template/update', emailTemplate, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_LETTERS({ commit }, { data }) {
    commit('SET_LETTER_ITEMS', data.letters);
  },
  SET_OUTBOX({ commit }, { data }) {
    commit('SET_OUTBOX_ITEMS', data.outbox);
  },
  SET_LETTER_TEMPLATE_ITEMS({ commit }, { data }) {
    commit('SET_LETTER_TEMPLATE_ITEMS', data.templates);
  },
  SET_EMAIL_TEMPLATES({ commit }, { data }) {
    commit('SET_LIST_ITEMS', data.templates);
  }
});

export default (Vue: Vue | VueConstructor): Module<CorrespondenceState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
