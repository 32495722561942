










































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AppTable from '@/components/AppTable.vue';
import { Birthday } from '../types';
import { ApiResponse } from '@/components/types';
import { FETCH_BIRTHDAY_LIST } from '@/modules/conservatorship/store/actions';
import { addMonths, format, setYear, subMonths } from '@/filter/dateFormat';

const Conservatorship = namespace('conservatorship');

@Component({
  components: { AppTable }
})
export default class BirthdayPlugin extends Vue {
  @Conservatorship.Action(FETCH_BIRTHDAY_LIST) fetch!: (month?: string) => Promise<ApiResponse<Birthday[]>>;

  loading: boolean = false;

  items: Birthday[] = [];
  month: string = format(Date.now(), 'yyyy-MM-dd');

  subMonth() {
    this.month = format(subMonths(this.month, 1), 'yyyy-MM-dd');
  }

  addMonth() {
    this.month = format(addMonths(this.month, 1), 'yyyy-MM-dd');
  }

  formatBirthday(birthday: string) {
    return format(birthday, 'dd.MM.yyyy');
  }

  @Watch('month', { immediate: true })
  async fetchList(month: string) {
    this.loading = true;

    const { content } = await this.fetch(month);

    if (content) {
      this.items = content.map(({ birthday, ...item }) => ({
        ...item,
        birthday: format(setYear(birthday, 2019), 'yyyy-MM-dd')
      }));
    }

    this.loading = false;
  }
}
