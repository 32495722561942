/* eslint-disable no-console */
import { register } from 'register-service-worker';
import { VueConstructor } from 'vue';

export const registerSW = () =>
  new Promise(resolve => {
    if (process.env.NODE_ENV === 'production') {
      register(`${process.env.BASE_URL}service-worker.js`, {
        ready(registration) {
          resolve(registration);
        },
        registered(registration) {
          resolve(registration);
        },
        updatefound(registration) {
          resolve(registration);
        },
        cached(registration) {
          resolve(registration);
        },
        updated(registration) {
          resolve(registration);
        },
        offline() {
          resolve();
        },
        error(error) {
          console.error('Error during service worker registration:', error);
          resolve();
        }
      });
    } else {
      resolve();
    }
  });

export const VueSW = {
  install(Vue: VueConstructor, { registration }: any) {
    Vue.prototype.$serviceWorker = registration;
  }
};

declare module 'vue/types/vue' {
  interface Vue {
    readonly $serviceWorker: any;
  }
}
