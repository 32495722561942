




























import { Component, Watch } from 'vue-property-decorator';
import { openDB } from 'idb';
import AppUserMenu from '@/views/application/components/AppUserMenu.vue';
import NavigationGroupItem from '@/views/application/components/NavigationGroupItem.vue';
import NavigationItem from '@/views/application/components/NavigationItem.vue';
import MenuItem from '@/views/application/components/MenuItem.vue';
import { namespace, State } from 'vuex-class';
import { GET_BACKGROUND_COLOR } from '@/modules/employee/store';
import SuccessDialog from '@/views/application/components/SuccessDialog.vue';
import ErrorDialog from '@/views/application/components/ErrorDialog.vue';
import { InitializeMixin } from '@/views/mixin';
import ImportSuccessAlerts from '@/modules/listOfAssets/components/ImportSuccessAlerts.vue';
import CompensationZipSuccessAlerts from '@/modules/compensation/components/CompensationZipSuccessAlerts.vue';
import Navigation from '@/views/application/components/Navigation.vue';

const Employee = namespace('employee');

declare module 'vue/types/vue' {
  interface Vue {
    $socket: any;
    backgroundColor: string;
  }
}

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
]);

@Component({
  components: {
    Navigation,
    CompensationZipSuccessAlerts,
    ImportSuccessAlerts,
    ErrorDialog,
    SuccessDialog,
    AppUserMenu,
    NavigationGroupItem,
    NavigationItem,
    MenuItem
  },
  metaInfo() {
    return {
      title: 'B.TREU',
      style: [
        {
          cssText: !this.backgroundColor ? '' : `
        body .v-application.theme--light {
          background-color: ${this.backgroundColor};
        }

        body .v-application .theme--light.v-navigation-drawer,
        body .theme--light .v-navigation-drawer {
          background-color: ${this.backgroundColor};
        }

        body .v-application .theme--light.v-card,
        body .theme--light .v-card {
          background-color: ${this.backgroundColor};
        }

        body .v-application .theme--light.v-table,
        body .theme--light .v-table {
          background-color: ${this.backgroundColor};
        }

        body .v-application .theme--light.v-list,
        body .v-application .theme--light.v-data-table,
        body .theme--light .v-list {
          background-color: ${this.backgroundColor};
        }

        body .v-theme--light .v-main--wrap {
          background-color: ${this.backgroundColor};
        }
        `,
          type: 'text/css'
        }
      ]
    };
  }
})
export default class Application extends InitializeMixin {
  @Employee.Getter(GET_BACKGROUND_COLOR) backgroundColor !: string;

  @State('socket') socket: any;

  drawer: boolean = this.$vuetify.breakpoint.mdAndUp;

  @Watch('socket', { immediate: true })
  onSocketOpen(socket: any) {
    if (!socket) return;

    // @ts-ignore
    this.$socket.sendObj({ path: 'authorization', token: this.$auth.token() });
  }

  async created() {
    if (!this.$serviceWorker || !this.$serviceWorker.pushManager) {
      return;
    }

    try {
      const trans = await openDB('employee-store', 2, {
        upgrade(db) {
          if (db.objectStoreNames.contains('employees')) {
            return;
          }

          db.createObjectStore('employees');
        }
      });

      await trans.put('employees', this.$auth.user().id, 'current');

      const api = process.env.VUE_APP_PUSH || `https://${document.location.host}/push`;

      const response = await this.$http.get(`${api}/subscription/key`, { responseType: 'json' });

      const subscription = await this.$serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(response.data.key)
      });

      await this.$http.post(`${api}/subscribe`, { subscription, employee: this.$auth.user().id }, { responseType: 'json' });
    } catch (error) {
      console.log(error);
    }
  }

  urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
