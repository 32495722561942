







































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AppTable from '@/components/AppTable.vue';
import { DueReport } from '../types';
import { ApiResponse } from '@/components/types';
import { FETCH_DUE_REPORTS } from '@/modules/conservatorship/store/actions';

const Conservatorship = namespace('conservatorship');

@Component({
  components: { AppTable }
})
export default class DueReportPlugin extends Vue {
  @Conservatorship.Action(FETCH_DUE_REPORTS) fetchDueReports!: () => Promise<ApiResponse<DueReport[]>>;

  loading: boolean = false;

  items: DueReport[] = [];

  async created() {
    this.loading = true;

    const { content } = await this.fetchDueReports();

    if (content) {
      this.items = content;
    }

    this.loading = false;
  }
}
