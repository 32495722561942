import { Term, TermView, WriteTerm } from '@/modules/term/types';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';

export const MapViewToWrite = ({ id, conservatorshipId, conservatee, employee, begin, deadline, title, note, repeatable }: TermView): WriteTerm => ({
  term_id: id,
  employee_id: employee.id,
  conservatorship_id: conservatorshipId,
  title,
  note,
  begin,
  deadline,
  repeatable
});

export const MapStateToView = ({ conservatee, ...term }: Term): TermView => ({
  ...term,
  conservatee: `${conservatee.surname}, ${conservatee.forename}`,
  conservatorshipId: conservatee.id,
  deadlineTrans: format(term.deadline, 'dd.MM.yyyy'),
  sortedDeadline: `${term.deadline}-${term.created}-${conservatee.surname}-${term.title}`,
  sortedBegin: `${term.begin}-${term.created}-${conservatee.surname}-${term.title}`
});


export const create = (employee_id?: string): WriteTerm => {
  return {
    term_id: uuid(),
    conservatorship_id: '',
    employee_id: employee_id || '',
    deadline: format(Date.now(), 'yyyy-MM-dd'),
    begin: format(Date.now(), 'yyyy-MM-dd'),
    repeatable: 'none',
    title: '',
    note: '',
  };
};
