

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class TextField extends Vue {
  @Prop() value?: string | number;
  @Prop() placeholder?: string;
  @Prop({ type: String, default: 'text' }) type!: string;
  @Prop({ type: Object }) isEqual?: any;
  @Prop({ default: false }) bic!: boolean;
  @Prop({ default: false }) phone!: boolean;
  @Prop({ default: false }) email!: boolean;
  @Prop({ type: Boolean, default: false }) numeric!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Boolean, default: false }) filled!: boolean;

  get validate() {
    return {
      required: this.required,
      numeric: this.numeric,
      isBIC: this.bic,
      email: this.email,
      isPhoneNumber: this.phone,
      ...(this.isEqual ? { isEqual: this.isEqual } : {})
    };
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners;

    return listeners;
  }

  input(value: any) {
    if (this.type === 'number' && !isNaN(parseFloat(value))) {
      value = parseFloat(value);
    }

    this.$emit('input', value);
  }

  created() {
    if (this.type === 'number' && !isNaN(parseFloat(this.value as string))) {
      this.$emit('input', parseFloat(this.value as string));
    }
  }
}
