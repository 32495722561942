import { VueConstructor } from 'vue';
import AsyncFormSubmit from '@/components/form/AsyncFormSubmit.vue';
import TextField from '@/components/form/TextField.vue';
import AsyncSaveButton from '@/components/form/AsyncSaveButton.vue';
import AsyncDeleteButton from '@/components/form/AsyncDeleteButton.vue';
import ResponseButton from '@/components/form/ResponseButton.vue';
import TextArea from '@/components/form/TextArea.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';

export default (Vue: VueConstructor) => {
  Vue.component('TextField', TextField);
  Vue.component('TextArea', TextArea);
  Vue.component('ErrorAlert', ErrorAlert);
  Vue.component('ResponseButton', ResponseButton);
  Vue.component('AsyncSaveButton', AsyncSaveButton);
  Vue.component('AsyncDeleteButton', AsyncDeleteButton);
  Vue.component('AsyncFormSubmit', AsyncFormSubmit);
}
