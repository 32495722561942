import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue from 'vue';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API || `https://${document.location.host}/backend`,
  timeout: 600000
});

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

instance.interceptors.request.use(config => {
  if (config.method !== 'get') {
    return config;
  }

  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    });
  };

  return config;
});

Vue.use(VueAxios, instance);
