import { Profitability, ProfitabilityState, WriteProfitability } from './types';
import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';

export const FETCH_PROFITABILITIES = 'FETCH_CONSERVATOR_PROFITABILITIES';
export const SAVE_PROFITABILITY = 'SAVE_CONSERVATOR_PROFITABILITY';

const initialState = (): ProfitabilityState => ({
  list: {}
});

const mutations: MutationTree<ProfitabilityState> = {
  SET_PROFITABILITIES: (state: ProfitabilityState, { month, profitabilities }) => {
    state.list = {
      ...state.list,
      [month]: profitabilities
    };
  },
  UPDATE_PROFITABILITY: (state, profitability: Profitability) => {
    if (!state.list[profitability.month]) return;

    const index: number = state.list[profitability.month]
      .findIndex((entry) => entry.id === profitability.id);

    state.list[profitability.month].splice(index, 1);

    state.list = {
      ...state.list,
      [profitability.month]: [...state.list[profitability.month], profitability]
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ProfitabilityState, RootState> => ({
  async [FETCH_PROFITABILITIES]({ commit }, month: string) {
    try {
      const response = await Vue.axios.get('api/conservatorship-management/conservator/profitabilities', {
        responseType: 'json',
        params: { month }
      });

      commit('SET_PROFITABILITIES', { month, profitabilities: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [SAVE_PROFITABILITY]({ commit, dispatch }, profitability: WriteProfitability) {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservator/add-profitability', profitability, { responseType: 'json' });

      dispatch(FETCH_PROFITABILITIES, profitability.month);
      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {

      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  }
});

export default (instance: Vue | VueConstructor): Module<ProfitabilityState, RootState> => ({
  namespaced: true,
  state: initialState(),
  mutations,
  actions: actions(instance)
});
