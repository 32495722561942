/* eslint-disable camelcase */
import { TimeAllocationSearchParams, TimeAllocationState } from '@/modules/timeAllocation/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import Vue, { VueConstructor } from 'vue';

export const FETCH_LIST = 'FETCH_TIME_ALLOCATION_LIST';
export const GET_LIST = 'GET_TIME_ALLOCATION_LIST';

const initialState = (): TimeAllocationState => ({
  list: {}
});

const getters: GetterTree<TimeAllocationState, RootState> = {
  [GET_LIST]: (state: TimeAllocationState) => (key: string) => state.list[key]
};

const mutations: MutationTree<TimeAllocationState> = {
  SET_LIST_ITEMS: (state: TimeAllocationState, { key, items }) => {
    state.list = {
      ...state.list,
      [key]: items
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<TimeAllocationState, RootState> => ({
  [FETCH_LIST]: async ({ commit }, { month, conservator }: TimeAllocationSearchParams) => {
    try {
      const response = await Vue.axios.get('api/time-allocation-simple', {
        responseType: 'json',
        params: { month, conservator }
      });

      commit('SET_LIST_ITEMS', { key: `${conservator}-${month}`, items: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  }
});

export default (instance: Vue | VueConstructor): Module<TimeAllocationState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});

