


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import debounce from 'lodash.debounce';
import { Car } from '../types';
import { GET_LIST } from '@/modules/car/store';
import { isValid } from 'date-fns';
import { AxiosResponse } from 'axios';

const CarStore = namespace('car');

@Component({})
export default class CarAvailableSelect extends Vue {
  @Prop() value?: string;
  @Prop() from?: string;
  @Prop() to?: string;
  @Prop() reservation?: string;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: false }) notAvailable!: boolean;

  @CarStore.Getter(GET_LIST) cars!: Car[];

  items: Car[] = [];
  loading: boolean = false;
  debounceQuery = debounce(this.queryCars, 600);

  async queryCars(from?: string, to?: string) {
    if (!from || !to) {
      this.items = [];
      return;
    }

    if (!isValid(new Date(from)) || !isValid(new Date(to))) {
      return;
    }

    this.loading = true;

    try {
      const response = await this.$http.get<any, AxiosResponse<Car[]>>('api/car/available', {
        params: { from, to, reservation: this.reservation },
        responseType: 'json'
      });
      this.items = response.data;
    } catch (error) {
      this.items = [];
    }

    this.loading = false;
  }

  @Watch('from')
  onFromChange(from?: string) {
    this.debounceQuery(from, this.to);
  }

  @Watch('to')
  onToChange(to?: string) {
    this.debounceQuery(this.from, to);
  }

  @Watch('items')
  onItemsChange(items: Car[]) {
    if ((!items || items.length === 0) && this.value) {
      this.$emit('update:notAvailable', true);
      this.$emit('input', null);

      return;
    }

    this.$emit('update:notAvailable', false);

    if (!this.value) return;

    const index = items.findIndex((car) => car.id === this.value);

    if (index === -1) {
      this.$emit('input', null);
      this.$emit('update:notAvailable', true);
    }
  }

  created() {
    if (!this.from || !this.to) return;

    this.queryCars(this.from, this.to);
  }
}
