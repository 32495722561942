












import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { GET_ALERT_ERROR, SET_ALERT_ERROR } from '@/store/types';
import { AxiosError } from 'axios';

@Component
export default class ErrorDialog extends Vue {
  @Getter(GET_ALERT_ERROR) error!: Error | AxiosError;
  @Mutation(SET_ALERT_ERROR) setError!: (error: string | null) => void;

  dialog = false;

  @Watch('error')
  onError(value: any) {
    if (!value) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);

    setTimeout(() => {
      this.dialog = false;
    }, 3800);

    setTimeout(() => {
      this.setError(null);
    }, 4000);
  }
}
