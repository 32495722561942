










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { GET_ALERT_SUCCESS, SET_ALERT_SUCCESS } from '@/store/types';

@Component
export default class SuccessDialog extends Vue {
  @Prop({ type: String, default: 'Änderung wurde erfolgreich gespeichert' }) text!: string;
  @Getter(GET_ALERT_SUCCESS) show!: boolean;
  @Mutation(SET_ALERT_SUCCESS) setShow!: (show: boolean) => void;

  dialog = false;

  @Watch('show')
  onSuccess(value: string) {
    if (!value) {
      this.dialog = false;
      return;
    }

    setTimeout(() => {
      this.dialog = true;
    }, 500);
  }

  @Watch('dialog')
  onOpen(dialog: boolean) {
    if (!dialog) return;

    setTimeout(() => this.setShow(false), 2500);
  }
}
