














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TimeText extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String }) placeholder?: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;

  get validate(): object {
    return {
      required: this.required
    };
  }

  input(value: string): void {
    this.$emit('input', value);
  }
}
