/* eslint-disable camelcase */
import Vue, { VueConstructor } from 'vue';
import format from '@/filter/dateFormat';
import {
  BlockedItemsParams,
  Car,
  CarColorConfiguration,
  CarState,
  ConfigureColor,
  RemoveBlockedItemsParams,
  WriteCar
} from '@/modules/car/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';

export const FETCH_LIST = 'FETCH_CAR_LIST';
export const FETCH_BLOCKED_CAR_LIST = 'FETCH_BLOCKED_CAR_TIME_LIST';
export const FETCH_COLORS = 'FETCH_CAR_COLORS';
export const REGISTER_NEW_CAR = 'REGISTER_NEW_CAR';
export const CORRECT_CAR = 'CORRECT_CAR';
export const CONFIGURE_COLOR = 'CONFIGURE_CAR_COLOR';
export const GET_LIST = 'GET_CAR_LIST';
export const GET_BY_ID = 'GET_CAR_BY_ID';
export const GET_BLOCKED = 'GET_BLOCKED_CAR';
export const GET_COLORS = 'GET_CAR_COLORS';
export const GET_BLOCKED_BY_YEAR_WEEK = 'GET_BLOCKED_CAR_BY_YEAR_WEEK';

const initialState = (): CarState => ({
  list: [],
  blocked: {},
  colors: []
});

const getters: GetterTree<CarState, RootState> = {
  [GET_BLOCKED_BY_YEAR_WEEK]: (state) => (yearWeek: string) => state.blocked[yearWeek] || [],
  [GET_BLOCKED]: (state) => state.blocked,
  [GET_COLORS]: (state) => state.colors,
  [GET_LIST]: (state): Car[] => state.list,
  [GET_BY_ID]: (state: CarState) => (id: string) => state.list.find((item) => item.id === id)
};

const mutations: MutationTree<CarState> = {
  SET_BLOCKED_LIST_ITEMS(state: CarState, { items, yearWeek }: BlockedItemsParams) {
    state.blocked = {
      ...state.blocked,
      [yearWeek]: items
    };
  },
  REMOVE_BLOCKED_LIST_ITEMS(state: CarState, { reservationNumber }: RemoveBlockedItemsParams) {
    for (const yearWeek of Object.keys(state.blocked)) {
      state.blocked = {
        ...state.blocked,
        [yearWeek]: state.blocked[yearWeek].filter((blocked) => blocked.reservationNumber !== reservationNumber)
      };
    }
  },
  SET_LIST_ITEMS(state: CarState, items: Car[]) {
    state.list = items;
  },
  SET_COLORS(state: CarState, items: CarColorConfiguration[]) {
    state.colors = items.sort((a, b) => a.name.localeCompare(b.name));
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<CarState, RootState> => ({
  async [FETCH_BLOCKED_CAR_LIST]({ commit }, weekDay) {
    try {
      const response = await Vue.axios.get('api/car/reserved', { responseType: 'json', params: { weekDay } });
      commit('SET_BLOCKED_LIST_ITEMS', { items: response.data, yearWeek: format(weekDay, 'yyyyww') });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_LIST]({ commit }) {
    try {
      const response = await Vue.axios.get('api/car/list', { responseType: 'json' });
      commit('SET_LIST_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_COLORS]({ commit }) {
    try {
      const response = await Vue.axios.get('api/car/colors', { responseType: 'json' });
      commit('SET_COLORS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [REGISTER_NEW_CAR](_, createCar: WriteCar) {
    try {
      await Vue.axios.post(
        'api/commands/appointment-management/car/create-new',
        createCar,
        { responseType: 'json' }
        );

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [CORRECT_CAR](_, correctCar: WriteCar) {
    try {
      await Vue.axios.post(
        'api/commands/appointment-management/car/correct',
        correctCar,
        { responseType: 'json' }
        );

      return {};
    } catch (error) {
      return { error };
    }
  },
  async [CONFIGURE_COLOR]({ dispatch }, configureColor: ConfigureColor) {
    try {
      await Vue.axios.post(
        'api/commands/appointment-management/car/configure-color',
        configureColor,
        { responseType: 'json' }
        );

      await dispatch(FETCH_COLORS);

      return {};
    } catch (error) {
      return { error };
    }
  },
  SET_RESERVED({ commit }, { data }) {
    commit('SET_BLOCKED_LIST_ITEMS', { items: data.reserved, yearWeek: data.week });
  },
  REMOVE_RESERVED({ commit }, { data }) {
    commit('REMOVE_BLOCKED_LIST_ITEMS', { reservationNumber: data.reserved.reservation_number });
  },
  SET_LIST({ commit }, { data }) {
    commit('SET_LIST_ITEMS', data.cars);
  }
});

export default (instance: Vue | VueConstructor): Module<CarState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
