import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import { ScopeOfFunctionsState } from '@/modules/scopeOfFunctions/types';
import Vue, { VueConstructor } from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApiResponse } from '@/components/types';

export const FETCH_LIST = 'FETCH_SCOPE_OF_FUNCTIONS_LIST';
export const GET_LIST = 'GET_SCOPE_OF_FUNCTIONS_LIST';
export const GET_BY_IDS = 'GET_SCOPE_OF_FUNCTIONS_BY_IDS';
export const CREATE_SCOPE_OF_FUNCTIONS = 'CREATE_SCOPE_OF_FUNCTIONS';
export const EDIT_SCOPE_OF_FUNCTIONS = 'EDIT_SCOPE_OF_FUNCTIONS';
export const DELETE_SCOPE_OF_FUNCTIONS = 'DELETE_SCOPE_OF_FUNCTIONS';

const initialState = (): ScopeOfFunctionsState => ({
  list: []
});

const getters: GetterTree<ScopeOfFunctionsState, RootState> = {
  [GET_LIST]: (state) => state.list,
  [GET_BY_IDS]: (state) => (ids: string[]) => ids
    .map((id) => state.list.find((scopeOfFunctions) => scopeOfFunctions.id === id))
};

const mutations: MutationTree<ScopeOfFunctionsState> = {
  SET_ITEMS: (state, items) => {
    state.list = items;
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ScopeOfFunctionsState, RootState> => ({
  [FETCH_LIST]: async ({ commit }): Promise<ApiResponse> => {
    try {
      const response = await Vue.axios.get('api/scopes-of-functions/list', { responseType: 'json' });
      commit('SET_ITEMS', response.data);

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  [CREATE_SCOPE_OF_FUNCTIONS]: async ({ commit }, scopeOfFunctions): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/scope-of-functions/create', scopeOfFunctions, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [EDIT_SCOPE_OF_FUNCTIONS]: async ({ commit }, scopeOfFunctions): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/scope-of-functions/edit', scopeOfFunctions, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  [DELETE_SCOPE_OF_FUNCTIONS]: async ({ commit }, scopeOfFunctions): Promise<ApiResponse> => {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/scope-of-functions/delete', scopeOfFunctions, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_LIST: ({ commit }, { data }): void => {
    commit('SET_ITEMS', data.scopeOfFunctions);
  }
});

export default (instance: Vue | VueConstructor): Module<ScopeOfFunctionsState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(instance)
});
