





































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST } from '@/modules/conservatorship/store/actions';
import { Conservatorship, ConservatorshipList, ConservatorshipListView } from '@/modules/conservatorship/types';
import { AuthorizationMixin } from '@/views/mixin';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorship');

@Component({
  inheritAttrs: false
})
export default class ConservatorshipSelect extends AuthorizationMixin {
  @Prop({ type: [String, Array] }) value?: string | string[] | null;
  @Prop({ type: String, default: '' }) conservator!: string;
  @Prop({ type: Boolean, default: true }) required!: boolean;
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Boolean, default: false }) showArchived!: boolean;

  @Conservatorship.State('list') list!: ConservatorshipList;
  @Conservatorship.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse<Conservatorship[]>>;

  loading: boolean = false;

  @Watch('conservator')
  changeConservator() {
    this.$emit('input', this.multiple ? [] : null);
  }

  get items(): ConservatorshipListView[] {
    const list = this.list.items
      .filter((conservatorship) => this.showArchived || conservatorship.state !== 'archived')
      .map(({ conservatee, ...conservatorship }) => ({
        id: conservatorship.id,
        conservatee: `${conservatee.surname}, ${conservatee.forename}`,
        conservatorId: conservatorship.conservator.id
      }))
      .sort((first, second) => first.conservatee.localeCompare(second.conservatee));

    if (!this.isSupport()) {
      return list.filter((conservatorship) => conservatorship.conservatorId === this.$auth.user().id);
    }

    if (this.conservator) {
      return list.filter((conservatorship) => conservatorship.conservatorId === this.conservator);
    }

    return list;
  }

  async created() {
    if (this.list.items.length !== 0) return;

    this.loading = true;

    try {
      await this.fetch();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }
}
