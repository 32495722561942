




























import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppTable from '@/components/AppTable.vue';
import { FETCH_EXPORTS, DOWNLOAD_COMPENSATIONS } from "../store";
import { Export } from "../types";
import { startOfMonth, endOfMonth, format } from "date-fns";

const AccountingStore = namespace('accounting');

@Component({
  components: {
    AppTable,
    ErrorAlert
  }
})
export default class AccountingPlugin extends Vue {
  @AccountingStore.Action(FETCH_EXPORTS) fetch!: () => Promise<ApiResponse<Export[]>>;
  @AccountingStore.Action(DOWNLOAD_COMPENSATIONS) download!: (params: { start?: string, end?: string }) => Promise<ApiResponse<Blob>>;

  error: any = null;
  loading: boolean = false;
  downloading: boolean = false;
  list: Export[] = [];

  pagination: Pagination = {
    sortBy: ['account'],
    itemsPerPage: -1
  };

  get headers() {
    return [
      { align: 'left', text: 'Rechnungsmonat', value: 'month' },
      { align: 'left', text: 'Letzter Export', value: 'executed' },
      { align: 'left', text: 'Letzte Rechnungsänderung', value: 'contentChange' },
      { align: 'right', text: 'Export', value: null },
    ];
  }

  async downloadExport(month: string | undefined) {
    this.downloading = true;
    this.error = null;

    let start = undefined;
    let end = undefined;

    if (month) {
      start = format(startOfMonth(new Date(month)), 'yyyy-MM-dd');
    }
    if (month) {
      end = format(endOfMonth(new Date(month)), 'yyyy-MM-dd');
    }

    // @ts-ignore
    const { error, content } = await this.download({ start, end });
    this.downloading = false;

    if (error) {
      this.error = error;
      return;
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', 'BP_Rechnungsexport.zip');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return this.load()
  }

  async load() {
    const { content, error } = await this.fetch();

    if (error) {
      this.error = error;
    }

    if (content) {
      this.list = content;
    }
  }

  created() {
    this.load()
  }
}
