





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getTime, isToday } from '@/filter/dateFormat';
import { FETCH_LIST } from '../store';
import EditAppointmentDialog from './EditAppointmentDialog.vue';
import CancelAppointmentDialog from './CancelAppointmentDialog.vue';
import { Appointment, AppointmentList, AppointmentView } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateToView } from '../model';
import { routeToLocation } from '@/router';

const TableView = () => import('./PluginTable.vue');
const ListView = () => import('./PluginList.vue');

const Appointment = namespace('appointment');

@Component({
  components: {
    CancelAppointmentDialog,
    EditAppointmentDialog,
    ListView,
    TableView
  }
})
export default class AppointmentsPlugin extends Vue {
  @Appointment.State('list') list!: AppointmentList;
  @Appointment.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;

  @Prop({ type: String }) conservatorship!: string;
  @Prop({ type: Boolean, default: false }) today!: boolean;

  loading: boolean = false;

  get editAppointment(): string {
    return this.$route.query['editAppointment'] as string;
  }

  set editAppointment(editAppointment: string) {
    if (!editAppointment) {
      const { editAppointment, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, ...query })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, editAppointment } })).catch(() => {});
  }

  get cancelAppointment(): string {
    return this.$route.query['cancelAppointment'] as string;
  }

  set cancelAppointment(cancelAppointment: string) {
    if (!cancelAppointment) {
      const { cancelAppointment, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, cancelAppointment } })).catch(() => {});
  }

  get items(): AppointmentView[] {
    let list: Appointment[] = this.list.items.filter((appointment) => getTime(appointment.end) > Date.now());

    if (this.today) {
      list = list.filter((appointment) => isToday(appointment.begin))
    }

    if (this.conservatorship) {
      list = list.filter((appointment) => !!appointment.conservatorships.find((conservatorship) => {
        return conservatorship.conservatorship_id === this.conservatorship;
      }));
    }

    return list
      .sort((first, second) => first.start.localeCompare(second.start))
      .map((appointment) => MapStateToView(appointment))
      .filter((appointment, index) => isToday(appointment.start) || index < 5);
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}
