
















































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TermList, TermView } from '../types';
import FinishTermDialog from './FinishTermDialog.vue';
import InProgressTermDialog from './InProgressTermDialog.vue';
import { ApiResponse, Pagination } from '@/components/types';
import { MapStateToView } from '../model';
import EditTermDialog from '@/modules/term/components/EditTermDialog.vue';
import RemoveTermDialog from '@/modules/term/components/RemoveTermDialog.vue';
import { FETCH_LIST, INIT_CREATE } from '@/modules/term/store';
import { getTime } from '@/filter/dateFormat';
import ResetTermDialog from '@/modules/term/components/ResetTermDialog.vue';
import { AuthorizationMixin } from "@/views/mixin";
import CreateTermDialog from "@/modules/term/components/CreateTermDialog.vue";
import ListExportDialog from '../components/ListExportDialog.vue';

const Term = namespace('term');

const PluginTable = () => import('./PluginTable.vue');
const PluginList = () => import('./PluginList.vue');

export type TermOrdering = 'sortedDeadline' | 'sortedBegin';

@Component({
  components: {
    CreateTermDialog,
    ResetTermDialog,
    RemoveTermDialog,
    EditTermDialog,
    PluginTable,
    PluginList,
    FinishTermDialog,
    InProgressTermDialog,
    ListExportDialog
  }
})
export default class TermsPlugin extends AuthorizationMixin {
  @Term.State('list') list!: TermList;
  @Term.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;
  @Term.Mutation(INIT_CREATE) initCreate!: (data: object) => void;

  @Prop({ type: String }) conservatorship?: string;
  @Prop({ type: String }) conservator?: string;
  @Prop({ type: Number }) itemsPerPage?: number;

  termToRemove: TermView | null = null;
  termToEdit: TermView | null = null;
  termToFinish: TermView | null = null;
  termToProgress: TermView | null = null;
  termToReset: TermView | null = null;

  loading: boolean = false;
  pagination: Pagination = {
    sortBy: [this.orderingProp],
    descending: false,
    itemsPerPage: this.itemsPerPage || -1
  };

  get orderingProp(): TermOrdering {
    return this.$auth.user().configurations.term_ordering === 'deadline' ? 'sortedDeadline' : 'sortedBegin';
  }

  get items() {
    let list = this.list.items || [];

    if (this.conservatorship) {
      list = list.filter((term) => term.conservatee.id === this.conservatorship);
    }

    return list
      .filter((term) => term.state !== 'done')
      .map((term) => MapStateToView(term))
      .sort((first, second) => first[this.orderingProp].localeCompare(second[this.orderingProp]));
  }

  itemClasses(term: TermView): string {
    const sortProps = this.$auth.user().configurations.term_ordering;

    if (term.state === 'open' && getTime(term[sortProps]) < Date.now()) {
      return 'error';
    }

    if (term.state === 'in_progress') {
      return 'warning';
    }

    return '';
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}
