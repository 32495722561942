


































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_REQUIRE_FORM_SHEET } from '../store';
import AppTable from '@/components/AppTable.vue';
import { CompensationList } from '../types';
import { ApiResponse } from '@/components/types';
import format, { addMonths, subMonths } from '@/filter/dateFormat';

const Compensation = namespace('compensation');

@Component({
  components: { AppTable }
})
export default class FormSheetPlugin extends Vue {
  @Compensation.State('list') list!: CompensationList;
  @Compensation.Action(FETCH_REQUIRE_FORM_SHEET) fetch!: () => Promise<ApiResponse>;

  loading: boolean = false;

  month: string = format(Date.now(), 'yyyy-MM');
  currentMonth: string = format(Date.now(), 'yyyy-MM');

  subMonth() {
    this.month = format(subMonths(`${this.month}-01`, 1), 'yyyy-MM');
  }

  addMonth() {
    this.month = format(addMonths(`${this.month}-01`, 1), 'yyyy-MM');
  }

  get headers() {
    return [
      { align: 'left', text: 'Betreuter', value: null, sortable: false },
      { align: 'left', text: 'Aktenzeichen', value: null, sortable: false },
      { align: 'right', text: 'Rechnungsnr.', value: null, sortable: false },
      { align: 'right', text: 'Zeitraum', value: null, sortable: false }
    ];
  }

  get items() {
    return this.list.items
      .filter((compensation) => {
        if (compensation.state === 'readyToMail') {
          return false;
        }

        return !this.month || format(compensation.end, 'yyyy-MM') <= this.month;
      });
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}
