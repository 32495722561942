










import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApiResponse } from '../types';

@Component({})
export default class AsyncDeleteButton extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() deleteFunction!: () => Promise<ApiResponse>;
  @Prop({ default: 'primary darken-1' }) color!: string;

  loading = false;

  async save() {
    this.$emit('on-start');

    this.loading = true;
    const { error } = await this.deleteFunction();
    this.loading = false;

    if (error) {
      this.$emit('on-error', error);

      return;
    }

    this.$emit('on-success');
  }
}
