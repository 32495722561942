








import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApiResponse } from '../types';

@Component({})
export default class AsyncFormSubmit extends Vue {
  @Prop({ type: Function, required: true }) saveFunction!: () => Promise<ApiResponse>;

  save(observer: any) {
    return async () => {
      this.$emit('on-loading', true);
      const { error, content } = await this.saveFunction();
      this.$emit('on-loading', false);

      if (error) {
        this.$emit('on-error', error);

        return;
      }

      observer.reset();

      this.$emit('on-success', content);
    }
  }
}
