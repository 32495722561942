



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';

@Component({ inheritAttrs: false })
export default class DatePicker extends Vue {
  @Prop({ type: String, default: format(new Date(), 'yyyy-MM-dd') }) value!: string;
  @Prop({ type: String }) label?: string;
  @Prop({ type: Function }) allowed?: (data: any) => boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: true }) clearable!: boolean;

  modal: boolean = false;
  identifier: string = uuid();
  date: string | null = null;
  dateFormatted: string | null = null;

  changed() {
    this.date = this.parseDate(this.dateFormatted);
  }

  @Watch('value')
  onValueChange(value: string) {
    this.date = value;

    setTimeout(() => {
      // @ts-ignore
      this.$refs.date && this.$refs.date.validate();
    }, 0)
  }

  @Watch('date')
  onDateChange(date: string) {
    this.dateFormatted = this.formatDate(date);
    this.$emit('input', date);
  }

  created() {
    this.date = this.value;
    this.dateFormatted = this.formatDate(this.value);
    this.$emit('input', this.value);
  }

  formatDate(date: string = ''): string {
    if (!date) {
      return date;
    }

    return format(date, 'dd.MM.yyyy');
  }

  parseDate(date: string | null): string {
    if (!date) return '';

    const [day, month, year] = date.split('.');

    if (!date || !month || !year) return '';

    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  delayed({ errors }: any) {
    if (errors.length) {
      return {
        on: ['input', 'change'],
        debounce: 350
      };
    }

    return {
      on: ['change'],
      debounce: 0
    };
  }
}
