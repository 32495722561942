import { RouterChild } from '@/router';

export default [
  {
    name: 'conservatorship-create',
    path: 'conservatorship/create',
    component: () => import('./views/Create.vue')
  } as RouterChild,
  {
    name: 'conservatorship-list',
    path: 'conservatorships',
    component: () => import('./views/List.vue')
  } as RouterChild,
  {
    name: 'conservatorship-details',
    path: 'conservatorship/:id',
    component: () => import('./views/Details.vue')
  } as RouterChild
];
