

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MenuItem extends Vue {
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: String, required: false }) to!: string;
  @Prop({ type: Array, required: false }) children!: any;
}
