import {
  ApplyReportOfConservatorshipManagement,
  Report,
  ReportOfConservatorshipManagement,
  ReportOfConservatorshipManagementState,
  UpdateReportOfConservatorshipManagement
} from './types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState, SET_ALERT_ERROR, SET_ALERT_SUCCESS } from '@/store/types';
import Vue, { VueConstructor } from 'vue';
import { ApiResponse } from '@/components/types';

export const FETCH_REPORTS = 'FETCH_CONSERVATORSHIP_MANAGEMENT_REPORTS';
export const FETCH_REPORT_PDF = 'FETCH_CONSERVATORSHIP_MANAGEMENT_REPORT_PDF';
export const APPLY_REPORT = 'APPLY_CONSERVATORSHIP_MANAGEMENT_REPORT';
export const UPDATE_REPORT = 'UPDATE_CONSERVATORSHIP_MANAGEMENT_REPORT';
export const GET_REPORTS = 'GET_CONSERVATORSHIP_MANAGEMENT_REPORTS';
export const GET_CREATED = 'GET_CONSERVATORSHIP_MANAGEMENT_REPORT_CREATED';
export const SET_CREATED = 'SET_CONSERVATORSHIP_MANAGEMENT_REPORT_CREATED';
export const CLEAR_CREATED = 'CLEAR_CONSERVATORSHIP_MANAGEMENT_REPORT_CREATED';
export const GET_UPDATED = 'GET_CONSERVATORSHIP_MANAGEMENT_REPORT_UPDATED';
export const SET_UPDATED = 'SET_CONSERVATORSHIP_MANAGEMENT_REPORT_UPDATED';
export const CLEAR_UPDATED = 'CLEAR_CONSERVATORSHIP_MANAGEMENT_REPORT_UPDATED';
export const SET_REPORTS = 'SET_CONSERVATORSHIP_MANAGEMENT_REPORTS';

const initialState = (): ReportOfConservatorshipManagementState => ({
  list: {},
  create: {},
  update: {}
});

const getters: GetterTree<ReportOfConservatorshipManagementState, RootState> = {
  [GET_REPORTS]: (state) => (id: string) => state.list[id] || [],
  [GET_CREATED]: (state) => (id: string) => state.create[id],
  [GET_UPDATED]: (state) => (id: string) => state.update[id]
};

const mutations: MutationTree<ReportOfConservatorshipManagementState> = {
  [SET_REPORTS](state, { conservatorship_id, reports }: { conservatorship_id: string, reports: Report[] }) {
    state.list = {
      ...state.list,
      [conservatorship_id]: reports.sort((first, second) => first.year - second.year)
    };
  },
  [SET_CREATED](state, { conservatorship_id, report }: { conservatorship_id: string, report: ReportOfConservatorshipManagement }) {
    state.create = {
      ...state.create,
      [conservatorship_id]: report
    };
  },
  [CLEAR_CREATED](state, { conservatorship_id }: { conservatorship_id: string, report: ReportOfConservatorshipManagement }) {
    delete state.create[conservatorship_id];

    state.create = {
      ...state.create
    };
  },
  [SET_UPDATED](state, { conservatorship_id, report }: { conservatorship_id: string, report: ReportOfConservatorshipManagement }) {
    state.update = {
      ...state.update,
      [conservatorship_id]: report
    };
  },
  [CLEAR_UPDATED](state, { conservatorship_id }: { conservatorship_id: string, report: ReportOfConservatorshipManagement }) {
    delete state.update[conservatorship_id];

    state.update = {
      ...state.update
    };
  },
  RESET: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      // @ts-ignore
      state[key] = initial[key];
    });
  }
};

const actions = (Vue: Vue | VueConstructor): ActionTree<ReportOfConservatorshipManagementState, RootState> => ({
  async [FETCH_REPORTS]({ commit }, conservatorship_id: string): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${conservatorship_id}/list`, { responseType: 'json' });

      commit(SET_REPORTS, { conservatorship_id, reports: response.data });

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [FETCH_REPORT_PDF]({ commit }, { conservatorship_id, report_id, breaks }: { conservatorship_id: string, report_id: string, breaks: number[] }): Promise<ApiResponse> {
    try {
      const response = await Vue.axios.get(`api/conservatorship-management/${conservatorship_id}/report/${report_id}/pdf`, { responseType: 'blob', params: { breaks }});

      return { content: response.data };
    } catch (error) {
      return { error };
    }
  },
  async [APPLY_REPORT]({ commit, dispatch, rootGetters }, applyReport: ApplyReportOfConservatorshipManagement): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatorship/apply-report-of-conservatorship-management', applyReport, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  async [UPDATE_REPORT]({ commit, dispatch, rootGetters }, updateReport: UpdateReportOfConservatorshipManagement): Promise<ApiResponse> {
    try {
      await Vue.axios.post('api/commands/conservatorship-management/conservatorship/update-report-of-conservatorship-management', updateReport, { responseType: 'json' });

      commit(SET_ALERT_SUCCESS, true, { root: true });

      return {};
    } catch (error) {
      commit(SET_ALERT_ERROR, error, { root: true });

      return { error };
    }
  },
  SET_REPORTS({ commit }, { data }: { data: { conservatorship: string, reports: ReportOfConservatorshipManagement[] } }): void {
    commit(SET_REPORTS, { conservatorship_id: data.conservatorship, reports: data.reports });
  }
});

export default (Vue: Vue | VueConstructor): Module<ReportOfConservatorshipManagementState, RootState> => ({
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions: actions(Vue)
});
